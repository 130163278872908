import React, { useEffect, useState, useRef } from "react";
import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  StyledSecondaryBookingTypography,
  StyledSecondaryTypography,
  StyledTableRow,
  StyledTableCell,
  TypographyDefault,
  styledTyphographyDefault,
  SecondaryTypography,
} from "../../../Theme";
import {
  Box,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Typography,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  PaginationItem,
  CircularProgress,
} from "@mui/material";
import { saveAs } from "file-saver";
import { ReactComponent as DownloadIcon } from "../../../Icons/DownloadIcon.svg";
import { ReactComponent as DisabledDownloadIcon } from "../../../Icons/DisableDownload.svg";
import { ReactComponent as BookingDropDown } from "../../../Icons/BookingDropDown.svg";
import { ReactComponent as BookingCalenderIcon } from "../../../Icons/BookingCalenderIcon.svg";
import { ReactComponent as SortIcon } from "../../../Icons/Sort.svg";
import { ReactComponent as NumberInputIcon } from "../../../Icons/NumberInputIcon.svg";
import { FacilitiesBookTableHeader } from "../../../Data";
import { ReactComponent as NextIcon } from "../../../Icons/Next.svg";
import { ReactComponent as PreviousIcon } from "../../../Icons/Previous.svg";
import { ReactComponent as ExclamatoryIcon } from "../../../Icons/Exclamatory.svg";
import { ReactComponent as CsvIcon } from "../../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../../Icons/PdfIcon.svg";
import { ReactComponent as SideArrow } from "../../../Icons/SideDropDownArrow.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Redux/AxiosInstance";
import $ from "jquery";
import "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment/moment";
import "../Booking/BookingStyle.css";
import CustomDateRangePicker from "../../../CommonComponents/CustomDateRange";

const FacilitiesBooking = () => {
  const navigate = useNavigate();
  const { LanguageData } = useSelector((state) => state.Language);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [selectDate, setSelectDate] = useState(
    `${moment().format("DD MMM YYYY")} - ${moment()
      .add(1, "days")
      .format("DD MMM YYYY")}`
  );
  const [initialRender, setInitialRender] = useState(true);
  const datePickerRef = useRef(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [facility, setFacility] = useState(null);
  const facilityOpen = Boolean(facility);
  const [buildingFacilities, setBuildingFacilities] = useState(null);
  const buildingFacilitiesOpen = Boolean(buildingFacilities);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const initialState = useSelector((state) => state.Facility);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [sortData, setSortData] = useState("");
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [facilityId, setfacilityId] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingFacilityDetails, setBookingFacilityDetails] = useState([]);
  const [buildingDetails, setBuildingDetails] = useState([]);
  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([null, null]);
  const [dateSubmit, setdateSubmit] = useState(false);
  const [Tableloading, setTableloading] = useState(true);
  const [selectedFacility, setSelectedFacility] = useState("All Facilities");

  console.log(buildingDetails);
  const [facilitiesInBuilding, setfacilitiesInBuilding] = useState([]);
  const DateParams = () => {
    if (datetimerangedata?.length >= 1) {
      if (datetimerangedata[0] !== null) {
        if (
          moment(datetimerangedata[0]?.$d).format("yyyy-MM-DD") ==
          moment(datetimerangedata[1]?.$d).format("yyyy-MM-DD")
        ) {
          const data = {
            from_date: moment(new Date(datetimerangedata[0]?.$d)).format(
              "yyyy-MM-DD"
            ),
          };
          return data;
        } else if (datetimerangedata[1] !== null) {
          if (datetimerangedata[0]?.$d !== datetimerangedata[1]?.$d) {
            const data = {
              from_date: moment(new Date(datetimerangedata[0]?.$d)).format(
                "yyyy-MM-DD"
              ),
              to_date: moment(new Date(datetimerangedata[1]?.$d)).format(
                "yyyy-MM-DD"
              ),
            };
            return data;
          }
        }
      }
    }
  };

  const getAllBooking = async () => {
    const urlParams = new URLSearchParams(DateParams());
    setBookingFacilityDetails([]);
    setTableloading(true);
    await axiosInstance
      .get(
        `${
          process.env.REACT_APP_API_URL
        }facility_booking/property/${propertyId}?page=${currentPage}&record_count=${rowsPerPage}&sort=${sortData}&facility_id=${facilityId}&${
          dateSubmit ? urlParams.toString() : ""
        }`
      )
      .then((response) => {
        setBookingFacilityDetails(response.data.data.facility_booking);
        setPaginationData(response.data.data.pagination_details);
        setTableloading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.data?.error == "No data found.") {
          setBookingFacilityDetails([]);
        }
        setTableloading(false);
      });
  };

  useEffect(() => {
    getAllBooking();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender && !openDateRangePicker) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getAllBooking();
      }
    } else {
      setInitialRender(false);
    }
  }, [rowsPerPage, sortData, openDateRangePicker, facilityId]);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/building/facility`
      )
      .then(async (res) => {
        if (res.data.data?.property_buildings?.length > 1) {
          setBuildingDetails(res.data.data?.property_buildings);
        } else {
          setfacilitiesInBuilding(res.data.data?.property_buildings?.facility);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // const picker = $(datePickerRef.current);

    // const handleDateChange = (start, end) => {
    //   const formattedDate = `${start.format("DD MMM YYYY")}-${end.format(
    //     "DD MMM YYYY"
    //   )}`;
    //   setSelectDate(formattedDate);
    // };

    // const ranges = {
    //   Today: [moment(), moment()],
    //   "This Week": [moment().startOf("week"), moment().endOf("week")],
    //   "This Month": [moment().startOf("month"), moment().endOf("month")],
    //   "This Year": [moment().startOf("year"), moment().endOf("year")],
    //   Yesterday: [
    //     moment().subtract(1, "days").startOf("day"),
    //     moment().subtract(1, "days").endOf("day"),
    //   ],
    //   "Last Week": [
    //     moment().subtract(1, "weeks").startOf("week"),
    //     moment().subtract(1, "weeks").endOf("week"),
    //   ],
    //   "Last Month": [
    //     moment().subtract(1, "months").startOf("month"),
    //     moment().subtract(1, "months").endOf("month"),
    //   ],
    //   "Last Year": [
    //     moment().subtract(1, "years").startOf("year"),
    //     moment().subtract(1, "years").endOf("year"),
    //   ],
    //   "All Time": [moment(), moment()],
    // };

    // picker.daterangepicker({
    //   startDate: moment().subtract(7, "days"),
    //   endDate: moment(),
    //   ranges,
    // });

    // picker.on("apply.daterangepicker", (event, picker) => {
    //   handleDateChange(picker.startDate, picker.endDate);
    // });

    // return () => {
    //   picker.data("daterangepicker").remove();
    // };
  }, []);

  const handleReviewNavigate = (id) => {
    navigate(`/booking/${id}`);
  };

  const DownloadReport = async (type) => {
    const urlParams = new URLSearchParams(DateParams());

    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}facility_booking/property/${propertyId}/download?download_type=${type}&page=${currentPage}&record_count=${rowsPerPage}&sort=${sortData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Facility_Booking_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  console.log("user", UserManage);
  return (
    <>
      {openDateRangePicker && (
        <CustomDateRangePicker
          openDateRangePicker={openDateRangePicker}
          setopenDateRangePicker={setopenDateRangePicker}
          datetimerangedata={datetimerangedata}
          setdatetimerangedata={setdatetimerangedata}
          setdateSubmit={setdateSubmit}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {LanguageData?.Facility_Booking || "Facilities"}
          </PrimaryTypography>
          <StyledSecondaryServiceTypography>
            {LanguageData?.Common_Last || "Last Updated:"}{" "}
            {moment.utc(new Date()).local().format("DD MMM YYYY, HH:mm")}
          </StyledSecondaryServiceTypography>
        </Box>
        {UserManage?.booking && (
          <Box>
            <Button
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              sx={{
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={bookingFacilityDetails.length === 0 || loading}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <>
                  {bookingFacilityDetails.length > 0 ? (
                    <DownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  ) : (
                    <DisabledDownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  )}
                </>
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          borderRadius: "8px 8px 0px 0px",
          borderBottom: "1px solid #E5E7EB",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 16px 20px 16px",
          backgroundColor: "#FFFFFF",
          marginTop: "23px",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            onClick={(e) => setFacility(e.currentTarget)}
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: "8px",
              border: "1px solid #D2D6DB",
              padding: "11px 16px 10px 16px",
              "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
            }}
          >
            <StyledSecondaryBookingTypography pr={1.3}>
              {selectedFacility}
            </StyledSecondaryBookingTypography>
            <BookingDropDown />
          </Box>
          {/* <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #D2D6DB",
              padding: "10px 16px 10px 16px",
              marginLeft: "15px",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            ref={datePickerRef}
          >
            <BookingCalenderIcon />
            <Typography
              style={{
                marginLeft: "10px",
                marginTop: "3px",
                fontSize: "14px",
                fontWeight: 600,
                color: "#4D5761",
              }}
            >
              {selectDate}
            </Typography>
          </Box> */}

          <Box
            onClick={() => {
              setopenDateRangePicker(true);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "8px",
              px: 2,
              ml: 2,
              "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
            }}
          >
            <BookingCalenderIcon style={{ marginRight: "10px" }} />
            <StyledSecondaryBookingTypography>
              {Boolean(datetimerangedata[0])
                ? moment(datetimerangedata[0]?.$d).format("MMM D YYYY")
                : "MM DD YYYY"}{" "}
              -{" "}
              {Boolean(datetimerangedata[1])
                ? moment(datetimerangedata[1]?.$d).format("MMM D YYYY")
                : "MM DD YYYY"}
            </StyledSecondaryBookingTypography>
          </Box>
        </Box>
        <Box
          onClick={(e) => {
            setSortAnchorEl(e.currentTarget);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #D2D6DB",
            borderRadius: "8px",
            px: 2,
            "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
          }}
        >
          <SortIcon style={{ marginRight: "10px" }} />
          <SecondaryTypography>
            {LanguageData?.sort_filter || "Sort"}
          </SecondaryTypography>
        </Box>
      </Box>
      <Box
        sx={{ border: "1px solid #E5E7EB", borderRadius: "0px 0px 8px 8px" }}
      >
        <>
          <TableContainer>
            <Table>
              {FacilitiesBookTableHeader.length > 0 ? (
                <TableHead>
                  {bookingFacilityDetails.length > 0 && (
                    <StyledTableRow>
                      {FacilitiesBookTableHeader.map((value, index) => (
                        <StyledTableCell key={index}>{value}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                  )}
                </TableHead>
              ) : (
                <></>
              )}
              <TableBody
                bgColor={!bookingFacilityDetails.length > 0 && "#F9FAFB"}
              >
                {bookingFacilityDetails.length > 0 ? (
                  bookingFacilityDetails.map((request, index) => (
                    <StyledTableRow
                      // sx={{ height: '70px' }}
                      key={request.id}
                      onClick={() => handleReviewNavigate(request.id)}
                    >
                      <StyledTableCell>{request?.uuid || "--"}</StyledTableCell>
                      <StyledTableCell>
                        {request.facility_details?.title || "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {request.facility_details?.location_details
                          ?.is_part_of_building === 0
                          ? request.facility_details?.location_details?.name ||
                            "--"
                          : request.facility_details?.location_details?.building
                              ?.building_name || "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {request.user_details?.full_name || "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {request.booking_date
                          ? moment
                              .utc(request?.booking_date)
                              .local()
                              .format("DD MMM YYYY")
                          : "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {request?.display_time || "--"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <Box
                    sx={{
                      borderRadius: "0px 0px 8px 8px",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                      backgroundColor: "#F9FAFB",
                    }}
                  >
                    {Tableloading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <ExclamatoryIcon style={{ marginBottom: "5px" }} />
                        <Typography
                          sx={{
                            color: "#9DA4AE",
                            fontSize: "16px",
                            fontWeight: 600,
                            width: "470px",
                            my: 2,
                            height: "21px",
                            lineHeight: "20.8px",
                            textAlign: "center",
                            marginTop: "5px",
                          }}
                        >
                          {LanguageData?.No_Facility ||
                            "No Facility Booking Here"}
                        </Typography>
                        <StyledSecondaryTypography
                          color="#9DA4AE !important"
                          textAlign="center"
                          width="470px"
                          height="88px"
                          fontSize="16px"
                          fontWeight="400px"
                          lineHeight="22.4px"
                        >
                          {LanguageData?.No_SubFacility ||
                            "There are no pending facility bookings at the moment."}
                        </StyledSecondaryTypography>
                      </>
                    )}
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {bookingFacilityDetails.length > 0 && (
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                // border: "1px solid #E5E7EB",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  {LanguageData?.Common_Show || "Show :"}
                </Typography>
                {/* <OutlinedInput
                inputProps={{ min: 5, max: 30, step: 5 }}
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
                type="number"
                sx={{
                  height: "40px",
                  width: "100px",
                  ml: 2,
                  borderRadius: "8px",
                }}
              /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          )}
        </>
      </Box>
      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            py={0.5}
            onClick={() => DownloadReport("csv")}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} />{" "}
            {LanguageData?.Download_csv || "Download as CSV file"}
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            py={0.5}
            onClick={() => DownloadReport("pdf")}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            {LanguageData?.Download_pdf || "Download as PDF file"}
          </TypographyDefault>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: sortData == "recent" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("recent");
            setSortAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {" "}
            {LanguageData?.Most_filter || "Most Recent"}
          </styledTyphographyDefault>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: sortData == "old" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("old");
            setSortAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {LanguageData?.Oldest_Filter || "Oldest"}{" "}
          </styledTyphographyDefault>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
        open={facilityOpen}
        anchorEl={facility}
        onClose={() => setFacility(null)}
      >
        <MenuItem
          onClick={() => {
            setfacilityId("");
            setFacility(null);
            setSelectedFacility("All Facilities");
          }}
        >
          <styledTyphographyDefault>
            {" "}
            {LanguageData?.All_Facilities || "All Facilities"}
          </styledTyphographyDefault>
        </MenuItem>
        {buildingDetails?.length > 1
          ? buildingDetails?.map((value, index) => (
              <MenuItem
                key={index}
                onClick={(e) => {
                  setBuildingFacilities(e.currentTarget);
                  setfacilitiesInBuilding(value?.facility);
                }}
              >
                <styledTyphographyDefault style={{ width: "153px" }}>
                  {value?.building_name}
                </styledTyphographyDefault>
                <SideArrow />
              </MenuItem>
            ))
          : facilitiesInBuilding?.map((value) => (
              <MenuItem
                onClick={() => {
                  setfacilityId(value?.id);
                  setBuildingFacilities(null);
                  setFacility(null);
                  setSelectedFacility(value?.title);
                }}
              >
                <styledTyphographyDefault>
                  {value?.title}
                </styledTyphographyDefault>
              </MenuItem>
            ))}
      </Menu>
      <Menu
        sx={{ marginLeft: "150px", marginTop: "-29px" }}
        open={buildingFacilitiesOpen}
        anchorEl={buildingFacilities}
        onClose={() => setBuildingFacilities(null)}
      >
        {facilitiesInBuilding?.map((value) => (
          <MenuItem
            onClick={() => {
              setfacilityId(value?.id);
              setBuildingFacilities(null);
              setFacility(null);
              setSelectedFacility(value?.title);
            }}
          >
            <styledTyphographyDefault>{value?.title}</styledTyphographyDefault>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default FacilitiesBooking;
