import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryDefaultTypography,
  SecondaryTypographyDefault,
  StyledSecondaryBookingTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  TypographyDefault,
} from "../../Theme";
import { saveAs } from "file-saver";
import { ReactComponent as SearchIcon } from "../../Icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../Icons/Filter.svg";
import { ReactComponent as SortIcon } from "../../Icons/Sort.svg";
import { ReactComponent as CheckboxIcon } from "../../Icons/Checkbox.svg";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteTable.svg";
import { ReactComponent as DeleteDisable } from "../../Icons/DeleteDisable.svg";
import { ReactComponent as PreviousIcon } from "../../Icons/Previous.svg";
import { ReactComponent as NextIcon } from "../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/Exclamatory.svg";
import { ReactComponent as Close } from "../../Icons/BorderAlertClose.svg";
import { ReactComponent as NumberInputIcon } from "../../Icons/NumberInputIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../Icons/Datepicker.svg";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as CheckBoxMinusIcon } from "../../Icons/CheckBoxMinus.svg";
import { ReactComponent as CsvIcon } from "../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../Icons/PdfIcon.svg";
import { ReactComponent as DisabledDownloadIcon } from "../../Icons/DisableDownload.svg";
// import { AnnouncementTabColumn, UserAnnouncementTabColumn } from "../../Data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../Redux/AxiosInstance";
import moment from "moment";
import AlertModal, { PostLoaderModal } from "../../CommonComponents/modal";
import CustomDateRangePicker from "../../CommonComponents/CustomDateRange";
import { ReactComponent as SuccessAlertIcon } from "../../Icons/SuccessAlertProfile.svg";
import { ReactComponent as CloseIcon } from "../../Icons/ProfileSettingsCloseIcon.svg";
import { showToast } from "../../CommonComponents/Toaster";

export default function Announcement() {
  const { LanguageData } = useSelector((state) => state.Language);

  const [initialRender, setInitialRender] = useState(true);
  const tabList = [
    LanguageData?.Announcement_pending || "Pending",
    LanguageData?.home_active || "Active",
    LanguageData?.home_inactive || "Inactive",
    LanguageData?.home_complete || "Completed",
    LanguageData?.Announcement_draft || "Draft",
  ];
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Pending");
  const [AnnouncementList, setAnnouncementList] = useState([]);
  const [statusId, setStatusId] = useState(3);
  const [singleDeleteId, setSingleDeleteId] = useState(0);
  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [trigger, setTrigger] = useState(false);
  const [mainCheck, setMainCheck] = useState(false);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setSortData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [filterData, setFilterData] = useState("");
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showMultipleDeleteModal, setshowMultipleDeleteModal] = useState(false);
  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([null, null]);
  const [multipleDel, setMultipleDel] = useState([]);
  const [toastModal, setToastModal] = useState(false);
  const [alertText, SetAlertText] = useState("");
  const [dateSubmit, setdateSubmit] = useState(false);
  const [SearchData, setSearchData] = useState("");
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [Tableloading, setTableloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const AnnouncementTabColumn = [
    LanguageData?.Announcement_tab_checkbox || "Checkbox",
    LanguageData?.Announcemt_tab_title || "Title",
    LanguageData?.Announcemt_tab_type || "Type",
    LanguageData?.Announcemt_tab_Publish || "Publish Date",
    LanguageData?.Announcemt_tab_createdby || "Created by",
    "",
  ];

  const UserAnnouncementTabColumn = [
    LanguageData?.Announcemt_tab_title || "Title",
    LanguageData?.Announcemt_tab_type || "Type",
    LanguageData?.Announcemt_tab_Publish || "Publish Date",
    LanguageData?.Announcemt_tab_createdby || "Created by",
  ];

  let deleteModalDatas = {
    label:
      LanguageData?.Announcement_Single_DeleteHeader ||
      "Confirm to delete the Announcement",
    descriptionOne:
      LanguageData?.Announcement_Single_DeleteSubHeader ||
      "Please confirm that you wish to delete the announcement. Upon deletion announcement will disappear from both dashboard and mobile app.",

    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Announcement_Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Common_Confirm || "Confirm",
    },
  };

  const getColumnType = () => {
    switch (selectedTab) {
      case "Pending":
        return UserAnnouncementTabColumn;
      case "Active":
        return UserAnnouncementTabColumn;
      case "Inactive":
        return UserAnnouncementTabColumn;
      case "Completed":
        return UserAnnouncementTabColumn;
      case "Draft":
        return UserAnnouncementTabColumn;
      default:
        return [];
    }
  };

  const closemodal = () => {
    setshowDeleteModal(false);
    setSingleDeleteId(0);
  };

  const MultipleDelete = (e, value) => {
    const { checked } = e.target;

    setMultipleDel((prev) => {
      const checkdata = multipleDel.find((itm) => itm?.id == value?.id);

      if (Boolean(checkdata)) {
        const filterdata = multipleDel.filter((itm) => itm?.id != value?.id);
        return filterdata;
      } else {
        return [...prev, value];
      }
    });
  };
  const handleMainCheck = (e) => {
    const { checked } = e.target;
    if (AnnouncementList?.length > 1) {
      setMainCheck(checked);
    }
  };

  const DateParams = () => {
    if (datetimerangedata?.length >= 1) {
      if (datetimerangedata[0] !== null) {
        if (
          moment(datetimerangedata[0]?.$d).format("yyyy-MM-DD") ==
          moment(datetimerangedata[1]?.$d).format("yyyy-MM-DD")
        ) {
          const data = {
            start_date: moment(new Date(datetimerangedata[0]?.$d)).format(
              "yyyy-MM-DD"
            ),
          };
          return data;
        } else if (datetimerangedata[1] !== null) {
          if (datetimerangedata[0]?.$d !== datetimerangedata[1]?.$d) {
            const data = {
              start_date: moment(new Date(datetimerangedata[0]?.$d)).format(
                "yyyy-MM-DD"
              ),
              end_date: moment(new Date(datetimerangedata[1]?.$d)).format(
                "yyyy-MM-DD"
              ),
            };
            return data;
          }
        }
      }
    }
  };

  const getAnnouncementTabData = async () => {
    const urlParams = new URLSearchParams(DateParams());
    setAnnouncementList([]);
    setTableloading(true);
    await axiosInstance
      .get(
        `${
          process.env.REACT_APP_API_URL
        }property/${propertyId}/announcement?page=${currentPage}&status_id=${statusId}&record_count=${rowsPerPage}&announcement_type_id=${filterData}&sort=${sortData}&${
          dateSubmit && statusId != 4 ? urlParams.toString() : ""
        }`
      )
      .then((res) => {
        setAnnouncementList(res.data.data?.announcement);
        setPaginationData(res.data.data.pagination_details);
        setTableloading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.data?.error == "No announcement found") {
          setAnnouncementList([]);
        }
        setTableloading(false);
      });
  };

  useEffect(() => {
    getAnnouncementTabData();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender && !openDateRangePicker) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getAnnouncementTabData();
      }
    } else {
      setInitialRender(false);
    }
  }, [statusId, rowsPerPage, filterData, sortData, openDateRangePicker]);

  const handleSearch = async (data) => {
    setTableloading(true);
    setAnnouncementList([]);
    setSearchData(data);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement?page=${currentPage}&status_id=${statusId}&record_count=${rowsPerPage}&announcement_type_id=${filterData}&sort=${sortData}&search=${data}`
      );
      setAnnouncementList(response.data.data.announcement);
      setPaginationData(response.data.data.pagination_details);
      setTableloading(false);
      // setRowsPerPage(response?.data?.data?.pagination_details?.per_page)
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error == "No announcement found") {
        setAnnouncementList([]);
      }
      setTableloading(false);
    }
  };

  const DownloadReport = async (type) => {
    const urlParams = new URLSearchParams(DateParams());
    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${
          process.env.REACT_APP_API_URL
        }property/${propertyId}/announcement/download?download_type=${type}&page=${currentPage}&status_id=${statusId}&record_count=${rowsPerPage}&announcement_type_id=${filterData}&sort=${sortData}&${
          dateSubmit && statusId != 4 ? urlParams.toString() : ""
        }&search=${SearchData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Announcement_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mainCheck) {
      setMultipleDel([]);
      AnnouncementList.map((itm) => setMultipleDel((prev) => [...prev, itm]));
    }
  }, [mainCheck]);

  useEffect(() => {
    if (multipleDel.length <= 1) {
      setMainCheck(false);
    }
  }, [multipleDel]);

  const handleShowMultipleDeleteModal = () => {
    setshowMultipleDeleteModal(!showMultipleDeleteModal);
    setMultipleDel([]);
  };

  const handleTab = (value) => {
    setSelectedTab(value);
    if (value === "Active") {
      setStatusId(1);
    } else if (value === "Inactive") {
      setStatusId(2);
    } else if (value === "Pending") {
      setStatusId(3);
    } else if (value === "Draft") {
      setStatusId(4);
    } else if (value === "Completed") {
      setStatusId(5);
    }

    if (sortData == "old") {
      setSortData("new");
    }
  };

  const confirmDelete = () => {
    if (singleDeleteId !== 0) {
      SetPostLoader(true);
      axiosInstance
        .delete(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement/${singleDeleteId}`
        )
        .then((res) => {
          if (res.data?.success) {
            setTrigger(!trigger);
            setshowDeleteModal(false);
            SetPostLoader(false);
            getAnnouncementTabData();
            showToast(res?.data?.message, "delete");
          }
        })
        .catch((err) => {
          console.error(err);
          setshowDeleteModal(false);
        });
    }
  };

  const handleMultiDeleteList = async () => {
    if (multipleDel.length > 0) {
      let deleteIds = [];

      await multipleDel.map((value) => {
        deleteIds.push(value.id);
      });

      if (deleteIds.length > 0) {
        SetPostLoader(true);
        const data = new FormData();
        deleteIds.forEach((itm, index) => {
          data.append(`announcement_ids[${index}]`, itm);
        });
        axiosInstance
          .post(
            `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement/multiple_delete_announcement`,
            data
          )
          .then((res) => {
            if (res.data?.success)
              setshowMultipleDeleteModal(!showMultipleDeleteModal);
            setMultipleDel([]);
            setTrigger(!trigger);
            SetPostLoader(false);
            getAnnouncementTabData();
            showToast(res?.data?.message, "delete");
          })
          .catch((err) => {
            console.error(err);
            setshowMultipleDeleteModal(!showMultipleDeleteModal);
            setMultipleDel([]);
          });
      }
    }
  };

  const handleNavigate = (id) => {
    navigate(`ReviewAnnouncement/${id}`);
  };

  const renderData = (value, index) => {
    return (
      <StyledTableRow height="72px" key={index}>
        {UserManage?.announcement && statusId != 5 && (
          <StyledTableCell>
            {" "}
            <Checkbox
              onClick={(e) => MultipleDelete(e, value)}
              checked={Boolean(multipleDel.find((itm) => itm?.id == value?.id))}
              sx={{ height: "40px", minWidth: "43px" }}
              size="small"
              icon={<CheckboxIcon />}
            />
          </StyledTableCell>
        )}
        <StyledTableCell onClick={() => handleNavigate(value.id)}>
          <TypographyDefault>{value?.title}</TypographyDefault>
        </StyledTableCell>
        <StyledTableCell onClick={() => handleNavigate(value.id)}>
          <TypographyDefault>{value.announcement_type?.name}</TypographyDefault>
        </StyledTableCell>
        <StyledTableCell onClick={() => handleNavigate(value.id)}>
          {value?.start_date
            ? moment.utc(value?.start_date).local().format("DD MMM YYYY") +
              " - " +
              moment.utc(value?.end_date).local().format("DD MMM YYYY")
            : "-"}
        </StyledTableCell>
        <StyledTableCell onClick={() => handleNavigate(value.id)}>
          <TypographyDefault>
            {" "}
            <TypographyDefault>{value?.created_by}</TypographyDefault>
          </TypographyDefault>
        </StyledTableCell>
        {UserManage?.announcement &&
          statusId != 5 &&
          (multipleDel.length >= 1 ? (
            <StyledTableCell>
              {" "}
              <DeleteDisable width={20} />
            </StyledTableCell>
          ) : (
            <StyledTableCell
              onClick={() => {
                setshowDeleteModal(true);
                setSingleDeleteId(value.id);
              }}
            >
              {" "}
              <DeleteIcon />{" "}
            </StyledTableCell>
          ))}
      </StyledTableRow>
    );
    // }
  };
  const renderNoData = () => {
    switch (selectedTab) {
      case "Pending":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              No Pending Posts found Here
            </Typography>
            {/* <StyledSecondaryDefaultTypography color="#9DA4AE !important">
              There are no active marketing posts to display at the moment.
            </StyledSecondaryDefaultTypography>
            <StyledSecondaryDefaultTypography color="#9DA4AE !important">
              You may create marketing posts by clicking the top right{" "}
              <span style={{ fontWeight: 600 }}> Create </span>
              <br /> button.{" "}
            </StyledSecondaryDefaultTypography> */}
          </>
        );
      case "Active":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              No Active Posts found Here
            </Typography>
            {/* <StyledSecondaryDefaultTypography color="#9DA4AE !important">
              There are no active marketing posts to display at the moment.
            </StyledSecondaryDefaultTypography>
            <StyledSecondaryDefaultTypography color="#9DA4AE !important">
              You may create marketing posts by clicking the top right{" "}
              <span style={{ fontWeight: 600 }}> Create </span>
              <br /> button.{" "}
            </StyledSecondaryDefaultTypography> */}
          </>
        );
      case "Inactive":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              No Inactive Posts found Here
            </Typography>
            {/* <StyledSecondaryDefaultTypography color="#9DA4AE !important">
              There are no active marketing posts to display at the moment.
            </StyledSecondaryDefaultTypography>
            <StyledSecondaryDefaultTypography color="#9DA4AE !important">
              You may create marketing posts by clicking the top right{" "}
              <span style={{ fontWeight: 600 }}> Create </span>
              <br /> button.{" "}
            </StyledSecondaryDefaultTypography> */}
          </>
        );
      case "Completed":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              No Completed Posts found Here
            </Typography>
          </>
        );
      case "Draft":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              No Draft Posts found Here
            </Typography>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={confirmDelete}
          closeFunction={closemodal}
        />
      )}
      {openDateRangePicker && (
        <CustomDateRangePicker
          openDateRangePicker={openDateRangePicker}
          setopenDateRangePicker={setopenDateRangePicker}
          datetimerangedata={datetimerangedata}
          setdatetimerangedata={setdatetimerangedata}
          setdateSubmit={setdateSubmit}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {LanguageData?.Announcement_Header || "Announcement"}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Announcement_subHeader ||
              "All announcement displayed on the LOBY application are reflected below."}
          </StyledSecondaryTypography>
        </Box>
        {UserManage?.announcement && (
          <Box>
            <Button
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              sx={{
                fontWeight: "600",
                height: "40px",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                mr: 3,
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={AnnouncementList.length === 0 || loading}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <>
                  {AnnouncementList.length > 0 ? (
                    <DownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  ) : (
                    <DisabledDownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  )}
                </>
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
            <Button
              onClick={() => navigate("createAnnouncement")}
              sx={{
                padding: "10px 16px",
                borderRadius: "8px",
                backgroundColor: "#0E5E84",
                color: "#FFF",
                fontSize: "14px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#0E4965",
                },
              }}
            >
              {LanguageData?.Announcement_create || "Create"}
            </Button>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        {multipleDel.length >= 1 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="40px"
          >
            <Box display="flex" alignItems="center">
              <SecondaryTypography mr={2}>
                {multipleDel.length} {LanguageData?.Selected || "Selected"}
              </SecondaryTypography>
              <StyledSecondaryTypography
                onClick={() => {
                  setMultipleDel([]);
                }}
                sx={{
                  borderBottomWidth: "2px",
                  borderBottomStyle: "solid",
                  cursor: "pointer",
                }}
              >
                {LanguageData?.Unselect_All || "Unselect All"}
              </StyledSecondaryTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #E5E7EB",
                borderRadius: "6px",
                px: 2,
                ml: 2,
              }}
            >
              <SecondaryTypographyDefault
                onClick={() =>
                  setshowMultipleDeleteModal(!showMultipleDeleteModal)
                }
              >
                {LanguageData?.Delete_Selected || "Delete Selected"}
              </SecondaryTypographyDefault>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                border: "1px solid #D2D6DB",
                display: "flex",
                borderRadius: "10px",
              }}
            >
              {tabList.map((value, index) => (
                <Box
                  key={index}
                  onClick={() => handleTab(value)}
                  sx={{
                    borderRadius:
                      index == 0
                        ? "8px 0 0 8px"
                        : index == value?.length - 1
                        ? " 0 8px 8px 0 "
                        : "",
                    borderLeft: "1px solid #E5E7EB",
                    borderRight: "1px solid #E5E7EB",
                    backgroundColor:
                      value == selectedTab ? "#F3F4F6" : "initial",
                    padding: "10px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{
                      color: value === selectedTab ? "#111927" : "#4D5761",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                    textAlign={"center"}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
            {selectedTab === "Draft" ? (
              <Box sx={{ display: "flex", height: "40px" }}>
                <StyledOutlinedInput
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  placeholder="Search"
                  startAdornment={
                    <SearchIcon style={{ paddingRight: "5px" }} />
                  }
                />
                <Box
                  onClick={(e) => setSortAnchorEl(e.currentTarget)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                    px: 2,
                    ml: 2,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#F9FAFB",
                    },
                  }}
                >
                  <SortIcon style={{ marginRight: "10px" }} />
                  <SecondaryTypographyDefault>
                    {LanguageData?.sort_filter || "Sort"}
                  </SecondaryTypographyDefault>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex", height: "40px" }}>
                <Box
                  onClick={() => setopenDateRangePicker(true)}
                  sx={{
                    minWidth: "237px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                    px: 2,
                    ml: 2,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#F9FAFB",
                    },
                  }}
                >
                  <DatePickerIcon style={{ marginRight: "10px" }} />
                  <StyledSecondaryBookingTypography>
                    {Boolean(datetimerangedata[0])
                      ? moment(datetimerangedata[0]?.$d).format("MMM D YYYY")
                      : "MMM DD YYY"}{" "}
                    -{" "}
                    {Boolean(datetimerangedata[1])
                      ? moment(datetimerangedata[1]?.$d).format("MMM D YYYY")
                      : "MMM DD YYY"}
                  </StyledSecondaryBookingTypography>
                </Box>
                <Box
                  onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                    px: 2,
                    ml: 2,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#F9FAFB",
                    },
                  }}
                >
                  <FilterIcon style={{ marginRight: "10px" }} />
                  <SecondaryTypographyDefault>
                    {LanguageData?.filter_common || "Filters"}
                  </SecondaryTypographyDefault>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box
        sx={{ border: "1px solid #E5E7EB", borderRadius: "0px 0px 8px 8px" }}
      >
        {AnnouncementList.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow sx={{ height: "44px" }}>
                    {(UserManage?.announcement && statusId != 5
                      ? AnnouncementTabColumn
                      : UserAnnouncementTabColumn
                    )?.map((value, index) => {
                      return (
                        <StyledTableCell key={index}>
                          {value === "Checkbox" ? (
                            multipleDel.length >= 1 ? (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                onClick={() => {
                                  setMultipleDel([]);
                                }}
                                size="small"
                                icon={<CheckBoxMinusIcon />}
                              />
                            ) : (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                size="small"
                                onChange={handleMainCheck}
                                checked={mainCheck}
                                icon={<CheckboxIcon />}
                              />
                            )
                          ) : value === "More" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {AnnouncementList?.map((value, index) => {
                    return renderData(value, index);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                // border: "1px solid #E5E7EB",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  {LanguageData?.Common_Show || "Show"}:
                </Typography>
                {/* <OutlinedInput
                  inputProps={{ min: 5, max: 30, step: 5 }}
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  type="number"
                  sx={{
                    height: "40px",
                    width: "100px",
                    ml: 2,
                    borderRadius: "8px",
                  }}
                /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                page={currentPage}
                count={paginationData?.last_page}
                size="medium"
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              backgroundColor: "#F9FAFB",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50vh",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {Tableloading ? <CircularProgress /> : renderNoData()}
          </Box>
        )}
      </Box>
      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem onClick={() => setSortData("recent")}>
          <Typography>
            {" "}
            {LanguageData?.Most_filter || "Most Recent"}{" "}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setSortData("old")}>
          <Typography> {LanguageData?.Oldest_Filter || "Oldest"}</Typography>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: filterData == "" && "#f6f7f8" }}
          onClick={() => {
            setFilterData("");
            setFilterAnchorEl(null);
          }}
        >
          <Typography>{LanguageData?.all_filter || "All"}</Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: filterData == "1" && "#f6f7f8" }}
          onClick={() => {
            setFilterData("1");
            setFilterAnchorEl(null);
          }}
        >
          <Typography>{LanguageData?.General_Filter || "General"} </Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: filterData == "2" && "#f6f7f8" }}
          onClick={() => {
            setFilterData("2");
            setFilterAnchorEl(null);
          }}
        >
          <Typography>
            {" "}
            {LanguageData?.Building_Filter || "Building Alert"}{" "}
          </Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            py={0.5}
            onClick={() => DownloadReport("csv")}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} />{" "}
            {LanguageData?.Download_csv || "Download as CSV file"}
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            py={0.5}
            onClick={() => DownloadReport("pdf")}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            {LanguageData?.Download_pdf || "Download as PDF file"}
          </TypographyDefault>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>

      <Modal open={showMultipleDeleteModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Announcement_delete_Header ||
                  "Confirm to delete the Announcement"}
              </PrimaryTypography>
              <Close onClick={() => handleShowMultipleDeleteModal()} />
            </Box>
            <Box sx={{ px: 3, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <Typography mb={1.3}>
                {LanguageData?.Announcement_delete_SubHeader ||
                  "Please confirm that you wish to delete the selected Announcement. The Announcement which are deleted will be removed from the system and non-recoverable."}
              </Typography>
              {multipleDel.map((itm, index) => {
                return (
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 400, color: "#1F2A37" }}
                  >
                    {index + 1}. {itm?.title}
                  </Typography>
                );
              })}
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => handleShowMultipleDeleteModal()}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
                onClick={handleMultiDeleteList}
              >
                {LanguageData?.Announcement_DeleteNow || "Delete now"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {toastModal && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              padding: "10px 16px 10px 16px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
              marginTop: "30px",
              width: "370px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <SuccessAlertIcon />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#384250",
                  marginLeft: "10px",
                }}
              >
                {alertText}
              </Typography>
            </Box>
            <Box>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setToastModal(false)}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
