import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  CircularProgress,
  PaginationItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import {
  DatePickerStyle,
  PrimaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import { ReactComponent as SearchIcon } from "../../../Icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../../Icons/Filter.svg";
import { ReactComponent as SortIcon } from "../../../Icons/Sort.svg";
import { ReactComponent as CsvIcon } from "../../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../../Icons/PdfIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../Icons/DownloadIcon.svg";
import { ReactComponent as NumberInputIcon } from "../../../Icons/NumberInputIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as PreviousIcon } from "../../../Icons/Previous.svg";
import { ReactComponent as NextIcon } from "../../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../../Icons/Exclamatory.svg";
import { ReactComponent as MoreIcon } from "../../../Icons/ThreeDot.svg";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as DisabledDownloadIcon } from "../../../Icons/DisableDownload.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { VisitorsAllTabColumn } from "../../../Data";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../Redux/AxiosInstance";
import moment from "moment";
import { getChip } from "../../../Utils";
import TypesInformationModal from "../../../CommonComponents/TypeInformationModal";
import { DatePicker } from "@mui/x-date-pickers";
import { showToast } from "../../../CommonComponents/Toaster";

const Visitors = () => {
  const [initialRender, setInitialRender] = useState(true);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const tabList = ["All", "Invited", "Self Registration"];
  const [TabId, setTabId] = useState("");
  console.log(TabId);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("All");
  const [VisitorsList, setVisitorsList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setSortData] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [paginationData, setPaginationData] = useState({});
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [SearchData, setSearchData] = useState("");
  const [Tableloading, setTableloading] = useState(true);
  const [loading, setLoading] = useState(false);

  const getAllVisitors = async () => {
    setTableloading(true);
    setVisitorsList([]);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}invite_visitor/property/${propertyId}?page=${currentPage}&record_count=${rowsPerPage}&sort=${sortData}&type_id=${TabId}&search=${SearchData}`
      );
      setVisitorsList(response.data.data.invite_visitor);
      setPaginationData(response.data.data.pagination_details);
      setTableloading(false);
      // setRowsPerPage(response?.data?.data?.pagination_details?.per_page)
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error == "No data found") {
        setVisitorsList([]);
      }
      setTableloading(false);
    }

    // axiosInstance.get(`${process.env.REACT_APP_API_URL}invite_visitor/property/${propertyId}`)
    //   .then((data) => {
    //     setVisitorsList(data.data.data.invite_visitor)
    //     setPaginationData(data.data.data.pagination_details)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  };

  useEffect(() => {
    getAllVisitors();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getAllVisitors();
      }
    } else {
      setInitialRender(false);
    }
  }, [TabId, sortData, rowsPerPage, SearchData]);

  // const handleSearch = async (data) => {

  //   try {
  //     const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}invite_visitor/property/${propertyId}?search=${data}`)
  //     setVisitorsList(response.data.data.invite_visitor)
  //     setPaginationData(response.data.data.pagination_details)
  //     setRowsPerPage(response?.data?.data?.pagination_details?.per_page)

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const DownloadReport = async (type) => {
    try {
      setLoading(true);
      setAnchorEl(false);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}invite_visitor/property/${propertyId}/download?download_type=${type}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Visitors_report.${type}`);
    } catch (error) {
      console.log(error);
      showToast("Something went wrong! Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const getColumnType = () => {
    switch (selectedTab) {
      case "All":
        return VisitorsAllTabColumn;
      case "Invited":
        return VisitorsAllTabColumn;
      case "Self Registration":
        return VisitorsAllTabColumn;
      default:
        return [];
    }
  };

  const getNoDataType = () => {
    switch (selectedTab) {
      case "All":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              No Visitor History
            </Typography>
            <StyledSecondaryTypography color="#9DA4AE !important">
              There are no pending, active or past visitors in the system at the
              moment.{" "}
            </StyledSecondaryTypography>
          </>
        );
      case "Invited":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              "No Invited Visitors found."
            </Typography>
            {/* <StyledSecondaryTypography color="#9DA4AE !important">
              There are no pending, active or past visitors in the system at the
              moment.{" "}
            </StyledSecondaryTypography> */}
          </>
        );
      case "Self Registration":
        return (
          <>
            <ExclamatoryIcon />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                my: 2,
              }}
            >
              No Self Registration visitors found
            </Typography>
            {/* <StyledSecondaryTypography color="#9DA4AE !important">
              There are no pending, active or past visitors in the system at the
              moment.{" "}
            </StyledSecondaryTypography> */}
          </>
        );
      default:
        return [];
    }
  };

  const renderData = (value) => {
    // switch (selectedTab) {
    //   case 'All':
    return (
      <StyledTableRow height="70px">
        <StyledTableCell
          onClick={() => navigate(`visitorsDetails/${value.id}`)}
        >
          <StyledSecondaryTypography>
            {value?.uuid || "-"}
          </StyledSecondaryTypography>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => navigate(`visitorsDetails/${value.id}`)}
        >
          <StyledSecondaryTypography>
            {value?.start_date && value?.end_date ? (
              <>
                {moment.utc(value.start_date).local().format("DD MMM YYYY")} -
                {moment.utc(value.end_date).local().format("DD MMM YYYY")}
              </>
            ) : (
              <>
                {value?.start_date
                  ? moment.utc(value.start_date).local().format("DD MMM YYYY")
                  : "-"}
              </>
            )}
          </StyledSecondaryTypography>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => navigate(`visitorsDetails/${value.id}`)}
        >
          <StyledSecondaryTypography>
            {" "}
            {value.location && value.location.length > 0
              ? value.location.join(", ")
              : "-"}
          </StyledSecondaryTypography>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => navigate(`visitorsDetails/${value.id}`)}
        >
          <StyledSecondaryTypography>
            {" "}
            {value?.invited_user_personal_details?.full_name &&
            value?.invited_user_access_details?.unit_details?.unit_no ? (
              <>
                {value.invited_user_personal_details.full_name} •{" "}
                {value.invited_user_access_details.unit_details.unit_no}
              </>
            ) : (
              "-"
            )}
          </StyledSecondaryTypography>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => navigate(`visitorsDetails/${value.id}`)}
        >
          <StyledSecondaryTypography>
            {value?.invited_visitor?.total_count
              ? value.invited_visitor.total_count + " px"
              : "-"}
          </StyledSecondaryTypography>
        </StyledTableCell>
      </StyledTableRow>
    );
    // }
  };
  console.log(Tableloading);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>Visitor Details</PrimaryTypography>
          <StyledSecondaryTypography>
            View a list of all visitor invitations and registrations made via
            the LOBY system.{" "}
          </StyledSecondaryTypography>
        </Box>
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            fontWeight: "600",
            width: "fit-content",
            color: "#0E5E84",
            backgroundColor: "#E7EFF3",
            padding: "10px 16px 10px 16px",
            "&:hover": {
              backgroundColor: "#CFDFE6",
            },
            "&.Mui-disabled": {
              backgroundColor: "#F9FAFB",
              color: "#D2D6DB",
            },
          }}
          disabled={VisitorsList.length === 0 || loading}
        >
          {loading ? (
            <Box sx={{ width: "40px", marginTop: "5px" }}>
              <CircularProgress size={15} />
            </Box>
          ) : VisitorsList.length > 0 ? (
            <DownloadIcon style={{ marginRight: "8px", padding: "3px" }} />
          ) : (
            <DisabledDownloadIcon
              style={{ marginRight: "8px", padding: "3px" }}
            />
          )}
          Download Report
        </Button>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            border: "1px solid #D2D6DB",
            display: "flex",
            borderRadius: "10px",
          }}
        >
          {tabList.map((value, index) => (
            <Box
              onClick={() => {
                setSelectedTab(value);
                setTabId(index == 0 ? "" : index);
              }}
              sx={{
                borderLeft: index !== 0 ? "1px solid #E5E7EB" : "initial",
                backgroundColor: value == selectedTab ? "#F3F4F6" : "initial",
                padding: "10px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius:
                  index == 0
                    ? " 8px 0 0 8px "
                    : index == 2
                    ? "0px 8px 8px 0px"
                    : "initial",
                "&:hover": { cursor: "pointer" },
              }}
            >
              <Typography
                sx={{
                  color: value === selectedTab ? "#111927" : "#4D5761",
                  fontSize: "14px",
                  fontWeight: 550,
                }}
                textAlign={"center"}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: "flex", height: "40px" }}>
          <StyledOutlinedInput
            placeholder="Search"
            onChange={(e) => {
              setSearchData(e.target.value);
            }}
            startAdornment={<SearchIcon style={{ paddingRight: "5px" }} />}
          />
          <Box
            onClick={(e) => setSortAnchorEl(e.currentTarget)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "6px",
              px: 2,
              ml: 2,
              "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
            }}
          >
            <SortIcon style={{ marginRight: "10px" }} />
            <SecondaryTypography>Sort</SecondaryTypography>
          </Box>
          {/* <Box onClick={(e) => setFilterAnchorEl(e.currentTarget)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #E5E7EB', borderRadius: '6px', px: 2, ml: 2 }}>
            <FilterIcon style={{ marginRight: '10px' }} />
            <SecondaryTypography>Filters</SecondaryTypography>
          </Box> */}
        </Box>
      </Box>

      <Box
        sx={{ border: "1px solid #E5E7EB", borderRadius: "0px 0px 8px 8px" }}
      >
        <>
          <TableContainer>
            <Table>
              <TableHead>
                {VisitorsList?.length > 0 && (
                  <StyledTableRow>
                    {getColumnType().map((value) => {
                      return (
                        <StyledTableCell>
                          {value === "Checkbox" ? (
                            <Checkbox size="small" icon={<CheckboxIcon />} />
                          ) : value === "More" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                )}
              </TableHead>
              <TableBody bgColor={!VisitorsList.length > 0 && "#F9FAFB"}>
                {VisitorsList?.length > 0 ? (
                  VisitorsList?.map((value) => {
                    return renderData(value);
                  })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "50vh",
                      justifyContent: "center",
                    }}
                  >
                    {Tableloading ? <CircularProgress /> : getNoDataType()}
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {VisitorsList?.length > 0 && (
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                // border: "1px solid #E5E7EB",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  Show :
                </Typography>
                {/* <OutlinedInput inputProps={{ min: 5, max: 30, step: 5 }} value={rowsPerPage} onChange={(e) => setRowsPerPage(e.target.value)} type='number' sx={{ height: '40px', width: '100px', ml: 2, borderRadius: '8px' }} /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          )}
        </>
      </Box>
      <Menu
        sx={{ m: 0.5 }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
            onClick={() => DownloadReport("csv")}
          >
            <CsvIcon width={40} /> Download as CSV file
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
            onClick={() => DownloadReport("pdf")}
          >
            <PdfIcon width={40} />
            Download as PDF file
          </TypographyDefault>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: sortData == "recent" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("recent");
            setSortAnchorEl(null);
          }}
        >
          <Typography py={0.3}>Latest Arrival first</Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: sortData == "old" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("old");
            setSortAnchorEl(null);
          }}
        >
          <Typography py={0.3}>Oldest Arrival First</Typography>
        </MenuItem>
        {/* <MenuItem>
          <Typography py={.3}>Most Visitors First</Typography>
        </MenuItem>
        <MenuItem>
          <Typography py={.3}>Least Visitors First</Typography>
        </MenuItem> */}
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        <MenuItem>
          <Checkbox icon={<CheckboxIcon />} />
          <Typography sx={{ mr: 3 }}>Management</Typography>
        </MenuItem>
        <MenuItem>
          <Checkbox icon={<CheckboxIcon />} />
          <Typography>Operations</Typography>
        </MenuItem>
        <MenuItem>
          <Checkbox icon={<CheckboxIcon />} />
          <Typography>Ground Staff</Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Visitors;
