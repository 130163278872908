import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  SvgIcon,
  Tabs,
  Tab,
  Menu,
  Skeleton,
  CircularProgress,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";

import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  TypoGraph,
  TypographyDefault,
} from "../../Theme";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as InfoIcon } from "../../Icons/OverViewInfo.svg";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import { ReactComponent as HeatMapIconFirst } from "../../Icons/HeatMapOne.svg";
import { ReactComponent as HeatMapIconTwo } from "../../Icons/HeatMapTwo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { ReactComponent as HeatMapIconThree } from "../../Icons/HeatMapThree.svg";
import { ReactComponent as HeatMapIconFour } from "../../Icons/HeatMapFour.svg";
import { ReactComponent as HeatMapIconFive } from "../../Icons/HeatMapFive.svg";
import { ReactComponent as HeatMapIconsix } from "../../Icons/HeatMapSix.svg";
import { ReactComponent as HeatMapIconSeven } from "../../Icons/HeatMapSeven.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { ReactComponent as DownArrow } from "../../Icons/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../Icons/UpArrow.svg";
import { ReactComponent as LobyLogo } from "../../Icons/PdfLobyLogo.svg";
import "./Overview.css";
import ManagementGraph from "./ManagementGraph";
import axiosInstance from "../../Redux/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FaultReportGraph from "./FaultReportGraph";
import ServiceRequestGraph from "./ServiceRequestGraph";
import FacilityBookingsGraph from "./FacilityBookingGraph";
import ParcelCollectionGraph from "./ParcelCollectionGraph";
import { getGraphChip } from "../../Utils";
import Chart from "react-apexcharts";
import { getOverviewDatas } from "../../Redux/Feature/OverView/OverviewSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { showToast } from "../../CommonComponents/Toaster";
import {
  setpropertySetupLoader,
  getGeneralDetailsByProperty,
} from "../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const PdfHeaderSection = ({ buildingName }) => {
  return (
    <div id="print">
      <Box
        sx={{
          backgroundColor: "#0E4965",
          color: "#FFFFFF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontSize: "18px", fontWeight: 400, padding: "20px" }}
        >
          {buildingName}
        </Typography>
        <Box style={{ padding: "20px" }}>
          <LobyLogo />
        </Box>
      </Box>
      <Box
        sx={{
          color: "#0E4965",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontSize: "20px", fontWeight: 600, padding: "20px" }}
        >
          Overview (Statistics)
        </Typography>
        <Typography
          style={{ fontSize: "16px", fontWeight: 400, padding: "20px" }}
        >
          {moment(new Date()).format("DD MMM YYYY , HH:mm")}
        </Typography>
      </Box>
    </div>
  );
};

export default PdfHeaderSection;
