import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import ImageCourosel from "../../../CommonComponents/ImageCourosel";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../../Icons/BackArrowMobileIcon.svg";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as SelectTimeIcon } from "../../../Icons/SelectTimeIcon.svg";
import { ReactComponent as DeleteSBIcon } from "../../../Icons/DeleteSBIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as InactiveScheduleIcon } from "../../../Icons/InactiveScheduleIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypographyDefault,
  StyledSecondaryDefaultTypography,
  StyledPrimaryDefaultTypography,
  StyledSecondaryServiceTypography,
  SecondaryTypography,
  StyledSwitch,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Skeleton,
  Select,
  TextField,
  Collapse,
  Menu,
  Modal,
  Tab,
  Tabs,
  FormGroup,
  Typography,
  IconButton,
  Divider,
  InputAdornment,
  InputLabel,
  FormControl,
  Input,
  Checkbox,
  Dialog,
} from "@mui/material";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { getChip } from "../../../Utils";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { makeStyles } from "@mui/styles";
import { deleteFacility } from "../../../Redux/Feature/Facility/FacilitySlice";
import { showToast } from "../../../CommonComponents/Toaster";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .makeStyles-root-61": {
      backgroundColor: "grey",
    },
    position: "relative",
    backgroundColor: "white ",
    "& .MuiDateRangeCalendar-root ": { color: "red !important" },
    // "& .css-968uz8-MuiStack-root-MuiMultiInputDateRangeField-root": {
    //   border: "2px solid red"
    // },
    "&.MuiDateRangeCalendar-root > div ": {
      height: "640px !important",
    },
    "&.MuiDateRangeCalendar-root > div:nth-child(1)": {
      color: "white !important",
      zIndex: "-100000 !important",
    },
    // "& .css-jef1b6-MuiDateRangeCalendar-container": { color: "red !important" },
  },
}));

export default function ReviewFAcilityManage() {
  const { LanguageData } = useSelector((state) => state.Language);
  const [TimeSlotAnchorEl, setTimeSlotAnchorEl] = useState(null);
  const timeSlotMenuOpen = Boolean(TimeSlotAnchorEl);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const classes = useStyles();
  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([null, null]);
  const [time, setTime] = useState({ start: "", end: "" });
  const datePickerRef = useRef(null);
  const [timeSlot, setTimeSlot] = useState([]);
  const [err, seterr] = useState({ date: "", start: "", end: "" });
  const [isStatus, setIsStatus] = useState(false);

  const [anchorstartTime, setanchorstartTime] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [isDatePickerReady, setIsDatePickerReady] = useState(false);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const { deleteFacilityStatus } = useSelector((state) => state.Facility);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [CancelInactive, setCancelInactive] = useState(false);
  const [CancelScheduledInactive, setCancelScheduledInactive] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [ConfirmchangeStatus, setConfirmChangeStatus] = useState(false);
  const [changeStatusData, setChangeStatusData] = useState("");
  const [changeStatusErr, setChangeStatusErr] = useState("");

  const [FacilityManageList, setFacilityManageList] = useState([]);
  const [FacilityImages, setFacilityImages] = useState([]);
  const [uniqueFeatures, setuniqueFeatures] = useState([]);
  const [DetailsBookingData, setDetailsBookingData] = useState({
    facility_Details: {
      title: "",
      description: "",
      location: "",
      level: "",
      max_people: "",
    },
    opening_hours: {
      mon: { from: "", to: "", status: true },
      tue: { from: "", to: "", status: true },
      wed: { from: "", to: "", status: true },
      thu: { from: "", to: "", status: true },
      fri: { from: "", to: "", status: true },
      sat: { from: "", to: "", status: true },
      sun: { from: "", to: "", status: true },
    },
    Booking_type: {
      type: "",
      slot: "",
    },
  });
  const [textEditorvalue, settextEditorvalue] = useState("");
  const [loading, setloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  const ReviewOpeningHours = Object.values(DetailsBookingData.opening_hours);

  let CancelScheduledInactiveModalDatas = {
    label: LanguageData?.Cancel_Inactive || "Cancel Scheduled Inactive Period ",
    descriptionOne:
      LanguageData?.Cancel_Inactive_Des ||
      'Please confirm if you wish to cancel scheduled temporary inactive period. The status will change from "Inactive" to "Active".',
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Announcement_Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Common_Confirm || "Confirm",
    },
  };

  let CancelInactiveDatas = {
    label: "Cancel Inactive Period ",
    descriptionOne:
      'Please confirm if you wish to cancel inactive period. The status will change from "Inactive" to "Active".',
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Announcement_Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Common_Confirm || "Confirm",
    },
  };

  useEffect(() => {
    if (deleteFacilityStatus != null) {
      if (deleteFacilityStatus.success == true) {
        navigate("/manage");
        showToast(deleteFacilityStatus?.toast, "delete");
        // SetPostLoader(false)
      } else {
        SetPostLoader(false);
        showToast("Something went wrong! Please try again.", "error");
      }
    }
  }, [deleteFacilityStatus]);
  useEffect(() => {
    if (openDateRangePicker && datePickerRef.current) {
      setTimeout(() => {
        setIsDatePickerReady(true);
      }, 100);
    }
  }, [openDateRangePicker]);
  const getFacilityData = async () => {
    setloading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}`
      );
      const data = response.data.data.facility;
      setFacilityManageList(data);
      setDetailsBookingData({
        facility_Details: {
          title: data?.title,
          description: data?.description,
          location: data?.location_details?.building?.building_name,
          level: data?.location_details?.level?.level,
          max_people: data?.max_person_allowed,
          commonAreaId: data?.common_area_id,
          commonAreaName: data?.location_details?.name,
        },
        opening_hours: {
          mon: {
            from: data?.open_days[0]?.from_time,
            to: data?.open_days[0]?.to_time,
            status: data?.open_days[0]?.is_open,
          },
          tue: {
            from: data?.open_days[1]?.from_time,
            to: data?.open_days[1]?.to_time,
            status: data?.open_days[1]?.is_open,
          },
          wed: {
            from: data?.open_days[2]?.from_time,
            to: data?.open_days[2]?.to_time,
            status: data?.open_days[2]?.is_open,
          },
          thu: {
            from: data?.open_days[3]?.from_time,
            to: data?.open_days[3]?.to_time,
            status: data?.open_days[3]?.is_open,
          },
          fri: {
            from: data?.open_days[4]?.from_time,
            to: data?.open_days[4]?.to_time,
            status: data?.open_days[4]?.is_open,
          },
          sat: {
            from: data?.open_days[5]?.from_time,
            to: data?.open_days[5]?.to_time,
            status: data?.open_days[5]?.is_open,
          },
          sun: {
            from: data?.open_days[6]?.from_time,
            to: data?.open_days[6]?.to_time,
            status: data?.open_days[6]?.is_open,
          },
        },
        Booking_type: {
          type: data?.booking_type_details?.name,
          slot: data?.consecutive_slots,
        },
      });
      data?.images.forEach((itm) => {
        setFacilityImages((prev) => {
          const check = prev.find((sitm) => sitm?.file == itm?.module_name);
          if (!check) {
            return [
              ...prev,
              {
                id: itm?.id,
                file: itm?.module_name,
                src: `${process.env.REACT_APP_FILE_PATH}${itm?.path}`,
              },
            ];
          } else {
            return prev;
          }
        });
      });
      setuniqueFeatures(data?.unique_feature);
      settextEditorvalue(data?.terms_conditions);
      setloading(false);
      setdatetimerangedata([null, null]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getFacilityData();

    const getTimeData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=slot_time`
        );
        const TimeData = response.data.data.drop_down;
        setTimeSlot(TimeData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getTimeData();
  }, []);

  const handleTabValue = (e, value) => {
    setTabValue(value);
  };
  const CustomTabPanel = ({ value, index, children }) => {
    return <>{value == index && <div>{children}</div>}</>;
  };

  const ChangeStatusclose = () => {
    setChangeStatusModal(false);
    setChangeStatusData("");
    setCancelScheduledInactive(false);
    setCancelInactive(false);
    setConfirmChangeStatus(false);
    setChangeStatusErr("");
  };
  const confirmCancelfunction = () => {
    StatusActiveApi();
  };

  const confirmDeleteFacility = async () => {
    SetPostLoader(true);
    dispatch(deleteFacility({ propertyId, id }));
    // try {
    //     const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}`)
    //     console.log(response);
    //     navigate('/manage')
    // } catch (error) {
    //     console.log(error);
    // }
  };

  const handleNavigate = () => {
    navigate(`/manage/editFacilityManage/${id}`);
  };

  const ConfirmChnageStatus = () => {
    if (changeStatusData == 1) {
      ChangeStatusApi();
    } else if (changeStatusData == 2) {
      if (!changeScheduleStatusvalid()) {
        ChangeStatusApi();
      }
    }
  };

  const StatusActiveApi = async () => {
    SetPostLoader(true);

    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}/active`
      );
      // navigate('/manage')
      ChangeStatusclose();
      SetPostLoader(false);
      getFacilityData();
    } catch (error) {
      console.log(error);
    }
  };

  const ChangeStatusApi = async () => {
    SetPostLoader(true);
    let data;
    if (changeStatusData == 1) {
      data = {
        inactive_type: changeStatusData, //required|in:1,2
        today: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //required_if:inactive_type,1|date tile
      };
    } else if (changeStatusData == 2) {
      data = {
        inactive_type: changeStatusData, //required|in:1,2
        start_date: moment(datetimerangedata[0]?.$d).format("YYYY-MM-DD"), //required_if:inactive_type,2|date
        end_date: moment(datetimerangedata[1]?.$d).format("YYYY-MM-DD"), //required_if:inactive_type,2|date
        start_time: time?.start, //exists:slot_time,id
        end_time: time?.end, //exists:slot_time,id
      };
    }
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}facility/property/${propertyId}/report/${id}/inactive`,
        data
      );
      // navigate('/manage')
      ChangeStatusclose();
      setChangeStatusErr("");
      getFacilityData();
      SetPostLoader(false);
    } catch (error) {
      setConfirmChangeStatus(false);
      if (Boolean(error?.response?.data.data?.validation_error?.start_date)) {
        setChangeStatusErr(
          error?.response?.data?.data?.validation_error?.start_date
        );
      } else {
        setChangeStatusErr(error?.response?.data?.error);
      }
    }
  };

  const gettime = (id) => {
    const data = timeSlot.find((itm) => itm?.id == id);
    return data?.time;
  };

  const handledateTimeRange = (e, key) => {
    console.log("value", e);
    if (key == "date") {
      setdatetimerangedata(e);
      seterr((prev) => ({ ...prev, date: false }));
    } else {
      setTime((prev) => ({ ...prev, [key]: e }));
      seterr((prev) => ({ ...prev, [key]: false }));
      setTimeSlotAnchorEl(null);
      setMenuAnchor(null);
    }
  };

  const applyDateRange = () => {
    if (!changeScheduleStatusvalid()) {
      setIsStatus(true);
      setIsDatePickerReady(false);
      setopenDateRangePicker(false);
    }
  };

  const changeScheduleStatusvalid = () => {
    let isValid = false;

    if (time?.start == "") {
      seterr((prev) => ({ ...prev, start: true }));
      isValid = true;
    }
    if (time?.end == "") {
      seterr((prev) => ({ ...prev, end: true }));
      isValid = true;
    }
    if (datetimerangedata[0] == null) {
      seterr((prev) => ({ ...prev, date: true }));
      isValid = true;
    }
    return isValid;
  };

  const canelDateRange = () => {
    setIsStatus(false);
    setTime({ start: "", end: "" });
    seterr({ date: "", start: "", end: "" });
    setdatetimerangedata([null, null]);
    setIsDatePickerReady(false);
    setopenDateRangePicker(false);
    setTimeSlotAnchorEl(null);
  };

  return (
    <>
      {/* <button onClick={() => setloading(!loading)}>bdfbd</button> */}
      {postLoader && <PostLoaderModal />}

      {CancelInactive && (
        <AlertModal
          modalDatas={CancelInactiveDatas}
          confirmFunction={confirmCancelfunction}
          closeFunction={ChangeStatusclose}
        />
      )}

      {CancelScheduledInactive && (
        <AlertModal
          modalDatas={CancelScheduledInactiveModalDatas}
          confirmFunction={confirmCancelfunction}
          closeFunction={ChangeStatusclose}
        />
      )}

      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => navigate("/manage")}
            style={{ marginBottom: "30px", cursor: "pointer" }}
          />
        </Stack>
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                DetailsBookingData?.facility_Details?.title
              )}
            </PrimaryTypography>
            <StyledSecondaryServiceTypography>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                "Created on :" +
                moment
                  .utc(FacilityManageList?.created_at)
                  .local()
                  .format(" DD MMM YYYY, HH:mm ")
              )}{" "}
            </StyledSecondaryServiceTypography>
          </Box>
          {UserManage?.manage && !loading && (
            <DeleteSBIcon
              onClick={() => setDeleteModal(true)}
              style={{ cursor: "pointer" }}
            />
          )}
        </Stack>

        {Boolean(FacilityManageList?.inactive_schedule) && !loading && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
            }}
          >
            <Stack direction="row" alignItems="center">
              <InactiveScheduleIcon />
              <TypographyDefault display="flex" ml={1}>
                Bruno Krist (Dept - Role) has scheduled temporary inactive
                period from{" "}
                <SecondaryTypographyDefault mx={1}>
                  {" "}
                  {moment(
                    new Date(FacilityManageList?.inactive_schedule?.start_date)
                  ).format(" DD MMM YYYY")}
                  , {FacilityManageList?.inactive_schedule?.start_time?.time}{" "}
                </SecondaryTypographyDefault>{" "}
                to{" "}
                <SecondaryTypographyDefault mx={1}>
                  {" "}
                  {moment(
                    new Date(FacilityManageList?.inactive_schedule?.end_date)
                  ).format(" DD MMM YYYY")}
                  , {FacilityManageList?.inactive_schedule?.end_time?.time}.
                </SecondaryTypographyDefault>
              </TypographyDefault>
            </Stack>
            {UserManage?.manage && (
              <TypographyDefault
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setCancelScheduledInactive(true)}
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </TypographyDefault>
            )}
          </Stack>
        )}
        {/* <Stack direction='row' alignItems='center' justifyContent="space-between" gap='10px' px='16px' sx={{ height: '66px', backgroundColor: '#FCFCFD', border: '1px solid #E5E7EB', borderRadius: '8px' }}>
                <Stack direction='row' alignItems='center'>
                    <FacilityScheduledEditIcon />
                    <TypographyDefault display="flex" ml={1}>You have scheduled 3 edits for 24 Jan 2022, 10:00.<SecondaryTypographyDefault sx={{ textDecoration: "underline" }} mx={1}> Show </SecondaryTypographyDefault></TypographyDefault>
                </Stack>
                <TypographyDefault sx={{ textDecoration: "underline" }} onClick={() => setCancelScheduledInactive(true)}>Cancel</TypographyDefault>
            </Stack> */}
        {loading ? (
          <Skeleton variant="rounded" width="100%" height="50vh" />
        ) : (
          <Box
            mt={3}
            component="div"
            boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
            borderRadius="8px"
            width="100%"
          >
            <Box
              sx={{
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <Tabs
                sx={{ width: "100%", height: "55px" }}
                value={tabValue}
                onChange={handleTabValue}
              >
                <Tab sx={{ width: "50%", maxWidth: "none" }} label="Details" />
                <Tab sx={{ width: "50%", maxWidth: "none" }} label="Preview" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box
                backgroundColor="white"
                alignItems="center"
                padding="24px 16px 24px 16px"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" mb={1}>
                    <SecondaryTypography marginRight={2}>
                      {LanguageData?.Facility_is || "Facility is"}{" "}
                    </SecondaryTypography>
                    {getChip(FacilityManageList?.status?.key)}
                  </Stack>
                  {UserManage?.manage && (
                    <StyledSwitch
                      checked={
                        FacilityManageList?.status?.key == "active"
                          ? true
                          : false
                      }
                      onClick={() => {
                        FacilityManageList?.status?.key == "active"
                          ? setChangeStatusModal(true)
                          : setCancelInactive(true);
                      }}
                      name="loading"
                      color="primary"
                    />
                  )}
                </Stack>
                <TypographyDefault>
                  {LanguageData?.Review_Facility ||
                    " Please click on the toggle button on the right to change the facility status or schedule inactive period."}
                </TypographyDefault>
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {LanguageData?.Basic_Information || "Basic_Information"}
                </SecondaryTypography>
                {UserManage?.manage && (
                  <Button
                    onClick={() => {
                      handleNavigate();
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "20px 16px 20px 16px",
                }}
              >
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {" "}
                    {LanguageData?.Announcement_tab_title || "Title"}{" "}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {DetailsBookingData?.facility_Details?.title}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Description || "Description"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {DetailsBookingData?.facility_Details?.description}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Maximum_Pepole_Allowed ||
                      "Maximum People Allowed"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {DetailsBookingData?.facility_Details?.max_people}{" "}
                    {LanguageData?.Pepole || "Pepole"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Location || "Location"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {DetailsBookingData?.facility_Details?.commonAreaId
                      ? DetailsBookingData?.facility_Details?.commonAreaName +
                        (Boolean(DetailsBookingData?.facility_Details?.level)
                          ? ",  " + DetailsBookingData?.facility_Details?.level
                          : "")
                      : DetailsBookingData?.facility_Details?.location +
                        ",  " +
                        DetailsBookingData?.facility_Details?.level}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Booking_type || "Booking Type"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {DetailsBookingData?.Booking_type?.type}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Slot_Consective ||
                      "Consecutive Slots (30 Min per Slot)"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {DetailsBookingData?.Booking_type?.slot}{" "}
                    {LanguageData?.slot || "Slots"}
                  </TypographyDefault>
                </Grid>
              </Grid>

              {/* openninghours */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>Opening Hours</SecondaryTypography>
                {UserManage?.manage && (
                  <Button
                    onClick={() => {
                      handleNavigate();
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8}>
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      <StyledSecondaryTypography>
                        {LanguageData?.Day || "Day"}
                      </StyledSecondaryTypography>
                    </Stack>
                    <Stack rowGap={2.5} direction="column" mt={4}>
                      <TypographyDefault width="80px">
                        {" "}
                        {LanguageData?.Monday || "Monday"}{" "}
                      </TypographyDefault>
                      <TypographyDefault width="80px">
                        {LanguageData?.Tuesday || "Tuesday"}
                      </TypographyDefault>
                      <TypographyDefault width="80px">
                        {LanguageData?.Wednesday || "Wednesday"}
                      </TypographyDefault>
                      <TypographyDefault width="80px">
                        {LanguageData?.Thursday || "Thursday"}
                      </TypographyDefault>
                      <TypographyDefault width="80px">
                        {LanguageData?.Friday || "Friday"}
                      </TypographyDefault>
                      <TypographyDefault width="80px">
                        {LanguageData?.Saturday || "Saturday"}
                      </TypographyDefault>
                      <TypographyDefault width="80px">
                        {LanguageData?.Sunday || "Sunday"}
                      </TypographyDefault>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid xs={12} sm={5.8}>
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      <StyledSecondaryTypography>
                        {LanguageData?.Opening_Hours || "Opening Hours"}
                      </StyledSecondaryTypography>
                    </Stack>
                    <Stack rowGap={2.5} direction="column" mt={4}>
                      {ReviewOpeningHours?.map((itm) => (
                        <TypographyDefault>
                          {itm?.status == false
                            ? "Closed"
                            : itm?.from + " - " + itm?.to}
                        </TypographyDefault>
                      ))}{" "}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              {/* Unique features */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Unique_Features || "Unique Features"}{" "}
                </SecondaryTypography>
                {UserManage?.manage && (
                  <Button
                    onClick={() => {
                      handleNavigate();
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                {uniqueFeatures?.map((itm, index) => {
                  return (
                    <>
                      {index >= 1 && (
                        <Divider
                          sx={{
                            borderTop: "1px solid #F3F4F6",
                            width: "100%",
                            my: 3,
                          }}
                        />
                      )}
                      <Grid xs={12} sm={5.8}>
                        <StyledSecondaryTypography mb={1}>
                          {LanguageData?.Icon || "Icon"}
                        </StyledSecondaryTypography>
                        <img
                          src={`${process.env.REACT_APP_FILE_PATH}${itm?.custom_icon?.icon_path}`}
                          width="25px"
                          height="24px"
                        />
                      </Grid>
                      <Grid xs={12} sm={5.8}>
                        <StyledSecondaryTypography>
                          {LanguageData?.Announcement_tab_title || "Title"}
                        </StyledSecondaryTypography>
                        <TypographyDefault mt={0.5}>
                          {itm.title}
                        </TypographyDefault>

                        <StyledSecondaryTypography mt={2}>
                          {LanguageData?.Description || "Description"}
                        </StyledSecondaryTypography>
                        <TypographyDefault mt={0.5}>
                          {itm.description}
                        </TypographyDefault>
                      </Grid>
                    </>
                  );
                })}
              </Grid>

              {/* FacilityIMages */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Facility_Images || "Facility Images"}{" "}
                </SecondaryTypography>
                {UserManage?.manage && (
                  <Button
                    onClick={() => {
                      handleNavigate();
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}{" "}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12}>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    columnGap={3}
                    rowGap={2}
                    padding="20px 16px 20px 16px"
                  >
                    {FacilityImages.map((itm) => (
                      <img
                        src={itm?.src}
                        width="120px"
                        height="120px"
                        style={{ borderRadius: "10px" }}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>

              {/* Terms & Condition */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {LanguageData?.Terms_And_Condition || "Terms & Conditions"}{" "}
                </SecondaryTypography>
                {UserManage?.manage && (
                  <Button
                    onClick={() => {
                      handleNavigate();
                    }}
                    sx={{
                      width: "83px",
                      height: "36px",
                      padding: "8px 2px 8px 6px",
                      border: "1px solid #D2D6DB",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    {LanguageData?.Common_Edit || "Edit"}
                    <IconButton sx={{ paddingLeft: "10px" }}>
                      {" "}
                      <EditIcon width="14px" />
                    </IconButton>
                  </Button>
                )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Grid xs={12}>
                  <Stack
                    direction="column"
                    rowGap={2}
                    sx={{
                      "&>p": {
                        margin: "0px !important",
                        padding: "0px !important",
                      },
                      "& li": { padding: "5px !important" },
                    }}
                  >
                    {textEditorvalue && parse(textEditorvalue)}
                  </Stack>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Stack
                py={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#F9FAFB",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <StyledPrimaryDefaultTypography>
                  {LanguageData?.Mobile_Preview || "Mobile App Preview"}{" "}
                </StyledPrimaryDefaultTypography>
                <StyledSecondaryTypography>
                  {LanguageData?.Scroll_mobile ||
                    "Scroll on the mobile phone to review details"}
                </StyledSecondaryTypography>
              </Stack>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#F9FAFB"
              >
                <Box
                  my={6.5}
                  sx={{
                    border: "1px solid #E5E7EB",
                    width: "375px",
                    height: "750px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                    overflowY: "scroll",
                    "::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  <Stack sx={{ padding: "32px 16px 32px 16px" }}>
                    <BackArrowMobileIcon />
                    <Typography mt={5} sx={{ fontSize: "24px !important" }}>
                      {DetailsBookingData?.facility_Details?.title}
                    </Typography>
                    <StyledPrimaryDefaultTypography mt={2}>
                      {DetailsBookingData?.facility_Details?.commonAreaId
                        ? DetailsBookingData?.facility_Details?.commonAreaName
                        : DetailsBookingData?.facility_Details?.location +
                          "," +
                          DetailsBookingData?.facility_Details?.level}
                    </StyledPrimaryDefaultTypography>
                    <StyledSecondaryTypography>
                      {DetailsBookingData?.facility_Details?.description}
                    </StyledSecondaryTypography>
                  </Stack>
                  <Stack>
                    <TypographyDefault
                      sx={{ fontSize: "12px !important", ml: 2, pb: 1 }}
                    >
                      {LanguageData?.Capcity || "Capacity:"}
                      {DetailsBookingData?.facility_Details?.max_people}{" "}
                      {LanguageData?.Pax || "Pax"}
                    </TypographyDefault>
                    <ImageCourosel images={FacilityImages} />
                  </Stack>
                  <Stack gap={2.5} sx={{ padding: "32px 16px 32px 16px" }}>
                    {uniqueFeatures?.map((itm) => {
                      return (
                        <Stack columnGap={2} direction="row">
                          <img
                            src={`${process.env.REACT_APP_FILE_PATH}${itm?.custom_icon?.icon_path}`}
                            width="25px"
                            height="24px"
                            style={{ paddingTop: "4px" }}
                          />
                          <Stack gap="4px">
                            <SecondaryTypography>
                              {itm?.title}
                            </SecondaryTypography>
                            <StyledSecondaryTypography>
                              {itm?.description}{" "}
                            </StyledSecondaryTypography>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Box>
              </Box>
            </CustomTabPanel>
          </Box>
        )}
      </Box>

      {/* deleteModal */}

      <Modal open={deleteModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Delete_Facility ||
                  "Confirm to delete the facility permanently"}
              </PrimaryTypography>
              <Close onClick={() => setDeleteModal(false)} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <Stack direction="row" alignItems="center">
                <SecondaryTypography
                  mr={1}
                  sx={{ color: "#B54708 !important" }}
                >
                  {FacilityManageList?.booking_count} bookings
                </SecondaryTypography>
                <StyledSecondaryDefaultTypography
                  sx={{ color: "#1F2A37 !important" }}
                >
                  {" "}
                  {LanguageData?.Delete_sub_head ||
                    "during the stated period will be cancelled."}
                </StyledSecondaryDefaultTypography>
              </Stack>
              <StyledSecondaryDefaultTypography
                sx={{ mt: 3, color: "#1F2A37 !important" }}
              >
                {LanguageData?.Delete_Header ||
                  " Please confirm that you wish to delete the facility permanently.Upon deletion, the facility will disappear from both dashboard and mobile app."}
              </StyledSecondaryDefaultTypography>
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => setDeleteModal(false)}
                sx={{
                  background: "#FFFFFF",
                  color: "#0E5E84",
                }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  confirmDeleteFacility();
                }}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* changeStatus  */}

      <Modal open={changeStatusModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: { xs: "70%", sm: "50", md: "40%", lg: "35%" },
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Facility_Status || "Facility Status"}{" "}
              </PrimaryTypography>
              <Close onClick={ChangeStatusclose} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <Stack direction="row" alignItems="center">
                <StyledSecondaryDefaultTypography
                  sx={{ color: "#1F2A37 !important" }}
                >
                  {" "}
                  Selected facility has {FacilityManageList?.booking_count}{" "}
                  bookings.
                </StyledSecondaryDefaultTypography>
                <SecondaryTypography
                  ml={1}
                  sx={{ color: "#B54708 !important" }}
                >
                  {FacilityManageList?.booking_count} bookings
                </SecondaryTypography>
              </Stack>
              <StyledSecondaryDefaultTypography
                sx={{ mt: 1, color: "#1F2A37 !important" }}
              >
                {LanguageData?.Change_Facility ||
                  "Please select a change you would like to make to the facility."}
              </StyledSecondaryDefaultTypography>
              <Stack
                p={2}
                my={2}
                direction="row"
                onClick={() => {
                  setChangeStatusData(1);
                  canelDateRange();
                }}
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  changeStatusData == "1"
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                backgroundColor={
                  changeStatusData == "1" ? "#EDF6FA" : "#F9FAFB"
                }
                borderRadius="8px"
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Inactive_Immediately ||
                      "Inactive Immediately"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Inactive_Description ||
                      "Facility will be placed as inactive effective immediate.  Residents will not be able to book any time slots until the facility is turned back to active manually by condo management/ operations."}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="single"
                    checked={changeStatusData == "1"}
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              <Stack
                p={2}
                my={2}
                direction="row"
                onClick={() => setChangeStatusData(2)}
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  changeStatusData == "2"
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                sx={{
                  backgroundColor:
                    changeStatusData == "2" ? "#EDF6FA" : "#F9FAFB",
                  borderRadius: "8px",
                }}
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Shedule_Facility ||
                      "Schedule Temporary Inactive Period"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Shedule_Facility_Description ||
                      "Residents will not be able to book any timeslots within this date & time range"}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="multiple"
                    checked={changeStatusData == "2"}
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>

              {changeStatusData == "2" && (
                <Stack
                  onClick={() => {
                    setopenDateRangePicker(true);
                    setChangeStatusErr("");
                  }}
                  sx={{
                    height: "47px",
                    padding: "2px 12px 2px 12px",
                    borderRadius: "8px",
                    backgroundColor: "#F9FAFB",
                  }}
                  direction="row"
                  height="40px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isStatus ? (
                    <StyledSecondaryDefaultTypography>
                      {moment(datetimerangedata[0]?.$d).format("D MMM YYYY")},{" "}
                      {gettime(time?.start)} -{" "}
                      {moment(datetimerangedata[1]?.$d).format("D MMM YYYY")},{" "}
                      {gettime(time?.end)}{" "}
                    </StyledSecondaryDefaultTypography>
                  ) : (
                    <StyledSecondaryDefaultTypography
                      sx={{ color: "#9DA4AE !important" }}
                    >
                      {LanguageData?.Select_Range ||
                        " Please select date range"}
                    </StyledSecondaryDefaultTypography>
                  )}
                  <DatePickerIcon />
                </Stack>
              )}

              <ErrorTypography>{changeStatusErr}</ErrorTypography>
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={ChangeStatusclose}
                sx={{
                  background: "#FFFFFF",
                  color: "#0E5E84",
                }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                disabled={
                  Boolean(changeStatusData)
                    ? changeStatusData == 1
                      ? false
                      : isStatus
                      ? false
                      : true
                    : true
                }
                onClick={() => setConfirmChangeStatus(true)}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* confirmChangeStatus */}

      <Modal open={ConfirmchangeStatus}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {changeStatusData == "2"
                  ? "Confirm Schedule Temporary Inactive Period "
                  : "Confirm to change the status"}
              </PrimaryTypography>
              <Close onClick={() => setConfirmChangeStatus(false)} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <Stack direction="row" alignItems="center">
                <SecondaryTypography
                  mr={1}
                  sx={{ color: "#B54708 !important" }}
                >
                  {FacilityManageList?.booking_count} bookings
                </SecondaryTypography>
                <StyledSecondaryDefaultTypography
                  sx={{ color: "#1F2A37 !important" }}
                >
                  {LanguageData?.Delete_sub_head ||
                    "during the stated period will be cancelled."}
                </StyledSecondaryDefaultTypography>
              </Stack>
              <StyledSecondaryDefaultTypography
                sx={{ mt: 3, color: "#1F2A37 !important" }}
              >
                {LanguageData?.Facility_active_inactive ||
                  "Please confirm if you wish to confirm inactive immediately. The  status will change from Active to Inactive."}
              </StyledSecondaryDefaultTypography>
              {changeStatusData == "2" && (
                <StyledSecondaryDefaultTypography
                  sx={{
                    mt: 3,
                    color: "#1F2A37 !important",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {LanguageData?.Temporary_facility ||
                    "Please confirm if you wish to schedule a temporary inactive period from"}{" "}
                  <SecondaryTypography mr={1}>
                    {moment(datetimerangedata[0]?.$d).format("DD MMM YYYY")},{" "}
                    {gettime(time?.start)}
                  </SecondaryTypography>{" "}
                  {LanguageData?.to || "to"}{" "}
                  <SecondaryTypography ml={1}>
                    {moment(datetimerangedata[1]?.$d).format("DD MMM YYYY")},{" "}
                    {gettime(time?.end)}
                  </SecondaryTypography>
                  .{" "}
                </StyledSecondaryDefaultTypography>
              )}
              <Box sx={{ mt: 3 }} display="flex" alignItems="center">
                {getChip("active")}
                <Box display="flex" alignItems="center">
                  <div
                    style={{
                      marginLeft: "6px",
                      borderBottom: "1px solid black",
                      width: "50px",
                      height: "1px",
                    }}
                  ></div>
                  <span style={{ marginRight: "6px" }}>{">"}</span>
                </Box>
                {getChip("inactive")}
              </Box>
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => setConfirmChangeStatus(false)}
                sx={{
                  background: "#FFFFFF",
                  color: "#0E5E84",
                }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => ConfirmChnageStatus()}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* cancel scheduled inactive status */}

      {/* {openDateRangePicker && */}
      <Box
        sx={{
          backgroundColor: "#F9FAFB",
          position: "absolute",
          top: "10px",
        }}
      >
        <DateRangePicker
          ref={datePickerRef}
          sx={{
            "& .MuiPaper-root": {
              borderTopLeftRadius: "10px !important",
              borderTopRightRadius: "10px !important",
              borderBottomLeftRadius: "0px !important",
              borderBottomRightRadius: "0px !important", // Ensure this is applied to DateRangePicker itself
            },
          }}
          value={datetimerangedata}
          localeText={{ start: "", end: "" }}
          open={openDateRangePicker}
          onChange={(e) => handledateTimeRange(e, "date")}
          disablePast
        />

        {isDatePickerReady && (
          <Box
            sx={{
              position: "absolute",
              zIndex: "1301",
              left: "-2.42px",
              transform: "translate3d(10px, 10px, 0px)",
              top: "370px",
              // marginLeft: "-2.43px",
              width: "626.3px",
              boxSizing: "border-box",
              px: "20px",
              py: "20px",
              borderBottomLeftRadius: "10px !important",
              borderBottomRightRadius: "10px !important",
              backgroundColor: "white",
              // height: "234px",
              display: openDateRangePicker ? "" : "none",
              transition: "opacity 2s ease-in",
              transition: "opacity 2s ease-out",
              opacity: openDateRangePicker ? 1 : 0,
              webkitanimation: "fadeIn 2.5s",
              animation: "fadeIn 2.5s",
              boxShadow:
                "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important",
              // display: openDateRangePicker ? '' : 'none'
            }}
          >
            <Grid container justifyContent={"space-between"}>
              <Grid xs={5.5}>
                <Stack direction="column">
                  <SecondaryTypographyDefault>
                    {LanguageData?.Start_Date || "Start Date"}
                  </SecondaryTypographyDefault>
                  <TextField
                    placeholder="Select start date"
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      "& .MuiInputBase-root": {
                        color: err?.date ? "#912018" : "#111927",
                      },
                      backgroundColor: err?.date ? "#FEE4E2" : " #F9FAFB",
                    }}
                    value={
                      Boolean(datetimerangedata[0])
                        ? moment(datetimerangedata[0]?.$d).format("D MMM YYYY")
                        : ""
                    }
                    fullWidth
                  />
                  <SecondaryTypographyDefault mt={2}>
                    {LanguageData?.End_date || "End Date"}
                  </SecondaryTypographyDefault>
                  <TextField
                    placeholder="Select start date"
                    value={
                      Boolean(datetimerangedata[1])
                        ? moment(datetimerangedata[1]?.$d).format("D MMM YYYY")
                        : ""
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        color: err?.date ? "#912018" : "#111927",
                      },
                      backgroundColor: err?.date ? "#FEE4E2" : " #F9FAFB",
                    }}
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                  />
                </Stack>
              </Grid>

              <Grid
                xs={5.5}
                sx={{ visibility: anchorstartTime ? "hidden" : "" }}
              >
                <Stack direction="column">
                  <SecondaryTypographyDefault>
                    {LanguageData?.Start_Time || "Start Time"}
                  </SecondaryTypographyDefault>
                  <Stack
                    mt={1}
                    mb={2}
                    onClick={(e) => {
                      setTimeSlotAnchorEl("start");
                      setMenuAnchor(e.currentTarget);
                    }}
                    sx={{
                      backgroundColor: err?.start ? "#FEE4E2" : " #F9FAFB",
                      padding: "0 10px 0 10px ",
                      height: "45px",
                      minWidth: "140px",
                      borderRadius: "8px",
                    }}
                    direction="row"
                    height="40px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {Boolean(time?.start) ? (
                      <StyledSecondaryDefaultTypography>
                        {" "}
                        {gettime(time?.start)}
                      </StyledSecondaryDefaultTypography>
                    ) : (
                      <StyledSecondaryDefaultTypography
                        sx={{
                          color: Boolean(time?.start)
                            ? ""
                            : err?.start
                            ? "#912018 !important"
                            : "#9DA4AE !important",
                        }}
                      >
                        {LanguageData?.Select_start_time ||
                          " Select start time"}
                      </StyledSecondaryDefaultTypography>
                    )}
                    <SelectTimeIcon />
                  </Stack>
                  <SecondaryTypographyDefault>
                    {LanguageData?.End_time || "End Time"}
                  </SecondaryTypographyDefault>
                  <Stack
                    mt={1}
                    mb={2}
                    onClick={(e) => {
                      setTimeSlotAnchorEl("end");
                      setMenuAnchor(e.currentTarget);
                    }}
                    sx={{
                      backgroundColor: err?.end ? "#FEE4E2" : " #F9FAFB",
                      padding: "0 10px 0 10px ",
                      height: "45px",
                      minWidth: "140px",
                      borderRadius: "8px",
                    }}
                    direction="row"
                    height="40px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {Boolean(time?.end) ? (
                      <StyledSecondaryDefaultTypography>
                        {" "}
                        {gettime(time?.end)}
                      </StyledSecondaryDefaultTypography>
                    ) : (
                      <StyledSecondaryDefaultTypography
                        sx={{
                          color: Boolean(time?.end)
                            ? ""
                            : err?.end
                            ? "#912018 !important"
                            : "#9DA4AE !important",
                        }}
                      >
                        {LanguageData?.Select_end_time || "Select end time"}{" "}
                      </StyledSecondaryDefaultTypography>
                    )}
                    <SelectTimeIcon />
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="end">
                  <Stack direction="row" mt={5} columnGap={2}>
                    <Button onClick={() => canelDateRange()} variant="outlined">
                      {LanguageData?.Announcement_Cancel || "Cancel"}
                    </Button>
                    <Button
                      onClick={() => applyDateRange()}
                      variant="contained"
                    >
                      {LanguageData?.Common_Apply || "Apply"}
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      <Menu
        open={timeSlotMenuOpen}
        anchorEl={menuAnchor}
        MenuListProps={{
          sx: {
            maxHeight: "200px",
            width: "320px",
            "&::-webkit-scrollbar": {
              width: "1px", // Increase scrollbar width for better visibility
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "var(--Gray-200, #EAECF0)", // Thumb color
              borderRadius: "8px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#C1C8D0", // Thumb color on hover
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "var(--Base-White, #FFF)", // Scrollbar track background
              borderRadius: "8px", // Rounded scrollbar track
            },

            // Set high z-index // Limit height for the scrollable content
          },
        }}
        PaperProps={{
          sx: {
            width: "320px", // Ensure the menu width matches the list width
            maxHeight: "200px", // Limit menu height
            overflowY: "auto", // Enable vertical scrolling
            "&::-webkit-scrollbar": {
              width: "8px", // Adjust scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#EAECF0", // Thumb color
              borderRadius: "8px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#C1C8D0", // Change thumb color on hover
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFF", // Scrollbar track background
              borderRadius: "8px", // Rounded scrollbar track
            },
          },
        }}
        sx={{ zIndex: 130000000, width: "300px" }}
        anchorOrigin={{
          vertical: "bottom", // Align menu at the bottom of the field
          horizontal: "left", // Align menu to the left of the field
        }}
        transformOrigin={{
          vertical: "top", // Transform from the top of the menu
          horizontal: "left", // Align transformation to the left
        }}
        onClose={() => {
          setTimeSlotAnchorEl(null);
          setMenuAnchor(null);
          console.log(menuAnchor);
        }}
      >
        {timeSlot?.map((itm) => (
          <MenuItem
            onClick={(e) => handledateTimeRange(itm?.id, TimeSlotAnchorEl)}
          >
            <Typography>{itm?.time}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
