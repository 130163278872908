import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  SvgIcon,
  Tabs,
  Tab,
  Menu,
  Skeleton,
  CircularProgress,
  Stack,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { saveAs } from "file-saver";
import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  TypoGraph,
  TypographyDefault,
} from "../../Theme";

import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as InfoIcon } from "../../Icons/OverViewInfo.svg";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import { ReactComponent as HeatMapIconFirst } from "../../Icons/HeatMapOne.svg";
import { ReactComponent as HeatMapIconTwo } from "../../Icons/HeatMapTwo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { ReactComponent as HeatMapIconThree } from "../../Icons/HeatMapThree.svg";
import { ReactComponent as HeatMapIconFour } from "../../Icons/HeatMapFour.svg";
import { ReactComponent as HeatMapIconFive } from "../../Icons/HeatMapFive.svg";
import { ReactComponent as HeatMapIconsix } from "../../Icons/HeatMapSix.svg";
import { ReactComponent as HeatMapIconSeven } from "../../Icons/HeatMapSeven.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { ReactComponent as DownArrow } from "../../Icons/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../Icons/UpArrow.svg";
import "./Overview.css";
import ManagementGraph from "./ManagementGraph";
import axiosInstance from "../../Redux/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FaultReportGraph from "./FaultReportGraph";
import ServiceRequestGraph from "./ServiceRequestGraph";
import FacilityBookingsGraph from "./FacilityBookingGraph";
import ParcelCollectionGraph from "./ParcelCollectionGraph";
import { getGraphChip } from "../../Utils";
import Chart from "react-apexcharts";
import { getOverviewDatas } from "../../Redux/Feature/OverView/OverviewSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { showToast } from "../../CommonComponents/Toaster";
import {
  setpropertySetupLoader,
  getGeneralDetailsByProperty,
} from "../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import OverviewGraphSection from "./OverviewGraphSection";
import OverviewHeatMap from "./OverviewHeatMap";
import PdfHeaderSection from "./PdfHeaderSection";

const Overview = () => {
  const [tabValue, setTabValue] = useState(0);
  const { OverviewStatus } = useSelector((state) => state.OverView);
  const { LanguageData } = useSelector((state) => state.Language);
  const [userProfileDetails, setUserProfileDetails] = useState([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  let { generalDetailsList } = useSelector((state) => state.PropertyDetails);
  const [countDeatils, setCountDetails] = useState({
    Residents: "",
    invitedVisitors: "",
    PropertyStaff: "",
    FaultsReported: "",
    ServiceRequested: "",
    FacilitiesBooked: "",
  });
  const dispatch = useDispatch();
  const [yearSorting, setYearSorting] = useState("");
  const [yearArray, setYearArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [facilityList, setFacilityList] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState({
    id: 0,
    title: "",
  });
  const [heatMapData, setHeatMapData] = useState([]);
  const [selectedHeatMapData, setSelectedHeatMapData] = useState({});
  const [dateSorting, setDateSorting] = useState("Current Week");
  const [heatMapLoading, setHeatMapLoading] = useState(false);
  const [percentageChange, setPercentageChange] = useState({
    faultReportChange: "",
    serviceRequestChange: "",
    facilitiesChange: "",
    inviteVisitorChange: "",
  });
  const [loading, setLoading] = useState(false);
  const [buildingName, setBuildingName] = useState("");
  console.log(buildingName);

  useEffect(() => {
    dispatch(setpropertySetupLoader(false));
  }, []);

  useEffect(() => {
    if (generalDetailsList.success) {
      let data = generalDetailsList.data.property;
      setBuildingName(data?.title);
    }
  }, [generalDetailsList]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const previousYears = [currentYear - 2, currentYear - 1];
    const yearsArray = [...previousYears, currentYear];

    setYearSorting(currentYear);
    setYearArray(yearsArray);

    const apiPromises = [];

    const userDetailsPromise = axiosInstance
      .get(`${process.env.REACT_APP_API_URL}user_details`)
      .then((res) => {
        setUserProfileDetails(res.data.data?.user_details);
      })
      .catch((err) => console.error(err));

    apiPromises.push(userDetailsPromise);

    const dashboardPromise = axiosInstance
      .get(`${process.env.REACT_APP_API_URL}property/${propertyId}/dashboard`)
      .then((res) => {
        setCountDetails({
          Residents: res.data?.data?.dashboard?.resident_count,
          invitedVisitors: res.data?.data?.dashboard?.invite_visitor_count,
          PropertyStaff: res.data?.data?.dashboard?.staff_count,
          FaultsReported: res.data?.data?.dashboard?.fault_report_count,
          ServiceRequested: res.data?.data?.dashboard?.service_request_count,
          FacilitiesBooked: res.data?.data?.dashboard?.facility_booking_count,
        });
        setPercentageChange({
          facilitiesChange:
            res.data?.data?.dashboard?.percentage_change
              ?.facility_booking_count,
          faultReportChange:
            res.data?.data?.dashboard?.percentage_change?.fault_report_count,
          inviteVisitorChange:
            res.data?.data?.dashboard?.percentage_change?.invite_visitor_count,
          serviceRequestChange:
            res.data?.data?.dashboard?.percentage_change?.service_request_count,
        });
      })
      .catch((err) => {
        console.error(err);
      });

    apiPromises.push(dashboardPromise);

    Promise.all(apiPromises)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (OverviewStatus) {
      showToast("Something went wrong! Please try again later.", "error");
    }
  }, [OverviewStatus]);

  useEffect(() => {
    if (yearSorting) {
      dispatch(getOverviewDatas({ propertyId, yearSorting }));
    }
  }, [yearSorting]);

  useEffect(() => {
    dispatch(getGeneralDetailsByProperty(propertyId));
  }, []);

  useEffect(() => {
    setHeatMapLoading(true);
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}`)
      .then(async (res) => {
        const result = await res.data?.data?.facility;
        if (result && result.length > 0) {
          const SelectedFacility = result[0];
          setSelectedFacility({
            id: SelectedFacility?.id,
            title: SelectedFacility?.title,
          });
          setFacilityList(result);
        }
        let apiUrl = `${process.env.REACT_APP_API_URL}property/${propertyId}/dashboard_facility_overview`;

        if (dateSorting === "Current Week") {
          apiUrl = `${process.env.REACT_APP_API_URL}property/${propertyId}/dashboard_facility_overview`;
        } else if (dateSorting === "Last Week") {
          apiUrl += "?week=last";
        } else if (dateSorting === "Last Month") {
          apiUrl += "?months=1";
        } else if (dateSorting === "Last Year") {
          const previousYear = new Date().getFullYear() - 1;
          apiUrl += `?year=${previousYear}`;
        } else if (dateSorting === "Last 3 Months") {
          apiUrl += "?months=3";
        } else if (dateSorting === "Last 6 Months") {
          apiUrl += "?months=6";
        }

        axiosInstance
          .get(apiUrl)
          .then((res) => {
            setHeatMapData(res?.data?.data?.facility_overview);
            setHeatMapLoading(false);
          })
          .catch((err) => {
            setHeatMapLoading(false);
            console.error(err);
          });
      })
      .catch((err) => {
        setHeatMapLoading(false);
        console.error(err);
      });
  }, [dateSorting]);

  useEffect(() => {
    const selectedHeatMapData = heatMapData.find(
      (value) => selectedFacility.id === value.id
    );

    if (selectedHeatMapData) {
      setSelectedHeatMapData(selectedHeatMapData);
    }
  }, [heatMapData, selectedFacility]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const data = {
    mon: selectedHeatMapData?.schedule?.Mon || {},
    tue: selectedHeatMapData?.schedule?.Tue || {},
    wed: selectedHeatMapData?.schedule?.Wed || {},
    thu: selectedHeatMapData?.schedule?.Thu || {},
    fri: selectedHeatMapData?.schedule?.Fri || {},
    sat: selectedHeatMapData?.schedule?.Sat || {},
    sun: selectedHeatMapData?.schedule?.Sun || {},
  };
  const isSingleBookingType = selectedHeatMapData?.booking_type?.id === 1;
  const daysOfWeek = Object.keys(data);
  const timeSlots = Object.keys(data[daysOfWeek[0]]);

  timeSlots.reverse();

  const series = timeSlots.map((slot) => ({
    name: slot.trim(),

    data: daysOfWeek.map((day) => data[day][slot]),
  }));
  timeSlots.map((slot) => {
    console.log("slot", slot);
  });

  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: isSingleBookingType
            ? [
                {
                  from: 0,
                  to: 0,
                  color: "#F3F4F6",
                },
                {
                  from: 1,
                  to: 5,
                  color: "#CEE6F2",
                },
                {
                  from: 5,
                  to: 10,
                  color: "#A0CEE5",
                },
                {
                  from: 10,
                  to: 15,
                  color: "#72B7D7",
                },
                {
                  from: 20,
                  to: 30,
                  color: "#1687BD",
                },
                {
                  from: 30,
                  to: 50,
                  color: "#126C97",
                },
                {
                  from: 50,
                  color: "#0D5171",
                },
              ]
            : [
                {
                  from: 0,
                  to: 0,
                  color: "#F3F4F6",
                },
                {
                  from: 1,
                  to: 50,
                  color: "#CEE6F2",
                },
                {
                  from: 50,
                  to: 100,
                  color: "#A0CEE5",
                },
                {
                  from: 100,
                  to: 150,
                  color: "#72B7D7",
                },
                {
                  from: 150,
                  to: 300,
                  color: "#1687BD",
                },
                {
                  from: 300,
                  to: 500,
                  color: "#126C97",
                },
                {
                  from: 500,
                  color: "#0D5171",
                },
              ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      position: "top",
    },
    // yaxis: {
    //   type: "category",
    //   categories: timeSlots,
    // },
    legend: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      style: {
        background: "#262D33",
      },
      marker: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const day = daysOfWeek[dataPointIndex];
        const time = timeSlots;
        const timeSlot = time[seriesIndex];

        const total = series[seriesIndex][dataPointIndex] || "0";

        return `
          <div class="apexcharts-tooltip-custom">
            <div class="total-count-tip">
              <div>
                Booking
              </div>
              <div>
                ${total}
              </div>
            </div>
            <div class="month-change-top">${day}, ${timeSlot}</div>
          </div>
        `;
      },
    },
  };

  const convertPDF = (elements, fileName) => {
    const pdf = new jsPDF();

    // Create a container div to hold all elements
    const container = document.createElement("div");
    elements.forEach((element) => {
      container.appendChild(element.cloneNode(true));
    });

    // Append the container to the body temporarily (off-screen)
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.top = "0";
    container.style.margin = "0"; // Remove any margins
    container.style.padding = "0"; // Remove any padding
    container.style.width = "92%";
    container.style.height = `${container.scrollHeight}px`;
    document.body.appendChild(container);

    const containerWidth = container.scrollWidth;
    const containerHeight = container.scrollHeight;

    console.log("Container dimensions:", {
      containerWidth,
      containerHeight,
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const scale = 3; // High resolution for clarity

    return html2canvas(container, {
      scale: scale,
      width: containerWidth,
      height: containerHeight,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const canvasAspectRatio = canvas.width / canvas.height;
        const pdfAspectRatio = pdfWidth / pdfHeight;

        let imgWidth = pdfWidth;
        let imgHeight = pdfWidth / canvasAspectRatio;

        if (canvasAspectRatio < pdfAspectRatio) {
          imgHeight = pdfHeight;
          imgWidth = pdfHeight * canvasAspectRatio;
        }

        const x = (pdfWidth - imgWidth) / 2; // Center horizontally
        const y = 0; // Top of page

        // Render single page or split into multiple pages
        if (imgHeight <= pdfHeight) {
          pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
        } else {
          let position = 0;
          while (position < canvas.height) {
            const segmentCanvas = document.createElement("canvas");
            segmentCanvas.width = canvas.width;
            segmentCanvas.height = Math.min(
              canvas.height - position,
              pdfHeight * scale
            );
            const segmentCtx = segmentCanvas.getContext("2d");
            segmentCtx.drawImage(
              canvas,
              0,
              position,
              segmentCanvas.width,
              segmentCanvas.height,
              0,
              0,
              segmentCanvas.width,
              segmentCanvas.height
            );

            const segmentImgData = segmentCanvas.toDataURL("image/png");

            pdf.addImage(segmentImgData, "PNG", 0, 0, pdfWidth, pdfHeight);

            position += segmentCanvas.height;

            if (position < canvas.height) {
              pdf.addPage();
            }
          }
        }

        document.body.removeChild(container);

        pdf.save(fileName);

        return pdf;
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  React.useEffect(() => {
    console.log("loading", loading, isGeneratingPDF);
  }, [loading]);
  const generatePDFs = async () => {
    await setIsGeneratingPDF(true); // Enable PDF mode
    await new Promise((resolve) => setTimeout(resolve, 0)); // Wait for DOM to update

    console.log(isGeneratingPDF, "pdg"); // Logs state during generation

    // Create a single jsPDF instance
    const pdf = new jsPDF();

    // Component One
    const componentOne = document.getElementById("component-one");
    const canvasOne = await html2canvas(componentOne, {
      scale: 2,
      useCORS: true,
    });
    const imgDataOne = canvasOne.toDataURL("image/png");
    pdf.addImage(
      imgDataOne,
      "PNG",
      0,
      0,
      210,
      (canvasOne.height * 210) / canvasOne.width
    );
    pdf.addPage();

    // Component Two
    const componentTwo = document.getElementById("component-two");
    const canvasTwo = await html2canvas(componentTwo, {
      scale: 2,
      useCORS: true,
    });
    const imgDataTwo = canvasTwo.toDataURL("image/png");
    pdf.addImage(
      imgDataTwo,
      "PNG",
      0,
      0,
      210,
      (canvasTwo.height * 210) / canvasTwo.width
    );
    pdf.save("Overview-Report.pdf");

    setIsGeneratingPDF(false); // Disable PDF mode
    setLoading(false);

    console.log(isGeneratingPDF, "pdg after generation"); // Logs final state
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        // padding="20px"
        width="100%"
        sx={{
          position: "absolute",
          zIndex: -100000,
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Stack gap="20px" id="component-one">
          <PdfHeaderSection buildingName={buildingName} />
          <Box px="20px">
            <OverviewGraphSection
              countDeatils={countDeatils}
              percentageChange={percentageChange}
              tabValue={tabValue}
              yearSorting={yearSorting}
              LanguageData={LanguageData}
              isLoading={isLoading}
              yearArray={yearArray}
              setYearSorting={setYearSorting}
              handleTabChange={handleTabChange}
              id="component-one"
            />
          </Box>
        </Stack>
        <Stack id="component-two">
          <PdfHeaderSection buildingName={buildingName} />
          <Box px="20px">
            <OverviewHeatMap
              isLoading={isLoading}
              LanguageData={LanguageData}
              selectedFacility={selectedFacility}
              facilityList={facilityList}
              setSelectedFacility={setSelectedFacility}
              dateSorting={dateSorting}
              setDateSorting={setDateSorting}
              selectedHeatMapData={selectedHeatMapData}
              heatMapLoading={heatMapLoading}
              options={options}
              series={series}
            />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {" "}
            {isLoading ? (
              <Skeleton width={200} height={50} />
            ) : (
              `${LanguageData?.home_welcome_back || "Welcome Back"},${
                userProfileDetails?.full_name || "--"
              }`
            )}
          </PrimaryTypography>
          <StyledSecondaryServiceTypography>
            {isLoading ? (
              <Skeleton width={200} height={50} />
            ) : (
              `${userProfileDetails?.address || "--"}, ${
                userProfileDetails?.city || "--"
              } ${userProfileDetails?.zipcode || "--"}`
            )}
          </StyledSecondaryServiceTypography>
        </Box>
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Box>
            <Button
              sx={{
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: loading ? "#F9FAFB" : "#E7EFF3", // Disable background change when loading
                padding: "10px 16px 10px 16px",
                "&:hover": {
                  backgroundColor: loading ? "#F9FAFB" : "#CFDFE6", // Disable hover effect when loading
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
                transition: "all 0.3s ease", // Smooth transition for background change
              }}
              onClick={async () => {
                setLoading(true);
                await new Promise((resolve) => setTimeout(resolve, 0)); // Let React process the state update
                try {
                  await generatePDFs(); // Wait for async operation
                } catch (error) {
                  console.error("Error generating PDFs:", error);
                }
              }}
              disabled={loading} // Disable button during loading
            >
              {loading ? (
                <Box
                  sx={{
                    width: "40px",

                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={20} sx={{ color: "#0E5E84" }} />{" "}
                  {/* Ensure color of spinner matches */}
                </Box>
              ) : (
                <DownloadIcon style={{ marginRight: "8px", padding: "3px" }} />
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
          </Box>
        )}
      </Box>

      <OverviewGraphSection
        countDeatils={countDeatils}
        percentageChange={percentageChange}
        tabValue={tabValue}
        yearSorting={yearSorting}
        LanguageData={LanguageData}
        isLoading={isLoading}
        yearArray={yearArray}
        setYearSorting={setYearSorting}
        handleTabChange={handleTabChange}
        id="component-one"
      />

      <OverviewHeatMap
        isLoading={isLoading}
        LanguageData={LanguageData}
        selectedFacility={selectedFacility}
        facilityList={facilityList}
        setSelectedFacility={setSelectedFacility}
        dateSorting={dateSorting}
        setDateSorting={setDateSorting}
        selectedHeatMapData={selectedHeatMapData}
        heatMapLoading={heatMapLoading}
        options={options}
        series={series}
      />
    </Box>
  );
};

export default Overview;
