import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Stack } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  dateRange: {
    // In case you need additional customization
  },
}));

export default function CustomDateRangePicker({
  openDateRangePicker,
  setopenDateRangePicker,
  datetimerangedata,
  setdatetimerangedata,
  setdateSubmit,
  maxDate,
}) {
  const [errText, setErrText] = useState({ start: "", end: "" });
  const classes = useStyles();

  const handleApply = () => {
    if (!Boolean(datetimerangedata[0]) || !Boolean(datetimerangedata[1])) {
      if (!Boolean(datetimerangedata[0])) {
        setErrText((prev) => ({ ...prev, start: true }));
      }
      if (!Boolean(datetimerangedata[1])) {
        setErrText((prev) => ({ ...prev, end: true }));
      }
    } else {
      setErrText({ start: "", end: "" });
      setopenDateRangePicker(false);
      setdateSubmit(true);
    }
  };

  const [isDatePickerReady, setIsDatePickerReady] = useState(false);
  const datePickerRef = useRef(null);

  useEffect(() => {
    if (openDateRangePicker && datePickerRef.current) {
      setTimeout(() => {
        setIsDatePickerReady(true);
      }, 160);
    }
  }, [openDateRangePicker]);

  return (
    <>
      {openDateRangePicker && (
        <Box
          id="dateRange"
          sx={{
            position: "absolute",
            top: "10px",
            "& .MuiPaper-root.MuiPickersPopper-paper": {
              borderRadius: "10px !important", // Ensure the radius is applied
              width: "630px !important",
              boxShadow:
                "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)", // Box shadow applied here
            },
          }}
        >
          <DateRangePicker
            ref={datePickerRef}
            localeText={{ start: "", end: "" }}
            open={openDateRangePicker}
            onChange={(e) => {
              setdatetimerangedata(e);
              setErrText({ start: "", end: "" });
            }}
            maxDate={Boolean(maxDate) ? dayjs(maxDate) : null}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "10px !important", // Ensure this is applied to DateRangePicker itself
              },
            }}
          />

          {isDatePickerReady && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 1301,
                transform: "translate3d(10px, 10px, 0px)",
                top: "380px",
                marginLeft: "-2.4px",
                width: "595.90px",
                padding: "20px 15px",
                borderTop: "2px solid #F2F4F7",
                backgroundColor: "white",
                opacity: openDateRangePicker ? 1 : 0,
                borderBottomRightRadius: "8px !important", // Specifically for bottom-right
                borderBottomLeftRadius: "8px !important", // Specifically for bottom-left
                boxShadow:
                  "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important", // Apply box-shadow here as well
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      color: errText?.start ? "#912018 !important" : "#111927",
                      backgroundColor: errText?.start ? "#FEE4E2" : "",
                      width: "120px",
                      height: "25px",
                      padding: "10px 14px",
                      border: errText?.start
                        ? "1px solid #FEE4E2"
                        : "1px solid #D0D5DD",
                      borderRadius: "8px",
                    }}
                  >
                    {Boolean(datetimerangedata[0])
                      ? moment(datetimerangedata[0]?.$d).format("MM DD, YYYY")
                      : "MM DD, YYYY"}
                  </Box>
                  -
                  <Box
                    sx={{
                      color: errText?.end ? "#912018 !important" : "#111927",
                      backgroundColor: errText?.end ? "#FEE4E2" : "",
                      width: "120px",
                      height: "25px",
                      padding: "10px 14px",
                      border: errText?.end
                        ? "1px solid #FEE4E2"
                        : "1px solid #D0D5DD",
                      borderRadius: "8px",
                    }}
                  >
                    {Boolean(datetimerangedata[1])
                      ? moment(datetimerangedata[1]?.$d).format("MM DD, YYYY")
                      : "MM DD, YYYY"}
                  </Box>
                </Stack>

                <Stack direction="row" columnGap={2}>
                  <Button
                    onClick={() => {
                      setopenDateRangePicker(false);
                      setdatetimerangedata([]);
                      setdateSubmit(false);
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => handleApply()} variant="contained">
                    Apply
                  </Button>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
