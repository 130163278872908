import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  StyledSwitch,
  StyledDefaultTypography,
  SecondaryTypography,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Box,
  Button,
  MenuItem,
  Grid,
  Switch,
  IconButton,
  Divider,
  Modal,
  Skeleton,
  Checkbox,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../Redux/AxiosInstance";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import CustomDateRangePicker from "../../../CommonComponents/CustomDateRange";
import { DatePicker } from "@mui/x-date-pickers";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { makeStyles } from "@mui/styles";
import { userData, getChip } from "../../../Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white ",
    "& .MuiDateRangeCalendar-root ": { color: "red !important" },
    // "& .css-968uz8-MuiStack-root-MuiMultiInputDateRangeField-root": {
    //   border: "2px solid red"
    // },
    "&.MuiDateRangeCalendar-root > div ": {
      height: "470px !important",
    },
    "&.MuiDateRangeCalendar-root > div:nth-child(1)": {
      color: "white !important",
      zIndex: "-100000 !important",
    },
    // "& .css-jef1b6-MuiDateRangeCalendar-container": { color: "red !important" },
  },
}));

const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

// let cancelOffboardData = {
//     label: 'Confirm Cancel Offboard ',
//     descriptionOne: 'Please confirm that you wish to cancel the offboard request.',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }

// let cancelScheduledInactiveData = {
//     label: 'Confirm Cancel Scheduled Inactive ',
//     descriptionOne: 'Please confirm that you wish to cancel the Scheduled Inactive.',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }

// let offboardModalData = {
//     label: 'Offboard User Now',
//     descriptionOne: 'Please confirm that you wish to offboard user now. After offboarded, the information cannot be recovered.',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#B42318',
//         text: 'Confirm Offboard'
//     },
// }

// let InactiveData = {
//     label: 'Confirm Inactive ',
//     descriptionOne: 'Please confirm that you wish to Inactive the staff',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }
// let activeModalData = {
//     label: 'Confirm active ',
//     descriptionOne: 'Please confirm that you wish to active the staff',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }
const ReviewStaffDetails = () => {
  const { propertyId, userRole } = useSelector(
    (state) => state.PropertyDetails
  );
  const { userDetails, UserManage } = useSelector((state) => state.Login);

  const initialState = { offboard: false, inActive: false, active: false };
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [modalCheckboxStatus, setModalCheckboxStatus] = useState({
    offboard: false,
    inActive: false,
    active: false,
    changeInactive: false,
  });
  const [offboardDate, setOffboardDate] = useState(null);
  const [modalError, setModalError] = useState({
    offboard: "",
    inActive: "",
    active: "",
    selected: "",
  });
  const [showAlert, setShowAlert] = useState({
    cancel: false,
    inactive: false,
    offboard: false,
    active: false,
    cancel_Scheduled_inactive: false,
    changeInactive: false,
  });

  const [PersonalDetailsData, setPersonalDetailsData] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    dob: "",
    gender: "",
    nationality: "",
    inorpn: "",
    phone: "",
    countrycode: "",
    email: "",
    address: "",
    country: "",
    city: "",
    postalcode: "",
    fullName: "",
  });
  const [emergencyContactData, setemergencyContactData] = useState({
    firstname: "",
    lastname: "",
    // gender: '',
    // nationality: '',
    phone: "",
    email: "",
    relationship: "",
  });
  const [WorkAccessData, setWorkAccessData] = useState({
    employmenttype: "",
    department: "",
    jobrole: "",
    accessstartdate: "",
    accessenddate: null,
    inactive: null,
  });

  const [onboardedby, setonboardedby] = useState({
    name: "",
    email: "",
    department: "",
    jobrole: "",
    employmentId: 0,
  });

  const [offboardedBy, setOffboardedBy] = useState({
    name: "",
    role: "",
    department: "",
  });
  const [inactiveBy, setInactiveBy] = useState({
    name: "",
    role: "",
    department: "",
  });

  const [nationalityData, setnationalityData] = useState("");
  const [genderData, setgenderData] = useState([]);
  const [employeeTypeData, setemployeeTypeData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [departmentIndex, setDepartmentIndex] = useState("");

  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([]);
  const [isStatus, setIsStatus] = useState(true);
  const [EmploymentId, setEmploymentId] = useState(0);
  const [dateRangeSubmit, setdateRangeSubmit] = useState(false);
  const [loading, setloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  //changeInactiveDate
  const [openChangeDateRangePicker, setopenChangeDateRangePicker] =
    useState(false);
  const [Changedatetimerangedata, setChangedatetimerangedata] = useState([]);

  const getStaffData = async () => {
    setloading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}user_details?user_id=${id}`
      );
      const data = response.data.data.user_details;

      setloading(false);
      setSelectedData(data.access_details);
      setPersonalDetailsData({
        firstname: data?.first_name,
        lastname: data?.last_name,
        middlename: data?.middle_name,
        dob: data?.dob,
        gender: data?.gender,
        nationality: data?.nationality,
        inorpn: data?.identity_number,
        phone: data?.phone,
        countrycode: data?.country_code,
        email: data?.email,
        address: data?.address,
        country: data?.country,
        city: data?.city,
        postalcode: data?.zipcode,
        fullName: data?.full_name,
      });
      setemergencyContactData({
        firstname: data?.emergency_contacts?.first_name,
        lastname: data?.emergency_contacts?.last_name,
        // gender: data?.emergency_contacts?.gender,
        // nationality: data?.emergency_contacts?.nationality,
        countrycode: data?.emergency_contacts?.country_code,
        phone: data?.emergency_contacts?.phone,
        email: data?.emergency_contacts?.email,
        relationship: data?.emergency_contacts?.relationship,
      });
      setWorkAccessData({
        employmenttype: data?.access_details?.residency_type.name,
        department: data?.access_details?.department_id,
        jobrole: data?.access_details?.role_id,
        accessstartdate:
          data?.access_details?.access_start != null
            ? moment.utc(data?.access_details?.access_start).local()
            : null,
        accessenddate:
          data?.access_details?.access_end != null
            ? moment.utc(data?.access_details?.access_end).local()
            : null,
        updated_at: moment.utc(data?.access_details?.updated_at).local(),
        status: data?.access_details?.status,
        inactive: data?.access_details?.inactive,
      });

      setEmploymentId(data?.access_details?.residency_type?.id);

      setonboardedby({
        name: data?.access_details?.onboarded_user_details?.name,
        email: data?.access_details?.onboarded_user_details?.email,
        department:
          data?.access_details?.onboarded_user_details?.access_details
            ?.department_details?.department_name,
        jobrole:
          data?.access_details?.onboarded_user_details?.access_details
            ?.role_details?.title,
        employmentId: data?.access_details?.onboarded_user_details?.user_role,
      });
      setChangedatetimerangedata([
        dayjs(data?.access_details?.inactive?.start_date),
        dayjs(data?.access_details?.inactive?.end_date),
      ]);
      setOffboardedBy({
        name: data?.access_details?.offboarded_user_details?.name,
        role: data?.access_details?.offboarded_user_details?.access_details
          ?.role_details?.title,
        department:
          data?.access_details?.offboarded_user_details?.access_details
            ?.department_details?.department_name,
      });
      setInactiveBy({
        name: data?.access_details?.inactive?.last_update_details?.user_details
          ?.name,
        role: data?.access_details?.inactive?.last_update_details?.role_details
          ?.title,
        department:
          data?.access_details?.inactive?.last_update_details
            ?.department_details?.department_name,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getGenderData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=gender`
        );
        const genderData = response.data.data.drop_down;
        setgenderData(genderData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGenderData();

    const getDepartment = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/role`
        );
        const departmentdata = response.data.data.property_role;
        setDepartment(departmentdata);
      } catch (error) {
        console.log(error.message);
      }
    };
    getDepartment();

    getStaffData();
  }, []);

  const offboardremaininghours =
    (moment(WorkAccessData?.accessenddate)._d - moment(new Date())._d) /
    (1000 * 60 * 60);

  const closeModal = () => {
    setIsStatus(true);
    setChangeStatusModal(false);
    // setSelectedData({})
    setdatetimerangedata([]);
    setOffboardDate(null);
    setModalCheckboxStatus({
      offboard: false,
      inActive: false,
      active: false,
      changeInactive: false,
    });
    SetPostLoader(false);
  };

  const handleModalCheckboxStatus = (key) => {
    if (key !== "inActive") {
      setdatetimerangedata([]);
      setOffboardDate(null);
    }
    setModalCheckboxStatus({
      ...initialState,
      [key]: !modalCheckboxStatus[key],
    });
  };

  const closeAlert = () => {
    setShowAlert({
      cancel: false,
      inactive: false,
      offboard: false,
      active: false,
      changeInactive: false,
      cancel_Scheduled_inactive: false,
    });
  };

  const validate = () => {
    let hasError = [];

    if (
      modalCheckboxStatus.inActive == false &&
      modalCheckboxStatus.offboard == false &&
      modalCheckboxStatus.active == false &&
      modalCheckboxStatus.changeInactive == false
    ) {
      setModalError({
        ...modalError,
        selected: "Please select one option",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        selected: "",
      });
    }

    if (modalCheckboxStatus.offboard == true && offboardDate === null) {
      setModalError({
        ...modalError,
        offboard: "Please select offboard date",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        offboard: "",
      });
    }

    if (
      (modalCheckboxStatus.inActive == true &&
        (datetimerangedata[0] == null || datetimerangedata[1] == null)) ||
      (modalCheckboxStatus.changeInactive == true &&
        Changedatetimerangedata.length < 2)
    ) {
      setModalError({
        ...modalError,
        inActive: "Please select date range",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        inActive: "",
      });
    }
  };

  useEffect(() => {
    if (!validate()) {
      setIsStatus(false);
    } else {
      setIsStatus(true);
    }
  }, [
    datetimerangedata,
    offboardDate,
    selectedData,
    modalCheckboxStatus,
    Changedatetimerangedata,
  ]);

  const handleModalConfirm = () => {
    let hasError = validate();

    if (!hasError) {
      if (modalCheckboxStatus.inActive) {
        setShowAlert({
          ...showAlert,
          inactive: true,
        });
      } else if (modalCheckboxStatus.offboard) {
        setShowAlert({
          ...showAlert,
          offboard: true,
        });
      } else if (modalCheckboxStatus.active) {
        setShowAlert({
          ...showAlert,
          active: true,
        });
      } else if (modalCheckboxStatus.changeInactive) {
        setShowAlert({
          ...showAlert,
          changeInactive: true,
        });
      }

      // closeModal()
    }
  };

  const offboardStaff = () => {
    const checkstartDate =
      moment(offboardDate).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const startFormatToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const AccessEnd = offboardDate;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: [selectedData.user_id],
      status: "offboard",
      access_end: checkstartDate ? startFormatToday : final1End,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getStaffData();
        closeAlert();
        closeModal();
        SetPostLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inactiveStaff = () => {
    const checkstartDate =
      moment(datetimerangedata[0].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const startFormatToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkendDate =
      moment(datetimerangedata[1].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const endFormatToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const currentDate = moment().startOf("day");
    const startFormat = moment(datetimerangedata[0].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalStart = moment.utc(startFormat).format("YYYY-MM-DD HH:mm:ss");
    const endFormat = moment(datetimerangedata[1].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalEnd = moment.utc(endFormat).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: [selectedData.user_id],
      status: "inactive",
      start_date: checkstartDate ? startFormatToday : finalStart,
      end_date: checkendDate ? endFormatToday : finalEnd,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getStaffData();
        closeAlert();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const activeStaff = () => {
    SetPostLoader(true);
    const data = { user_ids: [selectedData.user_id], status: "active" };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getStaffData();
        closeAlert();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ChangeinactiveStaffdate = () => {
    const checkstartDate =
      moment(Changedatetimerangedata[0].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const startFormatToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkendDate =
      moment(Changedatetimerangedata[1].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const endFormatToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const startFormat = moment(Changedatetimerangedata[0].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalStart = moment.utc(startFormat).format("YYYY-MM-DD HH:mm:ss");
    const endFormat = moment(Changedatetimerangedata[1].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalEnd = moment.utc(endFormat).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: [selectedData.user_id],
      status: "inactive",
      start_date: checkstartDate ? startFormatToday : finalStart,
      end_date: checkendDate ? endFormatToday : finalEnd,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getStaffData();
        closeAlert();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Modal Datas

  let cancelOffboardData = {
    label: "Confirm Cancel Offboard ",
    descriptionOne: `Please confirm that you wish to confirm the scheduled offboard date of ${moment(
      WorkAccessData?.accessenddate
    ).format("DD MMM YYYY")}`,
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  let cancelScheduledInactiveData = {
    label: "Confirm Cancel Scheduled Inactive ",
    descriptionOne:
      "Please confirm that you wish to cancel the Scheduled Inactive.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  let offboardModalData = {
    label: "Offboard User Now",
    descriptionOne:
      "Please confirm that you wish to offboard user now. After offboarded, the information cannot be recovered.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: "Confirm Offboard",
    },
  };

  let InactiveData = {
    label: "Confirm Inactive ",
    descriptionOne: "Please confirm that you wish to Inactive the staff",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };
  let activeModalData = {
    label: "Confirm active ",
    descriptionOne: "Please confirm that you wish to active the staff",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  let changeInactiveData = {
    label: "Confirm Change of Inactive End Date",
    descriptionOne: `Please confirm that you wish to change inactive end date to ${moment(
      new Date(Changedatetimerangedata[1])
    ).format("DD MMM YYYY")}.`,
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showAlert.cancel_Scheduled_inactive && (
        <AlertModal
          modalDatas={cancelScheduledInactiveData}
          confirmFunction={activeStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.cancel && (
        <AlertModal
          modalDatas={cancelOffboardData}
          confirmFunction={activeStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.inactive && (
        <AlertModal
          modalDatas={InactiveData}
          confirmFunction={inactiveStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.offboard && (
        <AlertModal
          modalDatas={offboardModalData}
          confirmFunction={offboardStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.active && (
        <AlertModal
          modalDatas={activeModalData}
          confirmFunction={activeStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.changeInactive && (
        <AlertModal
          modalDatas={changeInactiveData}
          confirmFunction={ChangeinactiveStaffdate}
          closeFunction={closeAlert}
        />
      )}

      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <BackArrow
          onClick={() => navigate("/staffs")}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {loading ? <Skeleton /> : PersonalDetailsData?.fullName}{" "}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {loading ? (
                <Skeleton width={300} />
              ) : (
                "Last Updated: " +
                (WorkAccessData?.updated_at
                  ? moment(WorkAccessData.updated_at).format(
                      "DD MMM YYYY , HH:mm"
                    )
                  : "-")
              )}
            </StyledSecondaryTypography>
          </Box>
          {UserManage?.staffs && !loading && (
            <Button
              onClick={() => navigate(`/staffs/editStaff/${id}`)}
              sx={{
                fontWeight: "600",
                height: "40px",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "8px 6px 8px 2px",

                "&:hover": {
                  backgroundColor: "#CFDFE6",
                  border: "none",
                },
              }}
            >
              {" "}
              <IconButton sx={{ paddingLeft: "10px" }}>
                {" "}
                <EditIcon width="14px" />
              </IconButton>{" "}
              Edit Details
            </Button>
          )}
        </Stack>
        {loading ? (
          <Skeleton variant="rounded" width="100%" height={1000} />
        ) : (
          <>
            {WorkAccessData?.inactive !== null && (
              <Box
                my={4}
                padding="20px 16px"
                backgroundColor="#FFFAEB"
                border="1px solid #FEE6A1"
                borderRadius="8px"
              >
                <StyledTypography
                  mb="10px"
                  sx={{ color: "#B54708 !important" }}
                >
                  Scheduled for Inactive
                </StyledTypography>
                <TypographyDefault sx={{ color: "#B54708 !important" }}>
                  The staff will be marked as inactive for the period of{" "}
                  {moment
                    .utc(WorkAccessData?.inactive?.start_date)
                    .local()
                    .format("DD MMM YYYY")}{" "}
                  -{" "}
                  {moment
                    .utc(WorkAccessData?.inactive?.end_date)
                    .local()
                    .format("DD MMM YYYY")}
                  . (Status change by {inactiveBy?.name + ", "}
                  {inactiveBy?.role && inactiveBy?.role + ","}
                  {inactiveBy?.department || "Management"}). During the inactive
                  period, the staff will not be able to be assigned or tagged to
                  cases, their existing cases will be forwarded back to the
                  assignee for re-assignment.
                </TypographyDefault>
              </Box>
            )}
            {WorkAccessData?.accessenddate !== null && (
              <Box
                my={4}
                padding="20px 16px"
                backgroundColor="#FFFAEB"
                border="1px solid #FEE6A1"
                borderRadius="8px"
              >
                <StyledTypography
                  mb="10px"
                  sx={{ color: "#B54708 !important" }}
                >
                  Scheduled for Offboard
                </StyledTypography>
                <TypographyDefault sx={{ color: "#B54708 !important" }}>
                  The staff will be marked as offboarding by{" "}
                  {moment(WorkAccessData?.accessenddate).format("DD MMM YYYY")}.
                  (Status change by {offboardedBy?.name + ", "}{" "}
                  {offboardedBy?.role && offboardedBy?.role + ", "}{" "}
                  {offboardedBy?.department || "Management"}). After{" "}
                  {String(offboardremaininghours).split(".")[0]} hours from this
                  date, they will no longer appear in the system and their
                  details will be wiped out.
                </TypographyDefault>
              </Box>
            )}
            {/* Active */}
            {WorkAccessData?.status?.key === "active" && (
              <Box
                my={4}
                backgroundColor="white"
                alignItems="center"
                padding="24px 16px 24px 16px"
                sx={{ borderRadius: "8px 8px 8px 8px " }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" mb={1}>
                    <SecondaryTypography marginRight={2}>
                      Staff is
                    </SecondaryTypography>
                    {getChip("active")}
                  </Stack>
                  {UserManage?.staffs && (
                    <StyledSwitch
                      checked={true}
                      onClick={() => {
                        setChangeStatusModal(true);
                      }}
                      name="loading"
                      color="primary"
                    />
                  )}
                </Stack>
                <TypographyDefault>
                  The staff is currently marked as an active user in the system.
                </TypographyDefault>
              </Box>
            )}
            {/* Inactive */}
            {WorkAccessData?.status?.key === "inactive" && (
              <Box
                my={4}
                backgroundColor="white"
                alignItems="center"
                padding="24px 16px 24px 16px"
                sx={{ borderRadius: "8px 8px 8px 8px " }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" mb={1}>
                    <SecondaryTypography marginRight={2}>
                      Staff is
                    </SecondaryTypography>
                    {getChip("inactive")}
                  </Stack>
                  {UserManage?.staffs && (
                    <StyledSwitch
                      checked={false}
                      onClick={() => {
                        setChangeStatusModal(true);
                      }}
                      name="loading"
                      color="primary"
                    />
                  )}
                </Stack>
                <TypographyDefault>
                  The staff was marked as inactive for the period of{" "}
                  {moment
                    .utc(WorkAccessData?.inactive?.start_date)
                    .local()
                    .format("DD MMM YYYY")}{" "}
                  -{" "}
                  {moment
                    .utc(WorkAccessData?.inactive?.end_date)
                    .local()
                    .format("DD MMM YYYY")}
                  . (Status change by Germaine, Administrator, Operations) The
                  staff will be active on{" "}
                  {moment
                    .utc(WorkAccessData?.inactive?.end_date)
                    .local()
                    .add(1, "day")
                    .format("DD MMM YYYY")}
                  .
                </TypographyDefault>
              </Box>
            )}
            {/* offboard */}
            {WorkAccessData?.status?.key === "offboard" && (
              <Box
                my={4}
                backgroundColor="white"
                alignItems="center"
                padding="24px 16px 24px 16px"
                sx={{ borderRadius: "8px 8px 8px 8px " }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" mb={1}>
                    <SecondaryTypography marginRight={2}>
                      Staff is
                    </SecondaryTypography>
                    <Button
                      variant="outlined"
                      sx={{
                        border: "1px solid #E9D7FE",
                        backgroundColor: "#F9F5FF",
                        color: "#6941C6",
                      }}
                    >
                      Offboard
                    </Button>
                  </Stack>
                  {UserManage?.staffs && (
                    <StyledSwitch
                      checked={false}
                      onClick={() => {
                        setChangeStatusModal(true);
                      }}
                      name="loading"
                      color="primary"
                    />
                  )}
                </Stack>
                <TypographyDefault>
                  The staff is currently in offboarding as of 28 Jan 2023.
                  (Status change by Germaine, Administrator, Operations) The
                  staff will be permanently offboarded on 30 JAN 2023. .
                </TypographyDefault>
              </Box>
            )}
            {/* Personal Details */}

            <Box
              mt={3}
              component="div"
              boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
              borderRadius="8px 8px 0 0"
            >
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                  borderRadius: "8px 8px 0 0 ",
                }}
              >
                <SecondaryTypography>
                  Staff Basic Information
                </SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    First Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.firstname}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Last Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.lastname}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Middle Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {Boolean(PersonalDetailsData.middlename)
                      ? PersonalDetailsData.middlename
                      : "-"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Date of Birth
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {moment(new Date(PersonalDetailsData.dob)).format(
                      "DD MMM YYYY"
                    )}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                  {genderData.map((itm) => {
                    if (PersonalDetailsData.gender == itm.id) {
                      return <TypographyDefault>{itm.name}</TypographyDefault>;
                    }
                  })}{" "}
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Nationality
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.nationality}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Identity Number / Passport Number
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.inorpn}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography sx={{ marginBottom: "10px" }}>
                    Contact Number
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData?.countrycode}{" "}
                    {PersonalDetailsData.phone}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Email Address
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.email}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Address</StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.address}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Country</StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.country}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>City</StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.city}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Postal Code
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.postalcode}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
            {/* Emergency Contact */}
            <Box
              component="div"
              boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
              borderRadius="0px"
            >
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>Emergency Contact</SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    First Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.firstname || "-"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Last Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.lastname || "-"}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Email Address
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.email || "-"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Contact Number
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.countrycode +
                      " " +
                      emergencyContactData.phone || " - "}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                {/* <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
                            <TypographyDefault>{emergencyContactData.nationality}</TypographyDefault>
                        </Grid>
                        <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                            {genderData.map((itm) => {
                                if (emergencyContactData.gender == itm.id) {
                                    return <TypographyDefault>{itm.name}</TypographyDefault>
                                }
                            })}   </Grid>
                        <Divider sx={{ borderTop: '1px solid #F3F4F6', width: '100%', marginTop: '10px' }} /> */}
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Relationship
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.relationship || "-"}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
            {/* Work & Access Details */}
            <Box component="div" boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)">
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>Work & Access Details</SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Employment Type
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {WorkAccessData.employmenttype}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Department
                  </StyledSecondaryTypography>
                  {department?.map((itm, index) => {
                    if (WorkAccessData.department == itm.id) {
                      return (
                        <TypographyDefault>
                          {itm.department_name}
                        </TypographyDefault>
                      );
                    }
                  })}
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Job Role
                  </StyledSecondaryTypography>
                  {department?.map((itm) => {
                    if (itm.id == WorkAccessData.department) {
                      return itm.role.map((sitm) => {
                        if (WorkAccessData.jobrole == sitm.id) {
                          return (
                            <TypographyDefault>{sitm.title}</TypographyDefault>
                          );
                        }
                      });
                    }
                  })}
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Access Start Date
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {moment(new Date(WorkAccessData.accessstartdate)).format(
                      "DD MMM YYYY"
                    )}
                  </TypographyDefault>
                </Grid>
                {EmploymentId === 1 && (
                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      Access End Date
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {WorkAccessData.accessenddate != null
                        ? moment(new Date(WorkAccessData.accessenddate)).format(
                            "DD MMM YYYY"
                          )
                        : "-"}
                    </TypographyDefault>
                  </Grid>
                )}
              </Grid>
            </Box>
            {/* Onboarded by */}
            <Box
              component="div"
              boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
              borderRadius="0 0 8px 8px"
            >
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>Onboarded by</SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                  borderRadius: "0 0 8px 8px  ",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Name</StyledSecondaryTypography>
                  <TypographyDefault>{onboardedby.name}</TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Email</StyledSecondaryTypography>
                  <TypographyDefault>{onboardedby.email}</TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                {onboardedby?.employmentId === 2 ? (
                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      Department
                    </StyledSecondaryTypography>
                    <TypographyDefault>Condo admin</TypographyDefault>
                  </Grid>
                ) : (
                  <>
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Department
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {onboardedby.department}
                      </TypographyDefault>
                    </Grid>
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Job Role
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {onboardedby.jobrole}
                      </TypographyDefault>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </>
        )}
      </Box>

      <Modal open={changeStatusModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              height: "fit-content",
              maxHeight: "90%",
              overflowY: "scroll",
              "::-webkit-scrollbar": { display: "none" },
              backgroundColor: "#FFF",
              boxShadow:
                "0px 4px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 3px 0px rgba(0, 0, 0, 0.10)",
              border: "1px solid #F3F4F6",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <PrimaryTypography>
                Change status to the account
              </PrimaryTypography>
              <Close onClick={closeModal} />
            </Box>
            <Stack rowGap={2} sx={{ p: 2 }} direction={"column"}>
              <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                You are able to change the status of the user’s account here.
                Please select one of the options below.
              </Typography>
              <Stack direction="row" alignItems="center">
                <span>{getChip(selectedData?.status?.key)}</span>
                <Stack width="85%" direction="column">
                  {(selectedData?.status?.key === "inactive" ||
                    WorkAccessData?.inactive !== null) && (
                    <Stack
                      ml={2}
                      width="100%"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <TypographyDefault>
                        {moment
                          .utc(selectedData?.inactive?.start_date)
                          .local()
                          .format("DD MMM YYYY")}{" "}
                        -{" "}
                        {moment
                          .utc(selectedData?.inactive?.end_date)
                          .local()
                          .format("DD MMM YYYY")}
                      </TypographyDefault>
                      {/* <TypographyDefault onClick={() => { handleModalCheckboxStatus('inActive') }} sx={{ textDecoration: "underline", '&:hover': { cursor: 'pointer' } }}>Change Date</TypographyDefault> */}
                      <TypographyDefault
                        onClick={() => {
                          handleModalCheckboxStatus("changeInactive");
                        }}
                        sx={{
                          textDecoration: "underline",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        Change Inactive Date
                      </TypographyDefault>
                    </Stack>
                  )}
                  {(selectedData?.status?.key === "active" ||
                    selectedData?.status?.key === "inactive") &&
                    selectedData?.access_end !== null && (
                      <Stack
                        mt={1}
                        ml={2}
                        width="100%"
                        direction="row"
                        justifyContent="space-between"
                      >
                        <TypographyDefault>
                          Offboard on{" "}
                          {moment
                            .utc(selectedData?.access_end)
                            .local()
                            .format("DD MMM YYYY")}{" "}
                        </TypographyDefault>
                        <TypographyDefault
                          onClick={() => {
                            setShowAlert({
                              cancel: true,
                              inactive: false,
                              offboard: false,
                              active: false,
                              cancel_Scheduled_inactive: false,
                              changeInactive: false,
                            });
                          }}
                          sx={{
                            textDecoration: "underline",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          Cancelled Offboard
                        </TypographyDefault>
                      </Stack>
                    )}

                  {selectedData?.status?.key === "active" &&
                    WorkAccessData?.inactive !== null && (
                      <Stack
                        width="103%"
                        mt={1}
                        direction="column"
                        alignItems="end"
                        justifyContent="end"
                      >
                        {/* <TypographyDefault>Offboard on {moment(selectedData?.access_end).format("DD MMM YYYY")} </TypographyDefault> */}
                        {/* <TypographyDefault>{moment(WorkAccessData?.inactive?.start_date).format("DD MMM YYYY")} - {moment(WorkAccessData?.inactive?.end_date).format("DD MMM YYYY")}</TypographyDefault> */}
                        <TypographyDefault
                          onClick={() => {
                            setShowAlert({
                              cancel: false,
                              inactive: false,
                              offboard: false,
                              active: false,
                              cancel_Scheduled_inactive: true,
                              changeInactive: false,
                            });
                          }}
                          sx={{
                            textDecoration: "underline",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          Cancel Scheduled Inactive
                        </TypographyDefault>
                        {/* <TypographyDefault onClick={() => { handleModalCheckboxStatus('changeInactive') }} sx={{ textDecoration: "underline", '&:hover': { cursor: 'pointer' } }}>change Inactive Date</TypographyDefault> */}
                      </Stack>
                    )}
                </Stack>
              </Stack>
              <StyledDefaultTypography my={1.5}>
                Status Options
              </StyledDefaultTypography>
              {selectedData?.status?.key !== "inactive" &&
                WorkAccessData?.inactive == null && (
                  <Stack
                    className={modalCheckboxStatus.inActive && "selectedStyle"}
                    rowGap={1}
                    sx={{
                      backgroundColor: "#F9FAFB",
                      p: 1,
                      "&.selectedStyle": {
                        backgroundColor: "#EDF6FA",
                        border: "1px solid #0E4965",
                        borderRadius: "8px",
                      },
                    }}
                    direction={"column"}
                  >
                    <StyledDefaultTypography>Inactive</StyledDefaultTypography>
                    <Stack
                      direction={"row"}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <StyledSecondaryTypography>
                        Temporarily offboard account in the event staff is away
                        for a long period of time. During inactive period,
                        he/she will not be able to perform tasks other than
                        logging in.{" "}
                      </StyledSecondaryTypography>
                      <Checkbox
                        onChange={() => handleModalCheckboxStatus("inActive")}
                        checked={modalCheckboxStatus.inActive}
                        size="small"
                        icon={<CheckboxIcon />}
                      />
                    </Stack>
                  </Stack>
                )}
              {selectedData?.access_end === null && (
                <Stack
                  className={modalCheckboxStatus.offboard && "selectedStyle"}
                  rowGap={1}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    p: 1,
                    "&.selectedStyle": {
                      backgroundColor: "#EDF6FA",
                      border: "1px solid #0E4965",
                      borderRadius: "8px",
                    },
                  }}
                  direction={"column"}
                >
                  <StyledDefaultTypography>
                    Offboard User
                  </StyledDefaultTypography>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                  >
                    <StyledSecondaryTypography>
                      User will not be able to access this account after the
                      stated date, and his/ her active cases will be
                      automatically assigned back to sender.
                    </StyledSecondaryTypography>
                    <Checkbox
                      onChange={() => handleModalCheckboxStatus("offboard")}
                      checked={modalCheckboxStatus.offboard}
                      size="small"
                      icon={<CheckboxIcon />}
                    />
                  </Stack>
                </Stack>
              )}
              {selectedData?.status?.key !== "active" && (
                <Stack
                  className={modalCheckboxStatus.active && "selectedStyle"}
                  rowGap={1}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    p: 1,
                    "&.selectedStyle": {
                      backgroundColor: "#EDF6FA",
                      border: "1px solid #0E4965",
                      borderRadius: "8px",
                    },
                  }}
                  direction={"column"}
                >
                  <StyledDefaultTypography>
                    Change to Active
                  </StyledDefaultTypography>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                  >
                    <StyledSecondaryTypography>
                      User will be able to be assign or be assigned to cases,
                      post or book facilties and make announcements effective
                      immediate.
                    </StyledSecondaryTypography>
                    <Checkbox
                      onChange={() => handleModalCheckboxStatus("active")}
                      checked={modalCheckboxStatus.active}
                      size="small"
                      icon={<CheckboxIcon />}
                    />
                  </Stack>
                </Stack>
              )}
              {modalCheckboxStatus.offboard && (
                <DatePicker
                  minDate={
                    WorkAccessData?.inactive?.end_date != null
                      ? dayjs(WorkAccessData?.inactive?.end_date).add(1, "day")
                      : dayjs(WorkAccessData?.inactive?.end_date)
                  }
                  onChange={(e) => setOffboardDate(e.$d)}
                  format="DD/MM/YYYY"
                  slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                  sx={DatePickerStyle}
                />
              )}

              {modalCheckboxStatus.inActive && (
                <Stack
                  onClick={() => setopenDateRangePicker(true)}
                  sx={{
                    height: "47px",
                    padding: "2px 12px 2px 12px",
                    borderRadius: "8px",
                    backgroundColor: "#F9FAFB",
                  }}
                  direction="row"
                  height="40px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {Boolean(datetimerangedata[0]) ? (
                    <StyledSecondaryDefaultTypography>
                      {moment(datetimerangedata[0]?.$d).format("MMM D YYYY")} -{" "}
                      {moment(datetimerangedata[1]?.$d).format("MMM D YYYY")}{" "}
                    </StyledSecondaryDefaultTypography>
                  ) : (
                    <StyledSecondaryDefaultTypography
                      sx={{ color: "#9DA4AE !important" }}
                    >
                      Please select a inactive date
                    </StyledSecondaryDefaultTypography>
                  )}
                  <DatePickerIcon />
                </Stack>
              )}

              {modalCheckboxStatus.changeInactive && (
                <>
                  <Stack
                    onClick={() => setopenChangeDateRangePicker(true)}
                    sx={{
                      height: "47px",
                      padding: "2px 12px 2px 12px",
                      borderRadius: "8px",
                      backgroundColor: "#F9FAFB",
                    }}
                    direction="row"
                    height="40px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {Boolean(Changedatetimerangedata[0]) ? (
                      <StyledSecondaryDefaultTypography>
                        {moment(Changedatetimerangedata[0]?.$d).format(
                          "MMM D YYYY"
                        )}{" "}
                        -{" "}
                        {moment(Changedatetimerangedata[1]?.$d).format(
                          "MMM D YYYY"
                        )}{" "}
                      </StyledSecondaryDefaultTypography>
                    ) : (
                      <StyledSecondaryDefaultTypography
                        sx={{ color: "#9DA4AE !important" }}
                      >
                        Please select a inactive date
                      </StyledSecondaryDefaultTypography>
                    )}
                    <DatePickerIcon />
                    {/* <DatePicker disablePast format="DD/MM/YYYY" slots={{ openPickerIcon: () => <DatePickerIcon /> }} sx={DatePickerStyle} /> */}
                  </Stack>
                  <DateRangePicker
                    minDate={dayjs(WorkAccessData?.inactive?.start_date).add(
                      1,
                      "day"
                    )}
                    calendars={1}
                    open={openChangeDateRangePicker}
                    value={[
                      dayjs(WorkAccessData?.inactive?.start_date),
                      Changedatetimerangedata[1],
                    ]}
                    onChange={(e) => {
                      setChangedatetimerangedata([
                        dayjs(WorkAccessData?.inactive?.start_date),
                        e[1],
                      ]);
                    }}
                    onClose={() => setopenChangeDateRangePicker(false)}
                    sx={{
                      "&>*": {
                        visibility: "hidden",
                        width: "0px",
                        height: "0px !important",
                      },
                      height: "0px",
                      width: "0px",
                      marginLeft: "50%",
                    }}
                  />
                </>
              )}

              {/* {modalError.offboard && <ErrorTypography>{modalError.offboard}</ErrorTypography>}
                            {modalError.selected && <ErrorTypography>{modalError.selected}</ErrorTypography>}
                            {modalError.inActive && <ErrorTypography>{modalError.inActive}</ErrorTypography>} */}
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={handleModalConfirm}
                disabled={
                  (isStatus || openDateRangePicker) == true ? true : false
                }
                style={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* DateRange picker for Inactive */}

      {openDateRangePicker && (
        <CustomDateRangePicker
          openDateRangePicker={openDateRangePicker}
          setopenDateRangePicker={setopenDateRangePicker}
          datetimerangedata={datetimerangedata}
          setdatetimerangedata={setdatetimerangedata}
          setdateSubmit={setdateRangeSubmit}
          maxDate={selectedData?.access_end}
        />
      )}

      {/* DateRange picker for change Inactive end date*/}
      {/* {openChangeDateRangePicker && */}
      {/* } */}

      {/* {openDateRangePicker && */}
      {/* <Box
                sx={{ backgroundColor: "#F9FAFB", position: "absolute", top: "10px" }}
            >
                <DateRangePicker
                    classes={useStyles()}
                    sx={{
                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                            display: "none !important"
                        },
                        "& .css-8wfwvd-MuiTypography-root-MuiMultiInputDateRangeField-separator": {
                            display: "none"
                        },
                        "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
                            width: "fit-Content"
                        }
                    }}
                    localeText={{ start: "", end: "" }}
                    open={openDateRangePicker}
                    onChange={(e) => setdatetimerangedata(e)}
                    disablePast
                />

                <Box
                    sx={{
                        position: "absolute",
                        zIndex: "1301",
                        transform: "translate3d(10px, 10px, 0px)",
                        top: "400px",
                        width: '118%',
                        padding: "20px 15px 20px 15px",
                        borderTop: "2px solid #F2F4F7",
                        backgroundColor: "white",
                        display: openDateRangePicker ? '' : 'none',
                        transition: 'opacity 2s ease-in',
                        transition: 'opacity 2s ease-out',
                        opacity: openDateRangePicker ? 1 : 0,
                        webkitanimation: "fadeIn 2.5s",
                        animation: "fadeIn 2.5s"

                    }}
                >
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Stack direction='row' justifyContent='start' alignItems='center'>
                            <StyledSecondaryDefaultTypography mr={1} sx={{ height: '25px', color: "#101828 !important", width: "120px", padding: "10px 14px 10px 14px", border: '1px solid #D0D5DD', borderRadius: "8px" }}>{Boolean(datetimerangedata[0]) ? moment(datetimerangedata[0]?.$d).format('MMM D, YYYY') : ''}</StyledSecondaryDefaultTypography>
                            -
                            <StyledSecondaryDefaultTypography m={1} sx={{ height: '25px', color: "#101828 !important", width: "120px", padding: "10px 14px 10px 14px", border: '1px solid #D0D5DD', borderRadius: "8px" }}>{Boolean(datetimerangedata[1]) ? moment(datetimerangedata[1]?.$d).format('MMM D, YYYY') : ''}</StyledSecondaryDefaultTypography>

                        </Stack>

                        <Stack direction='row' columnGap={2} >
                            <Button onClick={() => { setopenDateRangePicker(false); setdatetimerangedata([]) }} variant="outlined">Cancel</Button>
                            <Button onClick={() => setopenDateRangePicker(false)} variant='contained'>Apply</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box> */}
    </>
  );
};

export default ReviewStaffDetails;
