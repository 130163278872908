import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Avatar,
  Stack,
  Box,
  Grid,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";
import axiosInstance from "../../../Redux/AxiosInstance";
import moment from "moment";

const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

function stringToColor(string) {
  console.log("stringToColor", string);

  // Array of colors
  const colors = ["#EBE1CE", "#009C70", "#0E5E84", "#FEE4E2"];

  // Generate a hash based on the string to choose an index for the colors array
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Use the hash to pick a color from the array (modulo operation ensures the index is within bounds)
  const colorIndex = Math.abs(hash) % colors.length;

  return colors[colorIndex];
}

function stringAvatar(name) {
  if (/\s/.test(name)) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: stringToColor(name) === "#0E5E84" ? "white" : "#111927",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  } else {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: stringToColor(name) === "#0E5E84" ? "white" : "#111927",
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
}

const VisitorsDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visitors, setvisitors] = useState([]);
  console.log(visitors);
  const [block, setblock] = useState({
    wovisitor: false,
    wvisitor: false,
    selfregister: false,
  });
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}invite_visitor/property/${propertyId}/invitation/${id}`
      )
      .then((data) => {
        console.log("data", data.data);
        const dataa = data?.data?.data?.invitation;
        setvisitors(dataa);
        // console.log("data", dataa);
        setloading(false);

        if (dataa?.type_id === 3) {
          setblock({ wovisitor: false, wvisitor: false, selfregister: true });
        } else {
          if (dataa.invited_visitor?.length > 0 && dataa?.type_id === 2) {
            setblock({ wovisitor: true, wvisitor: true, selfregister: false });
          } else if (
            dataa.invited_visitor?.length == 0 &&
            dataa?.type_id === 1
          ) {
            setblock({ wovisitor: true, wvisitor: false, selfregister: false });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => navigate("/Visitors")}
            style={{ marginBottom: "26px", cursor: "pointer" }}
          />
        </Stack>
        <Box mb={5}>
          <PrimaryTypography mb={1}>
            {loading ? <Skeleton width={200} /> : "#" + visitors?.uuid}
          </PrimaryTypography>
        </Box>
        {loading ? (
          <Skeleton variant="rounded" width="100%" height="50vh" />
        ) : (
          <>
            {block?.wovisitor && (
              <Box
                mt={3}
                component="div"
                boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
                borderRadius="8px"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  px="16px"
                  sx={{
                    height: "66px",
                    backgroundColor: "#FCFCFD",
                    border: "1px solid #E5E7EB",
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  <SecondaryTypography>Invitation Details</SecondaryTypography>
                </Stack>
                <Grid
                  container
                  xs={12}
                  gap="2.8%"
                  sx={{
                    backgroundColor: "white",
                    padding: "30px 16px 30px 16px",
                    border: "1px solid #E5E7EB",
                    borderRadius: "0 0 8px 8px  ",
                  }}
                >
                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      Invitation Range
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {visitors?.start_date
                        ? `${moment(visitors?.start_date).format(
                            "DD MMM YYYY"
                          )}- `
                        : "-"}
                      {visitors?.end_date
                        ? `${moment(visitors?.end_date).format("DD MMM YYYY")} `
                        : "-"}
                    </TypographyDefault>
                  </Grid>
                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      Location
                    </StyledSecondaryTypography>
                    {visitors?.location &&
                      visitors?.location?.map((result) => (
                        <TypographyDefault>{result}</TypographyDefault>
                      ))}
                    {/* <TypographyDefault>
                      {visitors?.location[0]?.unit == null
                        ? visitors?.location[0]?.common_area?.common_area_name
                        : visitors?.location[0]?.unit}
                    </TypographyDefault> */}
                  </Grid>
                  <Divider
                    sx={{
                      borderTop: "1px solid #F3F4F6",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  />
                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      Invited By
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {" "}
                      {visitors?.invited_user_personal_details?.full_name &&
                      visitors?.invited_user_unit?.unit_no ? (
                        <>
                          {visitors.invited_user_personal_details.full_name} •{" "}
                          {visitors.invited_user_unit.unit_no}
                        </>
                      ) : (
                        "-"
                      )}
                    </TypographyDefault>
                  </Grid>
                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      Contact Number
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {visitors?.invited_user_personal_details?.country_code &&
                      visitors?.invited_user_personal_details?.phone
                        ? visitors?.invited_user_personal_details
                            ?.country_code +
                          visitors?.invited_user_personal_details?.phone
                        : "-"}
                    </TypographyDefault>
                  </Grid>
                  <Divider
                    sx={{
                      borderTop: "1px solid #F3F4F6",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  />

                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      Visitor(s)
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {visitors?.total_count === 0
                        ? "-"
                        : visitors?.total_count + " " + "px"}
                    </TypographyDefault>
                  </Grid>
                </Grid>
              </Box>
            )}
            {block.wvisitor && (
              <Box
                mt={3}
                component="div"
                boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
                borderRadius="8px"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  px="16px"
                  sx={{
                    height: "66px",
                    backgroundColor: "#FCFCFD",
                    border: "1px solid #E5E7EB",
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  <SecondaryTypography>Visitor Details</SecondaryTypography>
                </Stack>
                <Grid
                  container
                  xs={12}
                  gap="2.8%"
                  sx={{
                    backgroundColor: "white",
                    padding: "30px 16px 30px 16px",
                    border: "1px solid #E5E7EB",
                    borderRadius: "0 0 8px 8px  ",
                  }}
                >
                  {visitors?.invited_visitor
                    ? visitors?.invited_visitor?.map((itm) => {
                        const { name, email } = itm;
                        return (
                          <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="30px"
                            >
                              <Avatar {...stringAvatar(name)} />
                              <Stack direction="column">
                                <PrimaryTypography>{name}</PrimaryTypography>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#4D5761",
                                  }}
                                >
                                  {email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                        );
                      })
                    : "-"}
                </Grid>
              </Box>
            )}

            {block.selfregister && (
              <Box
                mt={3}
                component="div"
                boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
                borderRadius="8px"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  px="16px"
                  sx={{
                    height: "66px",
                    backgroundColor: "#FCFCFD",
                    border: "1px solid #E5E7EB",
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  <SecondaryTypography>
                    {" "}
                    Self Registration Details
                  </SecondaryTypography>
                </Stack>
                {visitors?.invited_visitor?.map((value) => (
                  <Grid
                    container
                    xs={12}
                    gap="2.8%"
                    sx={{
                      backgroundColor: "white",
                      padding: "30px 16px 30px 16px",
                      border: "1px solid #E5E7EB",
                      borderRadius: "0 0 8px 8px  ",
                    }}
                  >
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Date & Time
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {visitors?.start_date && visitors?.end_date
                          ? moment
                              .utc(visitors.start_date)
                              .local()
                              .format("DD MMM YYYY") -
                            moment
                              .utc(visitors.end_date)
                              .local()
                              .format("DD MMM YYYY")
                          : "-"}
                      </TypographyDefault>
                    </Grid>
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Location
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {visitors?.location
                          ? visitors?.location?.map((result, index) => (
                              <TypographyDefault key={index}>
                                {result ? result : "-"}
                              </TypographyDefault>
                            ))
                          : "-"}
                      </TypographyDefault>
                    </Grid>
                    <Divider
                      sx={{
                        borderTop: "1px solid #F3F4F6",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    />
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Full Name
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {value?.name || "-"}
                      </TypographyDefault>
                    </Grid>
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Email Address
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {value?.email || "-"}
                      </TypographyDefault>
                    </Grid>
                    <Divider
                      sx={{
                        borderTop: "1px solid #F3F4F6",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    />
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Residents Unit Number
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {value?.unit_id || "-"}
                      </TypographyDefault>
                    </Grid>
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Contact Number
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {" "}
                        {value?.phone || "-"}
                      </TypographyDefault>
                    </Grid>
                    <Divider
                      sx={{
                        borderTop: "1px solid #F3F4F6",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    />

                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Visitor(s)
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {visitors?.total_count === 0
                          ? "-"
                          : visitors?.total_count + " " + "px"}
                      </TypographyDefault>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default VisitorsDetails;
