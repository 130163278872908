import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  SvgIcon,
  Tabs,
  Tab,
  Menu,
  Skeleton,
  CircularProgress,
  styled,
  Tooltip,
  tooltipClasses,
  Chip,
} from "@mui/material";

import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  TypoGraph,
  TypographyDefault,
} from "../../Theme";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as InfoIcon } from "../../Icons/OverViewInfo.svg";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import { ReactComponent as HeatMapIconFirst } from "../../Icons/HeatMapOne.svg";
import { ReactComponent as HeatMapIconTwo } from "../../Icons/HeatMapTwo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { ReactComponent as HeatMapIconThree } from "../../Icons/HeatMapThree.svg";
import { ReactComponent as HeatMapIconFour } from "../../Icons/HeatMapFour.svg";
import { ReactComponent as HeatMapIconFive } from "../../Icons/HeatMapFive.svg";
import { ReactComponent as HeatMapIconsix } from "../../Icons/HeatMapSix.svg";
import { ReactComponent as HeatMapIconSeven } from "../../Icons/HeatMapSeven.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { ReactComponent as DownArrow } from "../../Icons/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../Icons/UpArrow.svg";
import "./Overview.css";
import ManagementGraph from "./ManagementGraph";

import moment from "moment";
import FaultReportGraph from "./FaultReportGraph";
import ServiceRequestGraph from "./ServiceRequestGraph";
import FacilityBookingsGraph from "./FacilityBookingGraph";
import ParcelCollectionGraph from "./ParcelCollectionGraph";


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#262D33",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#262D33",
    fontSize: "12px",
    color: "#F3F4F6",
    fontWeight: 400,
    borderRadius: "8px 8px 5px 8px",
    padding: "12px",
  },
}));
const getGraphChip = (data) => {
  const commonStyles = {
    borderRadius: "6px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 500,
    height: "24px",
    padding: "2px 6px 2px 4px",
    display: "flex", // Ensure proper layout of label and icon
    alignItems: "center", // Align icon and label vertically
    ".MuiChip-icon": {
      marginRight: "6px",
      marginLeft: "0px",
      color: "inherit", // Inherit the color from parent styles
    },
    ".MuiChip-label": {
      padding: 0,
      color: "inherit", // Inherit color for label
    },
  };

  if (data?.includes("-")) {
    const newData = data?.replace("-", "");
    return (
      <Chip
        label={newData}
        icon={<DownArrow />}
        sx={{
          backgroundColor: "#FEF3F2",
          color: "#B42318",
          border: "1px solid #FECDCA",
          ...commonStyles,
        }}
      />
    );
  } else {
    return (
      <Chip
        label={data}
        icon={<UpArrow />}
        sx={{
          backgroundColor: "#ECFDF3",
          color: "#067647",
          border: "1px solid #ABEFC6",
          ...commonStyles,
        }}
      />
    );
  }
};

const OverviewGraphSection = ({
  countDeatils,
  percentageChange,
  tabValue,
  yearSorting,
  LanguageData,
  isLoading,
  yearArray,
  setYearSorting,
  handleTabChange,
}) => {
  return (
    <div id="section-1">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "50px",
        }}
      >
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Typography
            sx={{ color: "#111927", fontWeight: 600, fontSize: "20px" }}
          >
            {LanguageData?.home_this_month || "This Month"}
          </Typography>
        )}

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {isLoading ? (
            <Skeleton width={200} height={50} />
          ) : (
            <>
              <StyledTooltip
                title="The up/down trend indicator illustrates the comparison of data between the current month and the prior month."
                arrow
                placement="top-start"
              >
                <InfoIcon style={{ marginRight: "10px", cursor: "pointer" }} />
              </StyledTooltip>

              <StyledSecondaryServiceTypography>
                {LanguageData?.home_this_month || "Updated as of"}{" "}
                {moment(new Date()).format("DD MMMM YYYY")}
              </StyledSecondaryServiceTypography>
            </>
          )}
        </Box>
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={80} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderRadius: "8px 0px 0px 0px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_resident || "Residents"}
            </StyledSecondaryServiceTypography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
            >
              {countDeatils?.Residents || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_invited_visitors || " Invited Visitors"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.invitedVisitors || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.inviteVisitorChange)}
              </TypographyDefault>
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: "0px 8px 0px 0px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_property_staff || "Property Staff"}
            </StyledSecondaryServiceTypography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
            >
              {countDeatils?.PropertyStaff || "-"}
            </Typography>
          </Box>
        </Box>
      )}
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={80} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderRadius: "0px 0px 0px 8px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_falut_report || "Faults Reported"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.FaultsReported || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.faultReportChange)}
              </TypographyDefault>
            </Box>
          </Box>
          <Box
            sx={{
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_service_request || "Service Requested"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.ServiceRequested || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.serviceRequestChange)}
              </TypographyDefault>
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: "0px 0px 8px 0px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_facility_book || "Facilities Booked"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.FacilitiesBooked || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.facilitiesChange)}
              </TypographyDefault>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "50px",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Typography
            sx={{ color: "#111927", fontWeight: 600, fontSize: "20px" }}
          >
            {LanguageData?.home_overview || " Overview"}
          </Typography>
        )}
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Box sx={{ cursor: "pointer" }}>
            <Select
              value={yearSorting}
              style={{
                borderRadius: "8px",
                border: "1px solid #D2D6DB",
                backgroundColor: "#FFFFFF",
                padding: "8px 14px 8px 14px",
                color: "#0E5E84",
                fontWeight: 600,
                fontSize: "14px",
                height: "36px",
                minWidth: "92px",
              }}
              IconComponent={KeyboardArrowDownIcon}
              onChange={(e) => setYearSorting(e.target.value)}
            >
              {yearArray.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={500} />
      ) : (
        <>
          <Box
            sx={{
              borderRadius: "8px 8px 0px 0px",
              borderBottom: "1px solid #E5E7EB",
              backgroundColor: "#FFFFFF",
              marginTop: "10px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              style={{ justifyContent: "space-between" }}
            >
              <Tab
                label={LanguageData?.home_tab_management || "Management"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_fault || "Fault Report"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_service || "Service Request"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_facility || "Facilities Booked"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_parcel || "Parcel"}
                sx={{ flexGrow: 1 }}
              />
            </Tabs>
          </Box>
          {tabValue === 0 && <ManagementGraph />}
          {tabValue === 1 && <FaultReportGraph />}
          {tabValue === 2 && <ServiceRequestGraph />}
          {tabValue === 3 && <FacilityBookingsGraph />}
          {tabValue === 4 && <ParcelCollectionGraph />}
        </>
      )}
    </div>
  );
};

export default OverviewGraphSection;
