import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  PrimaryTypography,
  StyledSecondaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledTableCell,
  StyledTableRow,
  TypographyDefault,
  styledTyphographyDefault,
} from "../../Theme";
import moment from "moment";
import { saveAs } from "file-saver";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as SortIcon } from "../../Icons/Sort.svg";
import { ReactComponent as FilterIcon } from "../../Icons/Filter.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/Exclamatory.svg";
import { ReactComponent as SearchIcon } from "../../Icons/Search.svg";
import { ReactComponent as PreviousIcon } from "../../Icons/Previous.svg";
import { ServiceNavTab, ServiceTableHeader } from "../../Data";
import { ReactComponent as NumberInputIcon } from "../../Icons/NumberInputIcon.svg";
import { ReactComponent as NextIcon } from "../../Icons/Next.svg";
import { ReactComponent as CsvIcon } from "../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../Icons/PdfIcon.svg";
import { ReactComponent as DisabledDownloadIcon } from "../../Icons/DisableDownload.svg";
import { ReactComponent as CheckboxIcon } from "../../Icons/Checkbox.svg";
import { ReactComponent as SetUpIcon } from "../../Icons/ServiceSetup.svg";
import { getChip } from "../../Utils";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Menu,
  MenuItem,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import axiosInstance from "../../Redux/AxiosInstance";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ServiceRequest = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const navigate = useNavigate();
  const [initialRender, setInitialRender] = useState(true);
  const [userStatus, setUserStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [tabId, setTabId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setsortData] = useState("new");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [filterData, setFilterData] = useState([]);
  const [SelectedFilter, setSelectedFilter] = useState([]);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [serviceTypesList, setServiceTypesList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [checkedItems, setCheckedItems] = useState({
    submitted: false,
    approved: false,
    cancelled: false,
  });
  const [SearchData, setSearchData] = useState("");
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [Tableloading, setTableloading] = useState(true);
  const [loading, setLoading] = useState(false);

  const getServiceRequestData = async () => {
    const data = SelectedFilter.join(",");
    setTableloading(true);
    setServiceTypesList([]);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}?page=${currentPage}&record_count=${rowsPerPage}&status_id=${data}&sort=${sortData}&service_request_types_id=${tabId}&search=${SearchData}`
      )
      .then((response) => {
        setPaginationData(response.data.data.pagination_details);
        let serviceData = response.data.data.service_request;
        setServiceTypesList(serviceData);
        setTableloading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.error == "No service request found") {
          setServiceTypesList([]);
        }
        setTableloading(false);
      });
  };

  useEffect(() => {
    getServiceRequestData();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getServiceRequestData();
      }
    } else {
      setInitialRender(false);
    }
  }, [rowsPerPage, sortData, SelectedFilter, tabId, SearchData]);

  const handleSearch = async (data) => {
    const filter = SelectedFilter.join(",");
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}?search=${data}&page=${currentPage}&record_count=${rowsPerPage}&status_id=${filter}&sort=${sortData}&service_request_types_id=${tabId}`
      );
      setServiceTypesList(response?.data?.data?.service_request);
      setPaginationData(response?.data?.data?.pagination_details);
      setRowsPerPage(response?.data?.data?.pagination_details?.per_page);
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterDD = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}getDropDownDatas?type=service_request_status`
      );
      setFilterData(response?.data?.data?.drop_down);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFilterDD();
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/get_service_request_terms_condition`
      )
      .then(async (res) => {
        let result = await res.data.data?.service_request;
        if (result?.terms_condition) {
          setUserStatus(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSelectedFilter = (e, id) => {
    // setCurrentPage('1')
    const Data = SelectedFilter?.find((itm) => itm == id);
    if (Data) {
      const Data = SelectedFilter?.filter((itm) => itm !== id);
      setSelectedFilter(Data);
    } else {
      setSelectedFilter((itm) => [...itm, id]);
    }
  };

  const handleTotalCount = (key) => {
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [key]: !prevItems[key],
    }));
  };

  const totalCheckCount = Object.values(checkedItems).filter(
    (isChecked) => isChecked
  ).length;

  const handleReviewNavigate = (id) => {
    navigate(`/service-request-list/${id}`);
  };

  const DownloadReport = async (type) => {
    const data = SelectedFilter.join(",");
    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/download?download_type=${type}&page=${currentPage}&record_count=${rowsPerPage}&status_id=${data}&sort=${sortData}&service_request_types_id=${tabId}&search=${SearchData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `ServiceRequest_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const renderNoData = () => {
    switch (selectedTab) {
      case "All":
        return (
          <>
            <ExclamatoryIcon style={{ marginBottom: "5px" }} />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                width: "470px",
                my: 2,
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              {LanguageData?.No_Service_Request_Here || "No Request List Here"}
            </Typography>
            <StyledSecondaryTypography
              color="#9DA4AE !important"
              textAlign="center"
              width="470px"
              height="88px"
              fontSize="16px"
              fontWeight="400px"
              lineHeight="22.4px"
            >
              {LanguageData?.noData ||
                "There are no active or past service requests at the moment. Residents are able to submit service requests via the LOBY mobile app. Once submitted,you will see it here for approval and processing."}
            </StyledSecondaryTypography>
          </>
        );
      case "Cargo Lift Usage":
        return (
          <>
            <ExclamatoryIcon style={{ marginBottom: "5px" }} />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                width: "470px",
                my: 2,
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              {LanguageData?.No_Service_Request_Here ||
                "No Cargo lift usage list found here."}
            </Typography>
          </>
        );
      case "Security Clearance":
        return (
          <>
            <ExclamatoryIcon style={{ marginBottom: "5px" }} />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                width: "470px",
                my: 2,
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              {LanguageData?.No_Service_Request_Here ||
                "No Security Clearance list found here."}
            </Typography>
          </>
        );
      case "Ad Hoc":
        return (
          <>
            <ExclamatoryIcon style={{ marginBottom: "5px" }} />
            <Typography
              sx={{
                color: "#9DA4AE",
                fontSize: "16px",
                fontWeight: 600,
                width: "470px",
                my: 2,
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              {LanguageData?.No_Service_Request_Here ||
                "No Ad Hoc list found here."}
            </Typography>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {LanguageData?.Service_Requests || "Request List"}{" "}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Service_Requests_sub ||
              "Manage Request List like Cargo Lift Usage and Security Clearance forms submitted on the LOBY system."}
          </StyledSecondaryTypography>
        </Box>
        {UserManage?.["fault-reports"] && userStatus ? (
          <Box>
            <Button
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              sx={{
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={serviceTypesList.length === 0 || loading}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <>
                  {serviceTypesList.length > 0 ? (
                    <DownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  ) : (
                    <DisabledDownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  )}
                </>
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      {userStatus ? (
        <>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #E5E7EB",
              p: 3,
              mt: 4,
              borderRadius: " 8px 8px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                border: "1px solid #D2D6DB",
                display: "flex",
                borderRadius: "10px",
                overflowX: "scroll",
                "::-webkit-scrollbar": { display: "none" },
              }}
            >
              {ServiceNavTab.map((value, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    setSelectedTab(value);
                    setTabId(index == 0 ? "" : index);
                  }}
                  sx={{
                    borderRadius:
                      index === 3
                        ? "0px 8px 8px 0px"
                        : index === 0
                        ? "8px 0px 0px 8px"
                        : "initial",
                    borderLeft: index !== 0 ? "1px solid #E5E7EB" : "initial",
                    backgroundColor:
                      value == selectedTab ? "#F3F4F6" : "initial",
                    padding: "10px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{
                      color: value === selectedTab ? "#111927" : "#4D5761",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                    textAlign={"center"}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: "flex", height: "40px" }}>
              <StyledOutlinedInput
                placeholder="Search"
                onChange={(e) => {
                  setSearchData(e.target.value);
                }}
                sx={{ marginLeft: "15px", width: "200px" }}
                startAdornment={<SearchIcon style={{ paddingRight: "5px" }} />}
              />
              <Box
                onClick={(e) => setSortAnchorEl(e.currentTarget)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #D2D6DB",
                  borderRadius: "6px",
                  px: 2,
                  ml: 2,
                  "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
                }}
              >
                <SortIcon style={{ marginRight: "10px" }} />
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.sort_filter || "Sort"}{" "}
                </SecondaryTypography>
              </Box>
              <Box
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #D2D6DB",
                  borderRadius: "8px",
                  px: 2,
                  ml: 2,
                  "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
                }}
              >
                <FilterIcon style={{ marginRight: "10px" }} />
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.filter_common || "Filters"}{" "}
                </SecondaryTypography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ border: "1px solid #E5E7EB" }}>
            <>
              <TableContainer>
                <Table>
                  {serviceTypesList.length > 0 ? (
                    <TableHead>
                      {ServiceTableHeader.length > 0 && (
                        <StyledTableRow>
                          {ServiceTableHeader.map((value) => (
                            <StyledTableCell key={value}>
                              {value}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      )}
                    </TableHead>
                  ) : (
                    <></>
                  )}
                  <TableBody
                    bgColor={!serviceTypesList.length > 0 && "#F9FAFB"}
                  >
                    {serviceTypesList.length > 0 ? (
                      serviceTypesList.map((request, index) => (
                        <StyledTableRow
                          key={index}
                          onClick={() => handleReviewNavigate(request.id)}
                        >
                          <StyledTableCell>
                            <TypographyDefault>
                              {request?.uuid || "--"}
                            </TypographyDefault>
                          </StyledTableCell>
                          <StyledTableCell>
                            <TypographyDefault>
                              {request?.title || "--"}
                            </TypographyDefault>
                          </StyledTableCell>
                          <StyledTableCell>
                            {getChip(request.status.status_details?.key)}
                          </StyledTableCell>
                          <StyledTableCell>
                            <TypographyDefault>
                              {request.user_details?.name || "--"}
                            </TypographyDefault>
                          </StyledTableCell>
                          <StyledTableCell>
                            <TypographyDefault>
                              {request?.created_at
                                ? moment
                                    .utc(request.created_at)
                                    .local()
                                    .format("DD MMM YYYY, HH:mm")
                                : "-"}
                            </TypographyDefault>
                            {/* <TypographyDefault>
                              {request?.created_at
                                ? moment(request.created_at).format(
                                    "DD MMM YYYY, HH:MM"
                                  )
                                : "-"}
                            </TypographyDefault> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <Box
                        sx={{
                          height: "50vh",
                          borderRadius: "0px, 0px, 8px, 8px",
                          padding: "16px",
                          backgroundColor: "#F9FAFB",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {Tableloading ? <CircularProgress /> : renderNoData()}
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          </Box>
          {serviceTypesList.length > 0 ? (
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                border: "1px solid #E5E7EB",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  Show :
                </Typography>
                {/* <OutlinedInput
                    inputProps={{ min: 5, max: 30, step: 5 }}
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(e.target.value)}
                    type="number"
                    sx={{
                      height: "40px",
                      width: "100px",
                      ml: 2,
                      borderRadius: "8px",
                    }}
                  /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                page={currentPage}
                size="medium"
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          ) : (
            <></>
          )}
          <Menu
            sx={{ m: 0.5 }}
            open={downloadOpen}
            anchorEl={downloadAnchorEl}
            onClose={() => setDownloadAnchorEl(null)}
          >
            <MenuItem>
              <TypographyDefault
                py={0.5}
                onClick={() => DownloadReport("csv")}
                sx={{
                  fontWeight: "500 !important",
                  color: "#1F2A37 !important",
                  display: "flex",
                }}
              >
                <CsvIcon width={40} />{" "}
                {LanguageData?.Download_csv || "Download as CSV file"}
              </TypographyDefault>
            </MenuItem>
            <MenuItem>
              <TypographyDefault
                py={0.5}
                onClick={() => DownloadReport("pdf")}
                sx={{
                  fontWeight: "500 !important",
                  color: "#1F2A37 !important",
                  display: "flex",
                }}
              >
                <PdfIcon width={40} />
                {LanguageData?.Download_pdf || "Download as PDF file"}
              </TypographyDefault>
            </MenuItem>
          </Menu>
          <Menu
            sx={{ m: 0.5 }}
            open={sortOpen}
            anchorEl={sortAnchorEl}
            onClose={() => setSortAnchorEl(null)}
          >
            <MenuItem
              sx={{ backgroundColor: sortData == "new" ? "#eff3f5" : "" }}
              onClick={() => {
                setsortData("new");
                setSortAnchorEl(null);
              }}
            >
              <styledTyphographyDefault>
                {" "}
                {LanguageData?.Newest || "Newest"}{" "}
              </styledTyphographyDefault>
            </MenuItem>
            <MenuItem
              sx={{ backgroundColor: sortData == "old" ? "#eff3f5" : "" }}
              onClick={() => {
                setsortData("old");
                setSortAnchorEl(null);
              }}
            >
              <styledTyphographyDefault>
                {" "}
                {LanguageData?.Oldest || "Oldest"}{" "}
              </styledTyphographyDefault>
            </MenuItem>
            <MenuItem
              sx={{ backgroundColor: sortData == "recent" ? "#eff3f5" : "" }}
              onClick={() => {
                setsortData("recent");
                setSortAnchorEl(null);
              }}
            >
              <styledTyphographyDefault>
                {LanguageData?.Most_recently_updated || "Most recently updated"}
              </styledTyphographyDefault>
            </MenuItem>
          </Menu>
          <Menu
            sx={{ width: "312px", height: "459px" }}
            open={filterOpen}
            anchorEl={filterAnchorEl}
            onClose={() => setFilterAnchorEl(null)}
          >
            {filterData?.map((itm, index) => (
              <MenuItem
                onClick={(e) => {
                  handleSelectedFilter(e, itm?.id);
                }}
              >
                <Checkbox
                  sx={{ width: "42px", height: "42px" }}
                  value={itm?.id}
                  checked={Boolean(
                    SelectedFilter.find((item) => itm?.id == item)
                  )}
                  icon={<CheckboxIcon />}
                />
                <Typography sx={{ mr: 3 }}>{itm?.name}</Typography>
              </MenuItem>
            ))}
            {/* <MenuItem>
                <Checkbox
                  onClick={() => handleTotalCount("submitted")}
                  icon={<CheckboxIcon />}
                />
                <Typography>Submitted</Typography>
              </MenuItem>
              <MenuItem>
                <Checkbox
                  onClick={() => handleTotalCount("approved")}
                  icon={<CheckboxIcon />}
                />
                <Typography>Approved</Typography>
              </MenuItem>
              <MenuItem>
                <Checkbox
                  onClick={() => handleTotalCount("cancelled")}
                  icon={<CheckboxIcon />}
                />
                <Typography>Cancelled</Typography>
              </MenuItem> */}
            {/* <Box
                sx={{
                  width: "70px",
                  height: "20px",
                  borderRadius: "8px",
                  border: "1px solid #D2D6DB",
                  padding: "10px 8px 10px 8px",
                  backgroundColor: "#FFFFFF",
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "45px",
                }}
              >
                <Typography
                  sx={{
                    height: "24px",
                    width: "30px",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#111927",
                  }}
                >
                  Filter
                </Typography>
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#0E5E84",
                    borderRadius: "50%",
                    position: "absolute",
                    marginLeft: "47px",
                    color: "#FFFFFF",
                  }}
                >
                  {totalCheckCount > 0 && (
                    <Typography sx={{ marginLeft: "7px" }}>
                      {totalCheckCount}
                    </Typography>
                  )}
                </Box>
              </Box> */}
          </Menu>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              height: "316px",
              marginTop: "33px",
              backgroundColor: "#FFFFFF",
              padding: "50px 0px 50px 0px",
              gap: "50px",
            }}
          >
            <Box
              sx={{
                height: "145px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // marginLeft: "250px",
                marginTop: "90px",
              }}
            >
              {Tableloading ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography
                    sx={{
                      fontWeight: "600px",
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4D5761",
                      lineHeight: "20.8px",
                      marginBottom: "10px",
                    }}
                  >
                    <Box>
                      <SetUpIcon />
                    </Box>
                    {LanguageData?.Please_set ||
                      "Please set up the below service request forms"}
                  </Typography>
                  <StyledSecondaryTypography
                    textAlign="center"
                    lineHeight="22.4px"
                  >
                    {LanguageData?.Set_Description ||
                      "Kindly establish the service requests (Cargo Lift and Security Clearance) terms and conditions, as well as the specifications for the cargo lift"}
                  </StyledSecondaryTypography>
                  <Box>
                    <Button
                      sx={{
                        fontWeight: "600",
                        width: "86px",
                        height: "41px",
                        color: "#0E5E84",
                        backgroundColor: "#E7EFF3",
                        padding: "10px  18px 10px 18px",
                        borderRadius: "8px",
                        gap: "8px",
                        marginTop: "15px",
                      }}
                      onClick={() => {
                        localStorage.setItem("fromServiceRequest", true);
                        navigate("/documentations");
                      }}
                    >
                      {LanguageData?.Set_Up || "Set Up"}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ServiceRequest;
