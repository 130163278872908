import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Dayjs from "dayjs";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Date.svg";
import {
  ErrorTypography,
  DatePickerStyle,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
  DatePickerStyles,
} from "../../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  IconButton,
  Divider,
  Modal,
  Typography,
  Checkbox,
  Skeleton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import axiosInstance from "../../../Redux/AxiosInstance";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { userData } from "../../../Utils";

const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

let offboardModalData = {
  label: "Offboard User Now",
  descriptionOne:
    "Please confirm that you wish to offboard user now. After offboarded, the information cannot be recovered.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm Offboard",
  },
};

let cancelOffboardData = {
  label: "Cancel Offboard Request",
  descriptionOne:
    "Please confirm that you wish to cancel the offboard request. The resident will be tagged as a permanent user.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#0E5E84",
    text: "Confirm",
  },
};

const ReviewResidentDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { propertyId, userRole } = useSelector(
    (state) => state.PropertyDetails
  );
  const { userDetails, UserManage } = useSelector((state) => state.Login);

  const [buildingData, setBuildingData] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);

  const initialState = { schedule: false, now: false };
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [modalCheckboxStatus, setModalCheckboxStatus] = useState({
    schedule: false,
    now: false,
  });
  const [offboardDate, setOffboardDate] = useState(null);
  const [modalError, setModalError] = useState({ date: "", selected: "" });
  const [showAlert, setShowAlert] = useState({
    now: false,
    schedule: false,
    cancelOffboard: false,
  });
  const [selectedData, setSelectedData] = useState({});

  const [PersonalDetailsData, setPersonalDetailsData] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    dob: "",
    gender: "",
    nationality: "",
    inorpn: "",
    phone: "",
    countrycode: "",
    email: "",
    address: "",
    country: "",
    city: "",
    postalcode: "",
  });
  const [emergencyContactData, setemergencyContactData] = useState({
    firstname: "",
    lastname: "",
    // gender: '',
    // nationality: '',
    phone: "",
    email: "",
    relationship: "",
  });
  const [WorkAccessData, setWorkAccessData] = useState({
    residenttype: "",
    building: "",
    level: "",
    unit: "",
    accessstartdate: "",
    accessenddate: "",
  });

  const [onboardedby, setonboardedby] = useState({
    name: "",
    email: "",
    department: "",
    jobrole: "",
    onboardId: 0,
  });

  const [offboardedBy, setOffboardedBy] = useState({
    name: "",
    role: "",
    department: "",
  });

  const [loading, setloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  const getResidentData = async () => {
    setloading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}user_details?user_id=${id}`
      );
      // setstaffdata(response.data.data.user_details)
      const data = response.data.data.user_details;
      setSelectedData(data);
      setPersonalDetailsData({
        firstname: data?.first_name,
        lastname: data?.last_name,
        middlename: data?.middle_name,
        dob: data?.dob,
        gender: data?.gender,
        nationality: data?.nationality,
        inorpn: data?.identity_number,
        phone: data?.phone,
        countrycode: data?.country_code,
        email: data?.email,
        address: data?.address,
        country: data?.country,
        city: data?.city,
        postalcode: data?.zipcode,
      });
      setemergencyContactData({
        firstname: data?.emergency_contacts?.first_name,
        lastname: data?.emergency_contacts?.last_name,
        // gender: data?.emergency_contacts?.gender,
        // nationality: data?.emergency_contacts?.nationality,
        countrycode: data?.emergency_contacts?.country_code,
        phone: data?.emergency_contacts?.phone,
        email: data?.emergency_contacts?.email,
        relationship: data?.emergency_contacts?.relationship,
      });
      setWorkAccessData({
        residenttype: data?.access_details?.residency_type.name,
        building: data?.access_details?.building_id,
        level: data?.access_details?.level_id,
        unit: data?.access_details?.unit_id,
        accessstartdate:
          data?.access_details?.access_start != null
            ? moment.utc(data?.access_details?.access_start).local()
            : null,
        accessenddate:
          data?.access_details?.access_end != null
            ? moment.utc(data?.access_details?.access_end).local()
            : null,
        updated_at: moment.utc(data?.access_details?.updated_at).local(),
      });
      setonboardedby({
        name: data?.access_details?.onboarded_user_details?.name,
        email: data?.access_details?.onboarded_user_details?.email,
        department:
          data?.access_details?.onboarded_user_details?.access_details
            ?.department_details?.department_name,
        jobrole:
          data?.access_details?.onboarded_user_details?.access_details
            ?.role_details?.title,
        onboardId: data?.access_details?.onboarded_user_details?.user_role,
      });

      setOffboardedBy({
        name: data?.access_details?.offboarded_user_details?.name,
        role: data?.access_details?.offboarded_user_details?.access_details
          ?.role_details?.title,
        department:
          data?.access_details?.offboarded_user_details?.access_details
            ?.department_details?.department_name,
      });

      setBtnDisable(false);
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getResidentData();

    const getBuildingData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building`
        );
        const BuildingData = response.data.data.property_buildings;
        setBuildingData(BuildingData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBuildingData();
  }, []);

  const closeModal = () => {
    setChangeStatusModal(false);
    setSelectedData({});
    setOffboardDate(Dayjs(null));
    setModalError({ date: "", selected: "" });
    setModalCheckboxStatus({ schedule: false, now: false });
    SetPostLoader(false);
  };

  const closeAlert = () => {
    setShowAlert({ now: false, schedule: false, cancelOffboard: false });
  };

  const offboardResident = () => {
    const checkScheduleoffboardDateToday =
      moment(offboardDate).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const offboardDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const AccessEnd = offboardDate;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: [selectedData.id],
      status: "offboard",
      access_end: modalCheckboxStatus.now
        ? offboardDateToday
        : checkScheduleoffboardDateToday
        ? offboardDateToday
        : final1End,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        closeAlert();
        closeModal();
        getResidentData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelOffboardResident = () => {
    SetPostLoader(true);
    const data = { user_ids: [selectedData.id], status: "active" };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        closeAlert();
        closeModal();
        getResidentData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validate = () => {
    let hasError = [];

    if (
      modalCheckboxStatus.now == false &&
      modalCheckboxStatus.schedule == false
    ) {
      setModalError({
        ...modalError,
        selected: "Please select one option",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        selected: "",
      });
    }

    if (modalCheckboxStatus.schedule == true && offboardDate === null) {
      setModalError({
        ...modalError,
        date: "Please select offboard date",
      });
      hasError.push(true);
    } else {
      setModalError({
        ...modalError,
        date: "",
      });
    }
    return hasError.includes(true);
  };

  const handleConfirm = () => {
    let isError = validate();
    if (!isError) {
      if (modalCheckboxStatus.now) {
        setShowAlert({
          ...showAlert,
          now: true,
        });
      } else {
        setShowAlert({
          ...showAlert,
          schedule: true,
        });
      }
      // closeModal()
    }
  };
  const handleModalCheckboxStatus = (key) => {
    setModalCheckboxStatus({
      ...initialState,
      [key]: !modalCheckboxStatus[key],
    });
  };
  const offboardremaininghours =
    (moment(WorkAccessData?.accessenddate)._d - moment(new Date())._d) /
    (1000 * 60 * 60);

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {(showAlert.now || showAlert.schedule) && (
        <AlertModal
          modalDatas={offboardModalData}
          confirmFunction={offboardResident}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.cancelOffboard && (
        <AlertModal
          modalDatas={cancelOffboardData}
          confirmFunction={cancelOffboardResident}
          closeFunction={closeAlert}
        />
      )}

      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <BackArrow
          onClick={() => navigate("/residents")}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flex={1}>
            <PrimaryTypography mb={1}>
              {loading ? <Skeleton width={300} /> : "Resident Profile"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {loading ? (
                <Skeleton width={300} />
              ) : (
                "Last Updated: " +
                moment(WorkAccessData.updated_at)
                  .format("DD MMM YYYY , HH:mm")
                  .toString()
              )}
            </StyledSecondaryTypography>
          </Box>
          {UserManage?.residents && !loading && (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Button
                disabled={btnDisable}
                onClick={() => setChangeStatusModal(true)}
                sx={{
                  width: "235px",
                  height: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "1px solid #D2D6DB",
                }}
              >
                <DatePickerIcon color="#0E4965 !important" />
                <StyledTypography sx={{ color: "#0E4965 !important" }} pl={2}>
                  {" "}
                  Change Offboard Date{" "}
                </StyledTypography>
              </Button>
              <Button
                disabled={btnDisable}
                onClick={() => navigate(`/residents/editResident/${id}`)}
                sx={{
                  fontWeight: "600",
                  height: "40px",
                  width: "fit-content",
                  color: "#0E5E84",
                  backgroundColor: "#E7EFF3",
                  padding: "8px 6px 8px 2px",
                  marginLeft: "12px",
                  "&:hover": {
                    backgroundColor: "#CFDFE6",
                    border: "none",
                  },
                }}
              >
                {" "}
                <IconButton sx={{ paddingLeft: "10px" }}>
                  {" "}
                  <EditIcon width="14px" />
                </IconButton>{" "}
                Edit Details
              </Button>
            </Box>
          )}
        </Stack>
        {loading ? (
          <Skeleton variant="rounded" width="100%" height={1000} />
        ) : (
          <>
            {Boolean(WorkAccessData.accessenddate) && (
              <Box
                my={4}
                px="20px"
                py="20px"
                borderRadius={"8px"}
                backgroundColor="#FFFAEB"
                border="1px solid #FEE6A1"
              >
                <StyledTypography
                  mb="10px"
                  sx={{ color: "#B54708 !important" }}
                >
                  Scheduled for offboarding
                </StyledTypography>
                <TypographyDefault sx={{ color: "#B54708 !important" }}>
                  The staff will be marked as offboarding by{" "}
                  {moment(WorkAccessData.accessenddate).format("DD MMM YYYY")}.
                  (Status change by {offboardedBy?.name + ","}
                  {offboardedBy?.role && offboardedBy?.role + ", "}{" "}
                  {offboardedBy?.department || "Management"}). After{" "}
                  {String(offboardremaininghours).split(".")[0]} hours from this
                  date, they will no longer appear in the system and their
                  details will be wiped out.
                </TypographyDefault>
              </Box>
            )}
            {/* Personal Details */}
            <Box
              mt={3}
              component="div"
              boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
              borderRadius="8px 8px 0 0"
            >
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                  borderRadius: "8px 8px 0 0 ",
                }}
              >
                <SecondaryTypography>
                  Resident Basic Information
                </SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    First Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.firstname}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Last Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.lastname}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Middle Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.middlename || "-"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Date of Birth
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {moment(PersonalDetailsData.dob).format("DD MMM YYYY")}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.gender == 1
                      ? "Male"
                      : PersonalDetailsData.gender == 2
                      ? "Female"
                      : "Other"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Nationality
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.nationality}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Identity Number / Passport Number
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.inorpn}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography sx={{ marginBottom: "10px" }}>
                    Contact Number
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.countrycode}{" "}
                    {PersonalDetailsData.phone}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Email Address
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.email}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Address</StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.address}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Country</StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.country}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>City</StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.city}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Postal Code
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {PersonalDetailsData.postalcode}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
            {/* Emergency Contact */}
            <Box
              component="div"
              boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
              borderRadius="0px"
            >
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>Emergency Contact</SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    First Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.firstname}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Last Name
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.lastname || "-"}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Email Address
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.email}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Contact Number
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.countrycode}{" "}
                    {emergencyContactData.phone}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                {/* <Grid xs={12} sm={5.8} sx={reviewstyle}>
                <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
                <TypographyDefault>{emergencyContactData.nationality}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
                <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                <TypographyDefault>{PersonalDetailsData.gender == 1 ? 'Male' : PersonalDetailsData.gender == 2 ? 'Female' : 'Other'}</TypographyDefault>
            </Grid>
            <Divider sx={{ borderTop: '1px solid #F3F4F6', width: '100%', marginTop: '10px' }} /> */}
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Relationship
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {emergencyContactData.relationship}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
            {/* Work & Access Details */}
            <Box component="div" boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)">
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>Work & Access Details</SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Resident Type
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {WorkAccessData.residenttype}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Building
                  </StyledSecondaryTypography>
                  {buildingData.map((itm, index) => {
                    if (WorkAccessData.building == itm.id) {
                      return (
                        <TypographyDefault>
                          {itm.building_name}
                        </TypographyDefault>
                      );
                    }
                  })}
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Level</StyledSecondaryTypography>
                  {buildingData.map((itm, index) => {
                    if (WorkAccessData.building == itm.id) {
                      return itm.level.map((sitm) => {
                        if (WorkAccessData.level == sitm.id) {
                          return (
                            <TypographyDefault>{sitm.level}</TypographyDefault>
                          );
                        }
                      });
                    }
                  })}
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Unit</StyledSecondaryTypography>
                  {buildingData.map((itm, index) => {
                    if (WorkAccessData.building == itm.id) {
                      return itm.level.map((sitm) => {
                        if (WorkAccessData.level == sitm.id) {
                          return sitm.building_unit.map((ssitm) => {
                            if (WorkAccessData.unit == ssitm.id) {
                              return (
                                <TypographyDefault>
                                  {ssitm.unit_no}
                                </TypographyDefault>
                              );
                            }
                          });
                        }
                      });
                    }
                  })}
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Access Start Date
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {moment(WorkAccessData.accessstartdate).format(
                      "DD MMM YYYY"
                    )}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>
                    Access End Date
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {WorkAccessData.accessenddate !== null
                      ? moment(WorkAccessData.accessenddate).format(
                          "DD MMM YYYY"
                        )
                      : "-"}
                  </TypographyDefault>
                </Grid>
              </Grid>
            </Box>
            {/* Onboarded by */}
            <Box
              component="div"
              boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
              borderRadius="0 0 8px 8px"
            >
              <Stack
                direction="row"
                alignItems="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  border: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>Onboarded by</SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  border: "1px solid #E5E7EB",
                  borderRadius: "0 0 8px 8px  ",
                }}
              >
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Name</StyledSecondaryTypography>
                  <TypographyDefault>{onboardedby.name}</TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} sx={reviewstyle}>
                  <StyledSecondaryTypography>Email</StyledSecondaryTypography>
                  <TypographyDefault>{onboardedby.email}</TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                {onboardedby.onboardId === 2 ? (
                  <Grid xs={12} sm={5.8} sx={reviewstyle}>
                    <StyledSecondaryTypography>
                      {" "}
                      Department
                    </StyledSecondaryTypography>
                    <TypographyDefault>Condo admin</TypographyDefault>
                  </Grid>
                ) : (
                  <>
                    {" "}
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Department
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {onboardedby.department}
                      </TypographyDefault>
                    </Grid>
                    <Grid xs={12} sm={5.8} sx={reviewstyle}>
                      <StyledSecondaryTypography>
                        Job Role
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {onboardedby.jobrole}
                      </TypographyDefault>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </>
        )}
      </Box>
      <Modal open={changeStatusModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              backgroundColor: "#FFF",
              boxShadow:
                "0px 4px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 3px 0px rgba(0, 0, 0, 0.10)",
              border: "1px solid #F3F4F6",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <PrimaryTypography>Change Offboard Date</PrimaryTypography>
              <Close onClick={closeModal} />
            </Box>
            <Stack rowGap={2} sx={{ p: 2 }} direction={"column"}>
              <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                You may change the offboard date of the resident below.
              </Typography>
              <Stack direction={"row"} justifyContent={"space-between"}>
                {selectedData?.access_details?.access_end && (
                  <StyledTypography>
                    Current Offboard Date :{" "}
                    {moment
                      .utc(selectedData?.access_details?.access_end)
                      .local()
                      .format("DD MMM YYYY")}
                  </StyledTypography>
                )}{" "}
                {selectedData?.access_details?.access_end != null && (
                  <TypographyDefault
                    onClick={() => {
                      // closeModal()
                      setShowAlert({
                        ...showAlert,
                        cancelOffboard: true,
                      });
                    }}
                    sx={{ textDecoration: "underline" }}
                  >
                    Cancel Offboard
                  </TypographyDefault>
                )}
              </Stack>
              <Stack
                className={modalCheckboxStatus.schedule && "selectedStyle"}
                rowGap={1}
                sx={{
                  backgroundColor: "#F9FAFB",
                  p: 1,
                  "&.selectedStyle": {
                    backgroundColor: "#EDF6FA",
                    border: "1px solid #0E4965",
                    borderRadius: "8px",
                  },
                }}
                direction={"column"}
              >
                {selectedData?.access_details?.access_end === null ? (
                  <StyledTypography sx={{ my: 1 }}>
                    Schedule Offboard Date
                  </StyledTypography>
                ) : (
                  <StyledTypography sx={{ my: 1 }}>
                    Change Offboard Date
                  </StyledTypography>
                )}
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <StyledSecondaryTypography>
                    To extend or reduce offboard date.
                  </StyledSecondaryTypography>
                  <Checkbox
                    onChange={() => handleModalCheckboxStatus("schedule")}
                    checked={modalCheckboxStatus.schedule}
                    size="small"
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              <Stack
                className={modalCheckboxStatus.now && "selectedStyle"}
                rowGap={1}
                sx={{
                  backgroundColor: "#F9FAFB",
                  p: 1,
                  "&.selectedStyle": {
                    backgroundColor: "#EDF6FA",
                    border: "1px solid #0E4965",
                    borderRadius: "8px",
                  },
                }}
                direction={"column"}
              >
                <StyledTypography>Offboard User Now</StyledTypography>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <StyledSecondaryTypography>
                    To offboard the resident immediately. After offboard is
                    complete, the action is non-reversible and user will not be
                    able to access their account.
                  </StyledSecondaryTypography>
                  <Checkbox
                    onChange={() => handleModalCheckboxStatus("now")}
                    checked={modalCheckboxStatus.now}
                    size="small"
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              {modalCheckboxStatus.schedule && (
                <DatePicker
                  disablePast
                  value={Dayjs(offboardDate)}
                  onChange={(e) => setOffboardDate(e.$d)}
                  format="DD/MM/YYYY"
                  slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                  sx={DatePickerStyle}
                />
              )}
              <ErrorTypography>{modalError.date}</ErrorTypography>
              <ErrorTypography>{modalError.selected}</ErrorTypography>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                sx={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#0E4965",
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ReviewResidentDetails;
