import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  SvgIcon,
  Tabs,
  Tab,
  Menu,
  Skeleton,
  CircularProgress,
} from "@mui/material";

import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  TypoGraph,
  TypographyDefault,
} from "../../Theme";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as InfoIcon } from "../../Icons/OverViewInfo.svg";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import { ReactComponent as HeatMapIconFirst } from "../../Icons/HeatMapOne.svg";
import { ReactComponent as HeatMapIconTwo } from "../../Icons/HeatMapTwo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { ReactComponent as HeatMapIconThree } from "../../Icons/HeatMapThree.svg";
import { ReactComponent as HeatMapIconFour } from "../../Icons/HeatMapFour.svg";
import { ReactComponent as HeatMapIconFive } from "../../Icons/HeatMapFive.svg";
import { ReactComponent as HeatMapIconsix } from "../../Icons/HeatMapSix.svg";
import { ReactComponent as HeatMapIconSeven } from "../../Icons/HeatMapSeven.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { ReactComponent as DownArrow } from "../../Icons/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../Icons/UpArrow.svg";
import "./Overview.css";
import ManagementGraph from "./ManagementGraph";
import axiosInstance from "../../Redux/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FaultReportGraph from "./FaultReportGraph";
import ServiceRequestGraph from "./ServiceRequestGraph";
import FacilityBookingsGraph from "./FacilityBookingGraph";
import ParcelCollectionGraph from "./ParcelCollectionGraph";
import { getGraphChip } from "../../Utils";
import Chart from "react-apexcharts";
import { getOverviewDatas } from "../../Redux/Feature/OverView/OverviewSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { showToast } from "../../CommonComponents/Toaster";
import {
  setpropertySetupLoader,
  getGeneralDetailsByProperty,
} from "../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
const OverviewHeatMap = ({
  isLoading,
  LanguageData,
  selectedFacility,
  facilityList,
  setSelectedFacility,
  dateSorting,
  setDateSorting,
  selectedHeatMapData,
  heatMapLoading,
  options,
  series,
}) => {
  return (
    <div id="section-2">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "50px",
        }}
      >
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#111927", fontWeight: 600, fontSize: "20px" }}
              >
                {LanguageData?.home_header_facility || "Facilities"}
              </Typography>
            </Box>
            {selectedFacility.title !== "" && selectedFacility.id !== 0 ? (
              <Box
                sx={{
                  cursor: "pointer",
                  marginLeft: "30px",
                  maxWidth: "150px",
                  minWidth: "92px",
                }}
              >
                <Select
                  name="title"
                  onChange={(e) => {
                    const selectedTitle = e.target.value;
                    const selectedFacilityObject = facilityList.find(
                      (facility) => facility.title === selectedTitle
                    );

                    setSelectedFacility({
                      id: selectedFacilityObject?.id || 0,
                      title: selectedTitle,
                    });
                  }}
                  value={selectedFacility?.title}
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #D2D6DB",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 14px 8px 14px",
                    color: "#0E5E84",
                    fontWeight: 600,
                    fontSize: "14px",
                    height: "36px",
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {facilityList?.map((result) => (
                    <MenuItem key={result?.id} value={result?.title}>
                      {result?.title}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}

        {selectedFacility.title !== "" && selectedFacility.id !== 0 ? (
          isLoading ? (
            <Skeleton width={200} height={50} />
          ) : (
            <Box sx={{ cursor: "pointer" }}>
              <Select
                value={dateSorting}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #D2D6DB",
                  backgroundColor: "#FFFFFF",
                  padding: "8px 14px 8px 14px",
                  color: "#0E5E84",
                  fontWeight: 600,
                  fontSize: "14px",
                  height: "36px",
                }}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => setDateSorting(e.target.value)}
              >
                <MenuItem value="Current Week">Current Week</MenuItem>
                <MenuItem value="Last Week">Last Week</MenuItem>
                <MenuItem value="Last Month">Last Month</MenuItem>
                <MenuItem value="Last 3 Months">Last 3 Months</MenuItem>
                <MenuItem value="Last 6 Months"> Last 6 Months</MenuItem>
                <MenuItem value="Last Year"> Last Year</MenuItem>
              </Select>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={500} />
      ) : (
        <>
          {selectedHeatMapData?.booking_type?.id === 2 ? (
            <Box
              sx={{
                borderRadius: "8px 8px 0px 0px",
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FCFCFD",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                {selectedFacility.title !== "" && selectedFacility.id !== 0 && (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    {LanguageData?.home_booking_multi || "Multi Booking Type"}
                  </StyledSecondaryServiceTypography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                    padding: "16px 0px 16px 0px",
                  }}
                >
                  <HeatMapIconFirst style={{ marginRight: "10px" }} />
                  <TypoGraph>0</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconTwo style={{ marginRight: "10px" }} />
                  <TypoGraph>1 - 50</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconThree style={{ marginRight: "10px" }} />
                  <TypoGraph>50-100</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFour style={{ marginRight: "10px" }} />
                  <TypoGraph>100-150</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFive style={{ marginRight: "10px" }} />
                  <TypoGraph>150-300</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconsix style={{ marginRight: "10px" }} />
                  <TypoGraph>300-500</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconSeven style={{ marginRight: "10px" }} />
                  <TypoGraph>500+</TypoGraph>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "8px 8px 0px 0px",
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FCFCFD",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                {selectedFacility.title !== "" && selectedFacility.id !== 0 && (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    {LanguageData?.home_booking_single || "Single Booking Type"}
                  </StyledSecondaryServiceTypography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                    padding: "16px 0px 16px 0px",
                  }}
                >
                  <HeatMapIconFirst style={{ marginRight: "10px" }} />
                  <TypoGraph>0</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconTwo style={{ marginRight: "10px" }} />
                  <TypoGraph>1 - 5</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconThree style={{ marginRight: "10px" }} />
                  <TypoGraph>5-10</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFour style={{ marginRight: "10px" }} />
                  <TypoGraph>10-15</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFive style={{ marginRight: "10px" }} />
                  <TypoGraph>20-30</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconsix style={{ marginRight: "10px" }} />
                  <TypoGraph>30-50</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconSeven style={{ marginRight: "10px" }} />
                  <TypoGraph>50+</TypoGraph>
                </Box>
              </Box>
            </Box>
          )}
          {selectedFacility.title !== "" && selectedFacility.id !== 0 ? (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              {heatMapLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "600px",
                  }}
                >
                  {" "}
                  <CircularProgress />
                </Box>
              ) : (
                <Chart
                  height={1500}
                  options={options}
                  series={series}
                  type="heatmap"
                />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "0px, 0px, 8px, 8px",
                padding: "16px",
                backgroundColor: "#F9FAFB",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ExclamatoryIcon
                style={{ marginBottom: "5px", marginTop: "60px" }}
              />
              <Typography
                sx={{
                  color: "#9DA4AE",
                  fontSize: "16px",
                  fontWeight: 600,
                  width: "470px",
                  my: 2,
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "100px",
                }}
                style={{ color: "#4D5761" }}
              >
                No statistics available for display
              </Typography>
            </Box>
          )}{" "}
        </>
      )}
    </div>
  );
};

export default OverviewHeatMap;
